import {UI} from "../../stem-core/src/ui/UIBase";
import {EndpointPaginator} from "../../client/state/EndpointPaginator";
import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {AggregateDataGroupingStore} from "../../client/state/merchant/AggregateDataGroupingStore";
import {isDeepEqual} from "../../blinkpay/UtilsLib";
import {ArrowDownIcon, ArrowUpIcon} from "../../blinkpay/SVGElements";
import {LabeledCheckbox} from "../../blinkpay/ui/Checkbox";
import {camelCaseToCapitalizedWords} from "../misc/Formatting";

export function makeMetadataColumnName(key) {
    // TODO: Special case for panelId, panelAlias, journeyId and journeyAlias.
    return camelCaseToCapitalizedWords(key);
}

export function makeMetadataColumnFunction(key) {
    // TODO: Special case for panelId, panelAlias, journeyId and journeyAlias.
    return value => value ?? "";
}

export class GroupByField {
    constructor(name, field, columnFunction) {
        this.name = name;
        this.field = field;
        this.columnFunction = columnFunction || (value => value);
    }

    toTableColumn() {
        let previousGroupByFields = Symbol();
        let previousValue = Symbol();
        return [this.name, (obj) => {
            const value = obj.entry[this.field];
            if (obj.groupByFields.indexOf(this.field) === -1) {
                previousValue = Symbol();
                previousGroupByFields = Symbol();
                return "(Any)";
            }
            if (value === previousValue && !isDeepEqual(obj.groupByFields, previousGroupByFields)) {
                return "";
            }
            previousValue = value;
            previousGroupByFields = obj.groupByFields;
            if (value == null) {
                return "N/A";
            }
            return this.columnFunction(value, obj.entry) ?? "N/A";
        }];
    }
}

export class BaseAnnotation {
    constructor(name, field, columnFunction, columnOptions) {
        this.name = name;
        this.field = field;
        this.columnFunction = columnFunction || (value => value);
        this.columnOptions = columnOptions;
    }

    toTableColumn() {
        return [this.name, (obj) => {
            const value = obj.entry[this.field];
            if (value == null) {
                return "N/A";
            }
            return this.columnFunction(value, obj.entry) ?? "N/A"
        }, this.columnOptions];
    }
}

export class Annotation extends BaseAnnotation {
    constructor(name, field, func, target, columnFunction, columnOptions) {
        super(name, field, columnFunction, columnOptions);
        this.func = func;
        this.target = target;
    }
}

export class AnalyticsAnnotation extends BaseAnnotation {
    constructor(name, field, eventTypes, payload, columnFunction, columnOptions) {
        super(name, field, columnFunction, columnOptions);
        this.eventTypes = eventTypes;
        this.payload = payload;
    }
}

export class ExtraColumnAnnotation extends BaseAnnotation {
    constructor(name, columnFunction, columnOptions) {
        super(name, null, columnFunction, columnOptions);
    }

    toTableColumn() {
        return [this.name, (obj) => this.columnFunction(obj.entry) ?? "N/A", this.columnOptions];
    }
}

export class AggregateDataViewManager extends Dispatchable {
    constructor(builtinAnnotations, builtinGroupByFields) {
        super();

        this.builtinAnnotations = [
            new Annotation("Count", "count", "count", "*"),
            ...builtinAnnotations,
        ];
        // TODO(@branch): Metadata annotations? Annotation builder? whatever
        this.availableAnnotations = [...this.builtinAnnotations];

        this.builtinGroupByFields = builtinGroupByFields;
        this.availableGroupByFields = [...this.builtinGroupByFields];

        this.enabledGroupByFields = [];
        this.enabledAnnotations = [...this.builtinAnnotations];
    }

    getPaginator(endpoint, options) {
        if (!this.paginator) {
            this.paginator = new AggregateDataPaginator(this, endpoint, options);
            this.attachListener(this.paginator, "pageLoaded", () => this.dispatch("update"));
        }
        return this.paginator;
    }

    getLastResponse() {
        return this.paginator?.getLastResponse();
    }

    addMetadataKeys(metadataKeys) {
        for (const metadataKey of metadataKeys) {
            this.availableGroupByFields.push(new GroupByField(makeMetadataColumnName(metadataKey), `conversion__metadata__${metadataKey}`));
        }
    }

    toggleGroupByField(groupByField) {
        const currentIndex = this.enabledGroupByFields.indexOf(groupByField);
        if (currentIndex >= 0) {
            this.enabledGroupByFields.splice(currentIndex, 1);
        } else {
            this.enabledGroupByFields = this.availableGroupByFields.filter(
                availableField => this.enabledGroupByFields.indexOf(availableField) >= 0 || availableField === groupByField);
        }
        this.dispatch("update");
    }

    swapGroupByFieldIndex(index) {
        if (index < 0 || index >= this.availableGroupByFields.length - 1) {
            return;
        }
        const temp = this.availableGroupByFields[index];
        this.availableGroupByFields[index] = this.availableGroupByFields[index + 1];
        this.availableGroupByFields[index + 1] = temp;
        // Remake the order.
        this.enabledGroupByFields = this.availableGroupByFields.filter(field => this.enabledGroupByFields.indexOf(field) >= 0);
        this.dispatch("update");
    }

    getRequestGroupsField() {
        const annotations = {};
        const analyticsAnnotations = {};
        for (const annotation of this.enabledAnnotations) {
            if (annotation instanceof Annotation) {
                annotations[annotation.field] = {
                    function: annotation.func,
                    target: annotation.target,
                };
            } else if (annotation instanceof AnalyticsAnnotation) {
                analyticsAnnotations[annotation.field] = {
                    event_type: annotation.eventTypes,
                    payload: annotation.payload,
                };
            }
        }
        return {
            groupBy: this.enabledGroupByFields.map(field => field.field),
            annotations,
            analyticsAnnotations,
        };
    }

    getTableColumns() {
        return [
            ...this.enabledGroupByFields.map(field => field.toTableColumn()),
            ...this.enabledAnnotations.map(annotation => annotation.toTableColumn()),
        ];
    }
}

function generateAggregateOrderedEntries(entries, fields) {
    if (fields.length === 0 || entries.length <= 1) {
        return entries;
    }
    const [groupByField, ...remainingFields] = fields;
    const entriesByFieldValue = new Map();
    for (const entry of entries) {
        const fieldValue = entry.entry[groupByField.field];
        if (!entriesByFieldValue.has(fieldValue)) {
            entriesByFieldValue.set(fieldValue, []);
        }
        entriesByFieldValue.get(fieldValue).push(entry);
    }
    const groups = [...entriesByFieldValue.entries()].sort(([,entries1], [,entries2]) => {
        const group1Count = entries1.reduce((count, entry) => count + entry.entry.count);
        const group2Count = entries2.reduce((count, entry) => count + entry.entry.count);
        return group2Count - group1Count;
    });
    const newEntries = [];
    for (const [, groupEntries] of groups) {
        if (groupEntries.length === 1) {
            newEntries.push(...groupEntries);
        } else {
            newEntries.push(
                ...generateAggregateOrderedEntries(groupEntries, remainingFields),
            );
        }
    }
    return newEntries;
}

class AggregateDataPaginator extends EndpointPaginator {
    disablePaginationController = true;

    constructor(groupsManager, endpoint, options) {
        super(AggregateDataGroupingStore, endpoint, options);
        this.groupsManager = groupsManager;
        this.filters.groups = this.groupsManager.getRequestGroupsField();
        this.attachUpdateListener(this.groupsManager, () => {
            this.updateFilter({
                groups: this.groupsManager.getRequestGroupsField(),
            });
        });
    }

    getNumPages() {
        return 1;
    }

    getCurrentPageEntries() {
        const rawEntries = super.getCurrentPageEntries();
        if (!rawEntries || !rawEntries.length || !this.groupsManager.enabledGroupByFields.length) {
            return rawEntries;
        }
        const entries = generateAggregateOrderedEntries(rawEntries, this.groupsManager.enabledGroupByFields, {});
        // Filter out aggregate entries that only have a single group below them, as they only clutter the UI.
        return entries.filter((entry, index) => {
            return index + 1 === entries.length
                || entry.entry.count !== entries[index + 1].entry.count
                || entry.groupByFields.length >= entries[index + 1].groupByFields.length;
        });
    }
}

export class AggregateDataFilters extends UI.Element {
    render() {
        const {groupsManager} = this.options;
        return [
            "Group by",
            groupsManager.availableGroupByFields.map((groupByField, index) => <div key={`groupBy##${groupByField.field}`} style={{display: "flex"}}>
                <ArrowUpIcon size={21} style={{cursor: index > 0 ? "pointer" : "not-allowed", display: "inline-block"}}
                             onClick={() => groupsManager.swapGroupByFieldIndex(index - 1)}/>
                <ArrowDownIcon size={21} style={{cursor: index < groupsManager.availableGroupByFields.length - 1 ? "pointer" : "not-allowed", display: "inline-block"}}
                               onClick={() => groupsManager.swapGroupByFieldIndex(index)}/>
                <LabeledCheckbox label={groupByField.name}
                                 initialValue={groupsManager.enabledGroupByFields.indexOf(groupByField) >= 0}
                                 onChange={() => groupsManager.toggleGroupByField(groupByField)}/>
            </div>),
        ]
    }
}
