import {UI} from "../../../stem-core/src/ui/UIBase";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {LoadingSpinner} from "../../../blinkpay/ui/LoadingSpinner";
import {apiMerchantUpdateEmailSender} from "../../../client/state/merchant/MerchantEmailSender";
import {Toast} from "../../../blinkpay/ui/Toast";
import {BlinkInput} from "../../../blinkpay/ui/Input";

export class MerchantEditEmailSenderModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Edit email sender",
            confirmLabel: "Confirm",
            emailSender: null,
            confirmAction: () => this.editEmailSender(),
        };
    }

    async editEmailSender() {
        const request = {
            emailSenderId: this.options.emailSender.id,
            name: this.nameInput.getValue().trim(),
        }
        LoadingSpinner.show();
        try {
            await apiMerchantUpdateEmailSender(request);
            this.hide();
        } catch (error) {
            Toast.showError(error);
        } finally {
            LoadingSpinner.hide();
        }
    }

    render() {
        const {emailSender} = this.options;

        return <div>
            <BlinkInput initialValue={emailSender.emailAddress}
                        editable={false}
                        label={"Email"}
                        inputAttributes={{placeholder: "Email"}}
                        validators={[{condition: () => !this.inputRefs.emailInput.isEmpty()}]}
            />
            <BlinkInput ref={"nameInput"}
                        initialValue={emailSender.name}
                        label={"Name (Optional)"}
                        inputAttributes={{placeholder: "Name"}}
            />
        </div>
    }
}
