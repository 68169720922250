import {UI} from "../../../stem-core/src/ui/UIBase";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {DashboardProgressBar} from "../theme/DashboardProgressBar";
import {Level} from "../../../stem-core/src/ui/Constants";
import {TaskStatus} from "../../../client/state/misc/GenericEnums";
import {bytesToSize} from "../../../blinkpay/Utils";
import {DownloadButton} from "../../ui/DownloadButton";
import {Messages} from "../../../blinkpay/Messages";
import {formatPercent} from "../Utils";
import {Modal} from "../../../blinkpay/ui/Modal";
import {Link} from "../../../stem-core/src/ui/UIPrimitives";

export class ReportDownloadButton extends DownloadButton {
    getIcon() {
        if (!this.options.report.isSucceeded()) {
            return;
        }
        return super.getIcon();
    }

    getLabel() {
        const {report} = this.options;
        if (report.isSucceeded()) {
            return Messages.download;
        }

        if (report.isInProgress()) {
            const progressPercent = formatPercent(100 * report.progress, 1);

            return <span>{Messages.reportProcessing} {progressPercent}</span>;
        }

        if (report.status == TaskStatus.PENDING) {
            return "Waiting..."
        }

        return "Generating Error";
    }

    getFileResource() {
        return this.options.report.s3File;
    }

    redraw() {
        const {report} = this.options;

        // Options for parent logic
        this.options.disabled = !report.isSucceeded();

        return super.redraw();
    }
}


@autoredraw
export class MerchantReportProgressModal extends Modal {
    getReportStatus(status) {
        if (status === TaskStatus.SUCCEEDED) {
            return "Report successfully generated";
        }
        if (status === TaskStatus.FAILED) {
            return "Error while generating your report. Try again and reach out if the problem persists."
        }
        if (status === TaskStatus.IN_PROGRESS) {
            return "Report generation in progress. You can continue your work and this report will be generated in the background, to be accessible later from the Reports page.";
        }
        return "Preparing data for report...";
    }

    render() {
        const {merchantReport} = this.options;
        const progressLevel = merchantReport.isSucceeded() ? Level.SUCCESS : (merchantReport.status === TaskStatus.FAILED ? Level.ERROR : Level.PRIMARY);
        const fileSize = bytesToSize(merchantReport.fileSize) || "-";

        return [
            <div>
                <p>{this.getReportStatus(merchantReport.status)}</p>
                <DashboardProgressBar level={progressLevel} value={merchantReport.progress}/>
            </div>,
            <div style={{marginTop: 12, textAlign: "center"}}>
                File size: {fileSize} <ReportDownloadButton report={merchantReport} />
                <p><Link href={"/reports"}>Go to reports page</Link></p>
            </div>
        ];
    }
}
