import {UI} from "../../stem-core/src/ui/UIBase";
import {DashboardBaseInput} from "../../core/ui/BaseInput";
import {ArrayInput} from "./input/ArrayInput";
import {Select} from "../../stem-core/src/ui/input/Input";
import {MerchantUserJourneyStore} from "../../client/state/merchant/MerchantUserJourneyStore";
import {RadioButtons} from "../../blinkpay/ui/Button";
import {isDeepEqual} from "../../blinkpay/UtilsLib.js";

export const JourneyActiveChoice = {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    ANY: "Any",
};

class JourneyFilterInput extends DashboardBaseInput {
    render() {
        return [
            <div>{this.value.journey}</div>,
            <RadioButtons
                activeValue={this.value.journeyActiveChoice}
                values={[JourneyActiveChoice.ACTIVE, JourneyActiveChoice.INACTIVE, JourneyActiveChoice.ANY]}
                onSelect={(journeyActiveChoice) => this.setAndDispatchValue({
                    journey: this.value.journey,
                    journeyActiveChoice,
                })} />,
        ];
    }
}

class JourneysArrayInput extends ArrayInput {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            box: false,
            addButtonText: "Add journey",
            initialValue: [],
            ElementInputClass: JourneyFilterInput,
            elementInputOptions: {
                style: {
                    display: "flex",
                },
            },
        };
    }

    isEqual(valueA, valueB) {
        return isDeepEqual(valueA, valueB);
    }

    addElement() {
        this.value.push({
            journey: this.journeySelect.getValue(),
            journeyActiveChoice: JourneyActiveChoice.ACTIVE,
        });
        this.redraw();
        this.setAndDispatchValue(this.value);
    }

    renderAddButton() {
        const {merchant} = this.options;
        const journeysUsedInFilter = this.value.map(valueElement => valueElement.journey);
        const availableJourneys = MerchantUserJourneyStore
            .filter(journey => journey.merchant === merchant && journey.settingsVersion.isMaster)
            .filter(journey => journeysUsedInFilter.indexOf(journey) === -1);

        return [
            <Select ref="journeySelect" options={availableJourneys} style={{marginRight: 16}}/>,
            super.renderAddButton(),
        ];
    }
}

export class JourneysFilter extends DashboardBaseInput {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            initialValue: [],
        };
    }

    isEqual(valueA, valueB) {
        return isDeepEqual(valueA, valueB);
    }

    // Returns the value formatted properly for backend filters.
    getValue() {
        const filterValue = {
            activeJourneys: [],
            inactiveJourneys: [],
        };
        for (const {journey, journeyActiveChoice} of this.value) {
            if (journeyActiveChoice === JourneyActiveChoice.ACTIVE) {
                filterValue.activeJourneys.push(journey.alias);
            } else if (journeyActiveChoice === JourneyActiveChoice.INACTIVE) {
                filterValue.inactiveJourneys.push(journey.alias);
            }
        }
        return filterValue;
    }

    render() {
        return [
            <JourneysArrayInput ref="arrayInput" merchant={this.options.merchant} initialValue={this.value}
                                onChange={(journeyChoices) => this.setAndDispatchValue(journeyChoices)} />,
        ];
    }
}
