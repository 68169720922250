import {UI} from "../../../stem-core/src/ui/UIBase";
import {DashboardTitle} from "../../common/DashboardTitle";
import {merchantService} from "../../misc/MerchantService";
import {
    apiMerchantEmailingBreakdown,
    apiUpdateMerchantEmailingSettings,
    MerchantEmailingSettingsStore
} from "../../../client/state/merchant/MerchantEmailingSettingsStore";
import {BlinkInputField} from "../../common/Input";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {CollapsiblePanel} from "../../../stem-core/src/ui/collapsible/CollapsiblePanel";
import {TopLevelTabArea} from "../../common/theme/TabAreaStyle";
import {Panel} from "../../../stem-core/src/ui/UIPrimitives";
import {DateRangeInput, DateRangeInterval, DefaultMerchantDateRangeIntervals} from "../../ui/input/DateRangeInput";
import {Level} from "../../../stem-core/src/ui/Constants";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {
    apiMerchantDeleteEmailSender,
    MerchantEmailSenderStore
} from "../../../client/state/merchant/MerchantEmailSender";
import {LEFT_COLUMN} from "../../common/theme/TableStyle";
import {SimpleTable} from "../../ui/SimpleTable";
import {MerchantAddEmailSenderModal} from "../../common/modals/MerchantAddEmailSenderModal";
import {MerchantEditEmailSenderModal} from "../../common/modals/MerchantEditEmailSenderModal";
import {Toast} from "../../../blinkpay/ui/Toast";
import {Select} from "../../../stem-core/src/ui/input/Input";
import {EmailSendingStoreObject} from "../../../client/state/merchant/MerchantEmailCampaignStore";
import {EmailSendingObjectStatistics} from "./EmailCampaignSendingPanel";
import {MultiEmailAddressInput} from "../../common/Input";
import {InfoBlockGroup} from "../../common/InfoBlock";
import {MerchantAudienceStore} from "../../../client/state/merchant/MerchantAudienceStore";
import {MultiselectInput} from "../../common/MultiselectInput";
import {Chart} from "../../ui/Chart";
import {StemDate} from "../../../stem-core/src/time/Date";
import {Theme} from "../../../stem-core/src/ui/style/Theme";


@autoredraw(MerchantEmailSenderStore)
class MerchantEmailSenderEditor extends UI.Element {
    async deleteEmailSender(emailSender) {
        try {
            return apiMerchantDeleteEmailSender({
                merchantId: merchantService.getMerchant().id,
                emailSenderId: emailSender.id,
            })
        } catch (e) {
            // TODO - have a generic way of displaying a Toast error
            Toast.show("Error deleting email sender.")
        }
    }

    render() {
        const merchant = merchantService.getMerchant();
        const emailSenders = MerchantEmailSenderStore.filterBy({merchantId: merchant.id});
        const columns = [
            ["Name", emailSender => emailSender.name || "-", LEFT_COLUMN],
            ["Email", emailSender => emailSender.emailAddress, LEFT_COLUMN],
            ["Actions", emailSender => {
                return [
                    <Button onClick={() => MerchantEditEmailSenderModal.show({emailSender})} label="Edit"/>,
                    <Button onClick={() => this.deleteEmailSender(emailSender)} label="Delete"/>
                ]
            }]
        ]

        return (
            <div style={{margin: "20px 0px"}}>
                <Button style={{marginBottom: 12}} onClick={() => MerchantAddEmailSenderModal.show()}
                        label="New email sender"/>
                <SimpleTable columns={columns} entries={emailSenders}/>
            </div>
        )
    }
}

@autoredraw(MerchantEmailSenderStore)
class MerchantEmailingSettingsEditor extends UI.Element {
    inputRefs = {};
    blink_default_sending_email = "Blink default"

    getDefaultOptions() {
        return {
            title: "General Settings",
        }
    }

    async updateEmailingSettings() {
        const request = {
            defaultSenderId: this.getSelectedEmailSender()?.id,
            emailReplyTo: this.inputRefs.emailReplyToInput?.getValue().trim(),
            bccEmails: this.inputRefs.bccEmailsInput.getValue(),
        }

        try {
            await apiUpdateMerchantEmailingSettings(request);
            Toast.showSuccess("Saved");
        } catch (error) {
            Toast.showError(error);
        }

    }

    getSelectedEmailSender() {
        return this.emailSenderSelector?.getValue();
    }

    render() {
        const {merchantEmailingSettings} = this.options;
        const defaultSender = merchantEmailingSettings.defaultSender;
        const senderDropdownOptions = MerchantEmailSenderStore.all();

        return [
            <div>
                Control the default when Blink sends emails on your behalf.
                All these settings can be overwritten by individual templates/campaigns.
            </div>,
            <MerchantEmailSenderEditor/>,
            <div>
                <Button label="Save changes" onClick={() => this.updateEmailingSettings()}/>
                <Button level={Level.SECONDARY} label="Discard changes"/>
            </div>,
            <BlinkInputField label="Default sending email address">
                {senderDropdownOptions.length === 0 ?
                    <TextInput disabled={true} placeholder={this.blink_default_sending_email}/> :
                    <Select ref="emailSenderSelector"
                            options={senderDropdownOptions}
                            onChange={() => this.redraw()}
                            selected={this.getSelectedEmailSender() || defaultSender}
                    />
                }
            </BlinkInputField>,
            <div>
                <BlinkInputField label="Default sending name">
                    <TextInput disabled={true}
                               placeholder={this.blink_default_sending_email}
                               value={this.getSelectedEmailSender()?.name || defaultSender?.name}/>
                </BlinkInputField>
            </div>,
            <div>
                <BlinkInputField label="Default reply-to email address">
                    <TextInput ref={{parent: this.inputRefs, name: "emailReplyToInput"}}
                               value={merchantEmailingSettings.emailReplyTo || ""}
                    />
                </BlinkInputField>
            </div>,
            <div>
                <BlinkInputField label="Default bcc addresses">
                    <MultiEmailAddressInput ref={{parent: this.inputRefs, name: "bccEmailsInput"}}
                                            value={merchantEmailingSettings.bccEmails}
                    />
                </BlinkInputField>
            </div>,
        ];
    }
}


class MerchantEmailOverviewSection extends Panel {
    lastResponse = null;
    lastError = null;

    async applyFilters() {
        const {startDate, endDate} = this.dateRangeInput.getValue();
        this.lastError = this.lastResponse = null;
        this.redraw();
        const filters = {
            startDate,
            endDate,
        };
        const selectedAudiences = this.audiencesInput.getValue();
        if (selectedAudiences.length !== MerchantAudienceStore.filterBy({merchant: merchantService.getMerchant()}).length) {
            filters.audienceIds = selectedAudiences.map(audience => audience.id);
        }
        try {
            this.lastResponse = await apiMerchantEmailingBreakdown(filters);
            this.lastResponse.dateHistogram = this.lastResponse.dateHistogram?.map(d => ({
                ...d,
                date: new StemDate(d.date)
            })) || [];
        } catch (error) {
            this.lastError = error;
        }

        this.redraw();
    }

    renderSummary() {
        const {lastResponse, lastError} = this;
        if (lastError) {
            return "Error in loading statistics: " + lastError;
        }
        if (!lastResponse) {
            return;
        }

        const emailStatistics = new EmailSendingStoreObject(lastResponse);

        return [
            <div style={{display: "flex"}}>
                <EmailSendingObjectStatistics emailSendingObject={emailStatistics} style={{display: "inline-flex"}}/>
                <InfoBlockGroup label="Newsletter subscribers" entries={[
                    ["Newly subscribed", lastResponse.subscribersCount || 0],
                    ["Unsubscribed", lastResponse.unsubscribersCount || 0],
                ]}/>
            </div>
        ];
    }

    renderNewSubscribersChart() {
        if (!this.lastResponse) {
            return;
        }
        return [
            <DashboardTitle
                title="Newsletter size changes"
                description="Visualize the growth of your newsletter audiences over time"
            />,
            <Chart {...this.dateRangeInput.getValue()}
                   data={this.lastResponse.dateHistogram}
                   tooltipOptions={{displayTotal: false}}
                   categories={[{
                       name: "Unsubscribed",
                       value: "unsubscribersCount",
                       color: Theme.props.CHART_BAR_BACKGROUND_COLOR_1,
                   }, {
                       name: "Newly subscribed",
                       value: "subscribersCount",
                       color: Theme.props.CHART_BAR_BACKGROUND_COLOR_3,
                   }]}/>];
    }

    render() {
        const merchant = merchantService.getMerchant();
        const allAudiences = MerchantAudienceStore.filterBy({merchant});
        return [
            <h2>Filter to get general information.</h2>,
            <BlinkInputField>
                <DateRangeInput
                    ref="dateRangeInput"
                    style={{maxWidth: 360}}
                    options={DefaultMerchantDateRangeIntervals(merchant)}
                    selectedInterval={DateRangeInterval.LAST_30_DAYS}
                    onChange={() => this.applyFilters()}
                />
            </BlinkInputField>,
            <CollapsiblePanel title="Filter by audience" collapsed={true}>
                <MultiselectInput options={allAudiences} initialValue={allAudiences} ref="audiencesInput"
                                  onChange={() => this.applyFilters()}/>
            </CollapsiblePanel>,
            <CollapsiblePanel title="Summary & Statistics" collapsed={false} style={{marginTop: 36}}>
                {this.renderSummary()}
            </CollapsiblePanel>,

            this.renderNewSubscribersChart(),
        ]
    }

    async onMount() {
        await this.applyFilters();
    }
}

export class MerchantEmailsPage extends UI.Element {
    render() {
        const merchant = merchantService.getMerchant();
        const merchantEmailingSettings = MerchantEmailingSettingsStore.find(obj => obj.merchantId === merchant.id);

        return [
            <DashboardTitle
                title="Emailing"
                description="Statistics on emailing"
            />,
            <TopLevelTabArea>
                <MerchantEmailOverviewSection title="Overview" merchant={merchant}/>
                <MerchantEmailingSettingsEditor title="General Settings"
                                                merchantEmailingSettings={merchantEmailingSettings}/>
            </TopLevelTabArea>
        ]
    }
}
