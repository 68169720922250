import {UI} from "../../../stem-core/src/ui/UIBase";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {Link} from "../../../stem-core/src/ui/UIPrimitives";
import {DashboardTitle} from "../../common/DashboardTitle";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {MerchantSDKSettingsStore} from "../../../client/state/merchant/MerchantSDKSettingsStore";
import {MerchantUserJourneyStore} from "../../../client/state/merchant/MerchantUserJourneyStore";
import {getActiveSettingsVersion} from "../../misc/MerchantService";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style";
import {NON_BREAKING_SPACE} from "../../common/Utils";
import {JourneyEntrypointState} from "./active-journeys/JourneyEntrypointState.js";
import {AddJourneyToEntrypointModal} from "./active-journeys/AddJourneyToEntrypointModal.jsx";
import {SaveOrDiscardButtons} from "../../common/SaveOrDiscardButtons.jsx";
import {JourneyEntrypointElement} from "./active-journeys/JourneyEntrypointElement.jsx";


class ActiveJourneysPageStyle extends StyleSheet {
    @styleRule
    actionButtonAreaLine = {
        display: "flex",
        alignItems: "center",
        paddingBottom: 8,
    };
}


@registerStyle(ActiveJourneysPageStyle)
@autoredraw(MerchantSDKSettingsStore, MerchantUserJourneyStore)
export class ActiveJourneysPage extends UI.Element {
    state = this.loadState();

    getJourneyEntrypoints() {
        return this.state.entries;
    }

    loadState() {
        const settingsVersion = getActiveSettingsVersion(this.options.merchant);
        const state = new JourneyEntrypointState(settingsVersion);
        this.attachListener(state, "change", () => this.redraw());
        return state;
    }

    render() {
        const description = <div>
            Choose which journeys are enabled for your users. Enabled
            journeys are split in groups. If a group has a single
            journey in it, that journey will be enabled for all users. If
            there is more than one journey in a group, each
            user will only have one of them enabled (randomly chosen). You
            can create and edit user journeys on the <Link href="/journeys">Journeys</Link> page.
        </div>

        const dashboardTitleElement = <DashboardTitle title="Active Journeys" description={description}/>;
        const userJourneysAvailable = MerchantUserJourneyStore.filterBy({settingsVersion: getActiveSettingsVersion(this.options.merchant)});
        if (!userJourneysAvailable.length) {
            return [
                dashboardTitleElement,
                <div>
                    You have no journeys configured. To create one go do the <Link href="/journeys">Journeys</Link> page.
                </div>
            ];
        }

        const journeyEntrypoints = this.getJourneyEntrypoints();

        return [
            dashboardTitleElement,

            <div className={this.styleSheet.actionButtonAreaLine}>
                <Button onClick={() => this.addNewJourneyEntrypoint()}>Add new entry</Button>
                <SaveOrDiscardButtons state={this.state}/>
            </div>,

            journeyEntrypoints.map((journeyEntrypoint, index) => <JourneyEntrypointElement
                journeyEntrypoint={journeyEntrypoint}
                index={index}
                state={this.state}
                onMoveDown={(index) => this.state.moveDownEntry(index)}
                onMoveUp={(index) => this.state.moveUpEntry(index)}
                onRemoveEntrypoint={(index) => this.state.removeEntry(index)}
                onAddJourney={(index) => this.addJourneyToEntrypoint(index)}
            />),
        ];
    }

    async addJourneyToEntrypoint(index) {
        const availableJourneys = this.state.entries[index].getAvailableJourneys();
        const selectedJourney = await AddJourneyToEntrypointModal.prompt({
            availableJourneys,
            description: "Select another journey to add to this split. You cannot choose journeys that are already in this split.",
        });
        if (selectedJourney) {
            this.state.addJourneyToEntrypoint(index, selectedJourney);
        }
    }

    getAllAvailableJourneys() {
        return MerchantUserJourneyStore.filterBy({
            settingsVersion: getActiveSettingsVersion(this.options.merchant),
        });
    }

    async addNewJourneyEntrypoint() {
        const newJourney = await AddJourneyToEntrypointModal.prompt({
            availableJourneys: this.getAllAvailableJourneys(),
            title: "Add a new active journey",
            description: "This will add a new journey to be executed at the end. It's order can be changed later.",
        });
        if (newJourney) {
            this.state.appendEntrypoint(newJourney);
        }
    }
}
