import {Level, UI} from "stem-core/src/ui/UI";
import {registerStyle, styleRule} from "stem-core/src/ui/UI";
import {DashboardCard, DashboardCardStyle} from "../../ui/DashboardCard";
import {Messages} from "../../../blinkpay/Messages";
import {Frequencies} from "./Frequencies";
import {LoadingSpinner} from "../../../blinkpay/ui/LoadingSpinner";
import {merchantService} from "../../misc/MerchantService";
import {AmountOptions} from "./AmountOptions";
import {apiUpdateDonationOffer, DonationOfferStore} from "../../../client/state/DonationOfferStore";
import {Button} from "stem-core/src/ui/UI";


class DonationOptionsStyle extends DashboardCardStyle {
    @styleRule
    container = {
        display: "flex",
        justifyContent: "center",
    };

    @styleRule
    section = {
        padding: "0px 30px",
        maxWidth: 340,
    };

    @styleRule
    buttonsContainer = {
        display: "flex",
        justifyContent: "center",
        margin: "48px 0",
    };

    @styleRule
    buttonsDivider = {
        width: 18,
    };
}

@registerStyle(DonationOptionsStyle)
export class DonationOptions extends DashboardCard {
    render() {
        const {styleSheet} = this;
        const {donationOffer} = this.options;

        return [
            <div className={styleSheet.container}>
                <AmountOptions
                    className={styleSheet.section}
                    ref="amountOptions"
                    donationOffer={donationOffer}
                />
                <Frequencies
                    className={styleSheet.section}
                    ref="frequencies"
                    donationOffer={donationOffer}
                />
            </div>,
            <div className={styleSheet.buttonsContainer}>
                <Button level={Level.SECONDARY}
                        disabled={!this.isChanged()}
                        onClick={() => this.cancelUpdates()}>
                    {Messages.cancel}
                </Button>
                <div className={styleSheet.buttonsDivider}/>
                <Button disabled={!this.isChanged()}
                    onClick={() => !this.hasInputError() && this.submitUpdates()}>
                    {Messages.saveChanges}
                </Button>
            </div>
        ]
    }

    getFormChildren() {
        return [
            this.amountOptions,
            this.frequencies,
        ].filter(e => !!e);
    }

    isChanged() {
        return this.getFormChildren().reduce((value, child) => value || child.isChanged(), false);
    }

    hasInputError() {
        return this.getFormChildren().reduce((value, child) => value || child.hasInputError(), false);
    }

    cancelUpdates() {
        this.resetChildrenState();
        this.redraw();
    }

    resetChildrenState() {
        this.getFormChildren().forEach((child) => child.resetState());
    }

    async submitUpdates() {
        LoadingSpinner.show();
        try {
            await apiUpdateDonationOffer(this.getData());
            const donationOffer = DonationOfferStore.getByMerchantId(merchantService.getMerchantId())[0];
            this.updateOptions({...this.options, donationOffer});
            this.resetChildrenState();
        } catch (error) {
            console.log(error);
        } finally {
            LoadingSpinner.hide();
        }
    }

    getData() {
        const donationOffer = this.options.donationOffer;
        const initialData = {
            recurringOfferId: donationOffer.id,
            merchantId: donationOffer.merchantId,
        };
        return this.getFormChildren().reduce((data, child) => ({...data, ...child.getData()}), initialData);
    }

    onMount() {
        super.onMount();
        this.getFormChildren().forEach((child) => {
            child.addUpdateListener(() => this.redraw());
        });
    }
}
