import {UI} from "../stem-core/src/ui/UI";
import {registerStyle} from "../stem-core/src/ui/UI";
import {Route, Router} from "../stem-core/src/ui/Router";

import {LoadingSpinner} from "../blinkpay/ui/LoadingSpinner";

import {DashboardSidePanel} from "./DashboardSidePanel";
import {merchantService} from "./misc/MerchantService";
import {authService} from "../client/connection/Services";
import {SidebarMerchantSelector} from "./ui/SidebarMerchantSelector";
import {MerchantRevenuePage} from "./pages/MerchantRevenuePage";
import {
    DonationsIcon,
    EmailIcon, MerchantIcon,
    ReportsIcon,
    RevenueIcon,
    SubscriptionsIcon,
    UserExperienceIcon,
    UsersIcon
} from "./NavSidePanelIcons";
import {MerchantPaymentsPage} from "./pages/MerchantPaymentsPage";
import {MerchantPayoutsPage} from "./pages/MerchantPayoutsPage";
import {MerchantUsersPage} from "./pages/MerchantUsersPage";
import {MerchantSubscriptionsPage} from "./pages/subscriptions/MerchantSubscriptionsPage";
import {MerchantSubscribersPage} from "./pages/subscriptions/MerchantSubscribersPage";
import {MerchantDonationSettingsPage} from "./pages/donations/MerchantDonationSettingsPage";
import {MerchantDonorsPage} from "./pages/donations/MerchantDonorsPage";
import {MerchantReportsPage} from "./pages/MerchantReportsPage";
import {Page} from "./PageHandler";
import {DashboardPageStyle} from "./common/theme/DashboardTheme";
import {ActiveJourneysPage} from "./pages/user-experience/ActiveJourneysPage.jsx";
import {MerchantJourneysPage} from "./pages/user-experience/MerchantJourneysPage.jsx";
import {MerchantPanelsPage} from "./pages/user-experience/MerchantPanelsPages.jsx";
import {MerchantEmailsPage} from "./pages/emailing/MerchantEmailsPage";
import {MerchantEmailTemplatesPage} from "./pages/emailing/MerchantEmailTemplatesPage";
import {MerchantAudiencesPage} from "./pages/emailing/MerchantAudiencesPage";
import {MerchantEmailCampaignsPage} from "./pages/emailing/MerchantEmailCampaignsPage";
import {MerchantAdministrationPage} from "./pages/MerchantAdministrationPage";
import {MerchantSubscriptionDiscountsPage} from "./pages/subscriptions/MerchantSubscriptionDiscountsPage";
import {MerchantNewsletterMembersPage} from "./pages/emailing/MerchantNewsletterMembersPage";
import {MerchantIntegrationsPage} from "./pages/MerchantIntegrationsPage";
import {MerchantLoggingPage} from "./pages/MerchantLoggingPage";
import {MerchantDonationsPage} from "./pages/donations/MerchantDonationsPage";
import {GearIcon} from "./SVGElements";
import {DashboardSettingsPage} from "./pages/DashboardSettingsPage";
import {DraftVersionLabel, UserExperiencePage} from "./pages/user-experience/UserExperiencePage";
import {ShoppingBagIcon} from "../blinkpay/SVGElements.jsx";
import {MerchantProductsPage} from "./pages/products/MerchantProductsPage.jsx";
import {MerchantPurchasesPage} from "./pages/products/MerchantPurchasesPage.jsx";
import {MerchantStore} from "../client/state/MerchantStore.js";


function MakeDashboardPages(merchant) {
    // TODO @branch @Mihai enable certain functionality only when the merchant supports it

    return [
        new Page("revenue", "Revenue", MerchantRevenuePage, {icon: RevenueIcon}),
        new Page("payments", "All Payments", MerchantPaymentsPage),
        new Page("payouts", "Payouts", MerchantPayoutsPage),

        new Page("users", "Users", MerchantUsersPage, {icon: UsersIcon}),

        // new Page("products", "Products", MerchantProductsPage, {icon: ShoppingBagIcon}),
        // new Page("purchases", "Purchases", MerchantPurchasesPage),

        new Page( "subscriptions", "Subscriptions", MerchantSubscriptionsPage, {icon: SubscriptionsIcon}),
        new Page("subscription-discounts", "Subscription Discounts", MerchantSubscriptionDiscountsPage),
        // new Page("group-subscriptions", "Group Subscriptions", MerchantGroupSubscriptionsPage),
        new Page( "subscribers", "Subscribers", MerchantSubscribersPage),

        new Page("donation-settings", "Donation Settings", MerchantDonationSettingsPage, {icon: DonationsIcon}),
        new Page("donors", "Donors", MerchantDonorsPage),
        new Page("donations", "Donations", MerchantDonationsPage),

        new Page("emails", "Emailing", MerchantEmailsPage, {icon: EmailIcon}),
        new Page("email-newsletters", "Audiences & Newsletters", MerchantAudiencesPage),
        new Page("email-newsletters-members", "Newsletter Members", MerchantNewsletterMembersPage),
        new Page("email-campaigns", "Campaign Emails", MerchantEmailCampaignsPage),
        new Page("event-emails", "Event-triggered Emails", MerchantEmailTemplatesPage),

        new Page("user-experience", "User Experience", UserExperiencePage, {
            icon: UserExperienceIcon,
            LabelClass: DraftVersionLabel,
        }),
        new Page("active-journeys", "Active Journeys", ActiveJourneysPage),
        new Page("journeys", "Journeys", MerchantJourneysPage),
        new Page("panels", "Panels", MerchantPanelsPage),

        new Page("reports", "Reports", MerchantReportsPage, {icon: ReportsIcon}),

        new Page("administration", "Administration", MerchantAdministrationPage, {icon: MerchantIcon}),
        new Page("integrations", "Integrations", MerchantIntegrationsPage),
        new Page("logs", "Logs", MerchantLoggingPage),

        new Page("settings", "Dashboard Settings", DashboardSettingsPage, {icon: GearIcon}),
    ]
}

@registerStyle(DashboardPageStyle)
export class DashboardPage extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.dashboardPage);
    }

    render() {
        if (!merchantService.isMerchantDataRequestFinished()) {
            return [
                // TODO: this isn't the whole page
                <div className={this.styleSheet.mainPage}>
                    <LoadingSpinner />
                </div>,
            ];
        }

        if (MerchantStore.all().length === 0) {
            // TODO @branch @Mihai this also happens on server error on init, fix then
            Router.changeURL("/switch-account", {replaceHistory: true});
            return [];
        }

        const merchant = merchantService.getMerchant()

        if (!merchant) {
            Router.changeURL("/choose-merchant", {replaceHistory: true});
            return [];
        }

        const pages = MakeDashboardPages(merchant);
        const routes = new Route(null, null, pages.map(page => page.toRoute(null)));

        const handleLogout = async () => {
            await authService.logout();
            // We refresh the page on logout to make sure onMount is executed when accessing the pages after logging in again
            window.location.href = "/";
        };

        // TODO: merge with Admin logic?
        return [
            <DashboardSidePanel ref="navSidePanel"
                                handleLogout={handleLogout}
                                pages={pages}>
                <SidebarMerchantSelector />
            </DashboardSidePanel>,

            <div className={this.styleSheet.mainPage}>
                <Router ref="pageSwitcher"
                        style={{maxWidth: 1068}}
                        routes={routes}/>
            </div>,
        ];
    }

    setURL(urlParts) {
        this.navSidePanel?.setURL(urlParts[0]);
        this.pageSwitcher?.setURL(urlParts);
    }

    onMount() {
        this.attachListener(merchantService, "merchantDataRequestFinished", () => {
            this.redraw();
            this.setURL(Router.parseURL());
        });
    }
}
