import {UI} from "../../../../stem-core/src/ui/UIBase";
import {IFrame} from "../../../../stem-core/src/ui/UIPrimitives";
import {BLINK_PAY_URL} from "../../../../blink-sdk/Constants";
import {merchantService} from "../../../misc/MerchantService";
import {styleRule, StyleSheet} from "../../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";
import {LinearSizeConverter, RangeSlider} from "../../../../blinkpay/ui/RangeSlider";
import {Direction} from "../../../../stem-core/src/ui/Constants";


class PanelEditorPreviewStyle extends StyleSheet {
    @styleRule
    panelEditorPreview = {
        display: "flex",
        justifyContent: "space-around",
    };

    @styleRule
    slidersAreaContainer = {
        display: "flex",
        justifyContent: "space-around",
    }

    @styleRule
    slidersArea = {
        display: "flex",
    };

    @styleRule
    sliderBlock = {
        display: "inline-flex",
        alignItems: "center",
        minWidth: 320,
    };

    @styleRule
    rangeSlider = {
        width: 300,
        margin: "12px 0",
    };
}

@registerStyle(PanelEditorPreviewStyle)
class PanelEditorPreviewIFrameContainer extends UI.Element {
    isPreviewIFrameReady = false;

    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.panelEditorPreview);
    }

    render() {
        const sdkScriptURL = encodeURIComponent(`${BLINK_PAY_URL}/1.0/blink-sdk.js`);
        const previewIFrameSrc = `/panel-editor-preview.html?sdkScriptURL=${sdkScriptURL}`;
        return <IFrame ref="previewIFrame" src={previewIFrameSrc}
                       style={{height: this.options.height, width: this.options.width, position: "absolute",
                               left: 0, right: 0, margin: "auto"}} />;
    }

    redraw() {
        if (!this.previewIFrame) {
            super.redraw();
        } else {
            // TODO: Throttle this!
            this.updatePreviewIFrame();
        }
    }

    updatePreviewIFrame() {
        if (this.isPreviewIFrameReady && this.previewIFrame.node.contentWindow) {
            this.previewIFrame.node.contentWindow.postMessage(this.options.panelOptions, window.origin);
            this.previewIFrame.setStyle({
                height: this.options.height,
                width: this.options.width,
            });
        }
    }

    handlePreviewIFrameMessage(data) {
        if (data === "ReadyForInitialOptions") {
            this.previewIFrame.node.contentWindow.postMessage({
                sdkInitOptions: {
                    clientId: merchantService.getMerchant().alias,
                    hideWallet: true,
                    journeys: null,
                },
                panelOptions: this.options.panelOptions,
            }, window.origin);
            this.updatePreviewIFrame();
        } else if (data === "ReadyForUpdates") {
            this.isPreviewIFrameReady = true;
            this.updatePreviewIFrame();
        }
    }

    onMount() {
        this.attachEventListener(window, "message", (event) => {
            if (event.origin !== window.origin) {
                return;
            }
            if (event.source !== this.previewIFrame.node.contentWindow) {
                return;
            }
            this.handlePreviewIFrameMessage(event.data);
        });
    }
}

@registerStyle(PanelEditorPreviewStyle)
export class PanelEditorPreview extends UI.Element {
    height = 600;
    width = 720;

    updatePreview(settings = {}) {
        Object.assign(this, settings);
        this.previewIFrameContainer?.updateOptions(this.getPreviewContainerOptions());
    }

    getPreviewContainerOptions() {
        const {height, width} = this;
        return {
            panelOptions: this.options.state.getPreviewPanelOptions(),
            height,
            width,
        };
    }

    render() {
        const {styleSheet} = this;

        return [
            <div className={styleSheet.slidersAreaContainer}>
                <div className={styleSheet.slidersArea}>
                    <div className={styleSheet.sliderBlock} style={{marginRight: 12}}>
                        <div style={{paddingRight: "4px"}}>Height</div>
                        <RangeSlider
                            initialValue={this.height} direction={Direction.UP}
                            className={styleSheet.rangeSlider}
                            converter={new LinearSizeConverter(400, 1080)}
                            onChange={(value) => this.updatePreview({height: value})}/>
                    </div>
                    <div className={styleSheet.sliderBlock}>
                        <div style={{paddingRight: "4px"}}>Width</div>
                        <RangeSlider
                            initialValue={this.width} direction={Direction.UP}
                            className={styleSheet.rangeSlider}
                            converter={new LinearSizeConverter(360, 1440)}
                            onChange={(value) => this.updatePreview({width: value})}/>
                    </div>
                </div>
            </div>,
            <PanelEditorPreviewIFrameContainer ref="previewIFrameContainer"
                                            style={{height: 800, position: "relative"}}
                                            {...this.getPreviewContainerOptions()} />,
        ];
    }

    onMount() {
        this.attachChangeListener(this.options.state, () => this.updatePreview());
    }
}
