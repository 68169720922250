import {UI} from "../../../stem-core/src/ui/UIBase.js";
import {BlinkInputField} from "../../common/Input.jsx";
import {SearchInput} from "./SearchInput.jsx";
import {Button} from "../../../stem-core/src/ui/button/Button.jsx";
import {ChevronDownIcon} from "../../../blinkpay/SVGElements.jsx";
import {Level} from "../../../stem-core/src/ui/Constants.js";
import {DashboardAnchoredPopup} from "./PopupInput.jsx";
import {AudienceExplicitMembershipFilter} from "../../pages/emailing/MerchantAudienceMembershipFilters.js";
import {MerchantAudienceStore} from "../../../client/state/merchant/MerchantAudienceStore.js";
import {MerchantReportType} from "../../../client/state/merchant/MerchantReportStore.js";


// TODO @Mihai merge with DashboardAnchoredPopup/PopupInput
export class PopupPrompter extends DashboardAnchoredPopup {
    hide(response = null) {
        if (this.resolvePromise) {
            this.attachTimeout(() => this.resolvePromise(response), this.styleSheet.transitionTime);
        }
        super.hide();
    }

    static async prompt(...args) {
        let modal;
        modal = this.show(...args);

        if (!modal) {
            return null;
        }

        return new Promise(resolve => {
            modal.resolvePromise = (result) => {
                delete modal.resolvePromise;
                resolve(result);
            };
        });
    }
}


// TODO @Mihai probably inherit DashboardBaseInput
export class UserSearchInput extends UI.Primitive("span") {
    selectedAudienceIds = [];

    getDefaultOptions() {
        return {
            searchInputWidth: 280,
            filterByAudience: true,
        }
    }

    getValue() {
        // TODO: Make this significantly more generic.
        const allFilters = [];
        const nameOrEmailSearch = this.nameOrEmailInput.getValue().trim();
        if (nameOrEmailSearch) {
            allFilters.push({
                type: MerchantReportType.USERS,
                nameOrEmailSearch,
            });
        }
        allFilters.push(...this.selectedAudienceIds.map(audienceId => ({
            type: MerchantReportType.AUDIENCE_MEMBERS,
            audienceId,
        })));
        if (allFilters.length === 0) {
            return undefined;
        }
        if (allFilters.length === 1) {
            return allFilters[0];
        }
        return {
            type: "and",
            subfilters: allFilters,
        };
    }

    dispatchChange() {
        this.dispatch("change", this.getValue());
    }

    // TODO @cleanup remove all instances of addChangeListener if they only do this
    addChangeListener(callback) {
        return this.addListener("change", callback);
    }

    async promptAudienceInput() {
        if (PopupPrompter.lastShownModal) {
            return;
        }
        const audienceFilters = <AudienceExplicitMembershipFilter style={{margin: 10}}
                                                                  initialValue={{audienceIds: this.selectedAudienceIds}}/>

        await PopupPrompter.prompt({
            children: [audienceFilters],
            anchor: this,
        });

        this.selectedAudienceIds = audienceFilters.getValue().audienceIds;
        this.dispatchChange();
    }

    getAudiencesFilter() {
        if (!this.options.filterByAudience || !MerchantAudienceStore.all().length) {
            return;
        }
        return <Button level={Level.SECONDARY} onClick={() => this.promptAudienceInput()}>
            Filter by audience <ChevronDownIcon/>
        </Button>
    }

    render() {
        const {searchInputWidth} = this.options;
        return [
            <BlinkInputField label="Filter by email or name">
                <SearchInput
                    style={{width: searchInputWidth}}
                    inputAttributes={{placeholder: "Search by email or name"}}
                    ref="nameOrEmailInput"
                    onChange={() => this.dispatchChange()}
                />
            </BlinkInputField>,
            this.getAudiencesFilter(),
        ]
    }
}
