import {UI} from "../stem-core/src/ui/UI";
import {StemApp} from "../stem-core/src/app/StemApp";
import {ViewportMeta} from "../stem-core/src/ui/UIPrimitives";
import {StemDate} from "../stem-core/src/time/Time";
import {authService} from "../client/connection/Services";
import {merchantService} from "./misc/MerchantService";
import {ServerTime} from "../stem-core/src/time/Time";
import {ApiErrors} from "../client/connection/ApiErrors";
import {Router} from "../stem-core/src/ui/Router";
import {apiConnection} from "../client/connection/BlinkApiConnection";
import {LanguageStore} from "../client/state/LanguageStore";
import {englishTranslationMap} from "../blinkpay/Messages";
import {setLanguageStore} from "../stem-core/src/ui/Translation";

LanguageStore.importState([
    {
        id: 1,
        translationMap: englishTranslationMap,
    },
]);

setLanguageStore(LanguageStore);


// TODO: lots of duplicated code here, merge or remove
export class App extends StemApp {
    static init() {
        this.initializeTheme();
        this.initializeViewportMeta();
        this.addAjaxProcessors();
        this.fetch();
        return super.init();
    }

    static fetch() {
        authService.onAuthentication(() => {
            merchantService.ensureMerchantDataRequested();
        });
    }

    getContainer() {
        return this.getRouter();
    }

    static initializeViewportMeta() {
        return ViewportMeta.create(document.head);
    }

    static addAjaxProcessors() {
        const ajaxHandler = apiConnection.getAjaxHandler();

        // TODO: normalize all these postprocessors.
        // Logout user if the token is invalid
        ajaxHandler.addPostprocessor(response => {
            const {error} = response;
            if (!error) {
                return response;
            }

            if (error && (error.code === ApiErrors.AUTHENTICATION_FAILED || error.code === ApiErrors.NOT_AUTHENTICATED)) {
                authService.clearCurrentInfo();
                Router.changeURL("/");
                return null;
            }

            return response;
        });

        ajaxHandler.addPreprocessor((options) => {
            // add merchant id in each request body
            const merchantId = merchantService.getMerchantId();
            if (merchantId) {
                options.data.merchantId = merchantId;
            }
        });

        // Sync server time
        ajaxHandler.addPostprocessor((payload, xhrPromise) => {
            const responseHeaders = xhrPromise.getResponseHeaders();
            const responseDate = responseHeaders.get("date");
            if (responseDate) {
                // Estimate server time, with 500ms rounding and 100 ms latency
                const estimatedServerTime = new StemDate(responseDate).add(600);
                ServerTime.set(estimatedServerTime, true);
            }
        });

        // Raise any error, to be handled by the error processor
        ajaxHandler.addPostprocessor(payload => {
            if (payload.error) {
                throw payload.error;
            }
        });

        // Prettify any error, so it's in a standardized format
        ajaxHandler.addErrorPostprocessor(error => {
            let formattedError = {};
            if (typeof error === "string" || error instanceof String) {
                formattedError = {message: error};
            } else if (error instanceof Error) {
                formattedError = {
                    name: error.name,
                    message: error.message,
                };
            }
            return formattedError;
        });
    }
}
