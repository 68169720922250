import {authService} from "../client/connection/Services";

const options = {replaceHistory: true};

export class AppGuards {
    static checkNotAuthenticated() {
        if (!authService.isAuthenticated()) {
            return;
        }
        return ["/", options];
    }

    static checkAuthenticated() {
        if (authService.isAuthenticated()) {
            return;
        }
        return ["login", options];
    }
}
