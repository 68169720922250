import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {BlinkSDKInputField} from "../../components/Primitives.jsx";
import {PanelEditorTabStyle} from "./PanelEditorTabStyle.js";
import {HTMLEditor} from "../../../../common/HTMLEditor.jsx";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme.js";


@registerStyle(PanelEditorTabStyle)
export class PanelContentEditor extends UI.Element {
    render() {
        return <BlinkSDKInputField label="HTML Content"
                                   stateKey={["panelOptions", "content"]}
                                   state={this.options.state}
                                   InputClass={HTMLEditor}
                                   style={{width: "100%", maxWidth: "100%"}}
                                   inputOptions={{
                                        className: this.styleSheet.textAreaInput + this.styleSheet.codeInput,
                                        aceMode: "html",
                                        resize: false,
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        style: {maxHeight: 600, minHeight: 600, maxWidth: "100%"},
                                   }}/>;
    }
}
