import {registerStyle, styleRule, UI} from "stem-core/src/ui/UI";
import {DonationFormComponent, DonationFormComponentStyle} from "./DonationFormComponent";
import {Messages} from "../../../blinkpay/Messages";
import {PredefinedAmounts} from "./PredefinedAmounts";


class AmountOptionsStyle extends DonationFormComponentStyle {
    @styleRule
    inputOptionsToggle = {
        maxWidth: 261,
        marginBottom: 12,
    };

    @styleRule
    hide = {
        display: "none",
    }

}

@registerStyle(AmountOptionsStyle)
export class AmountOptions extends DonationFormComponent {
    getDefaultState() {
        const donationOffer = this.options.donationOffer;
        return {
            ...super.getDefaultState(),
            amountInputOption: donationOffer.amountInputOption,
        }
    }

    getData() {
        const childState = this.predefinedAmounts && this.predefinedAmounts.getData() || {};
        return {
            amountInputOption: this.state.amountInputOption,
            ...childState,
        }
    }

    isChanged() {
        const selfIsChanged = this.state.amountInputOption !== this.options.donationOffer.amountInputOption;
        const childHasChanged = this.predefinedAmounts && this.predefinedAmounts.isChanged() || false;
        return selfIsChanged || childHasChanged;
    }

    hasInputError() {
        return this.predefinedAmounts && this.predefinedAmounts.hasInputError() || false;
    }

    resetState() {
        super.resetState();
        this.predefinedAmounts.resetState();
    }

    render() {
        const {styleSheet} = this;

        return [
            <div className={styleSheet.headerText}>{Messages.donationAmountOptionsTitle}</div>,
            <div className={styleSheet.descriptionText}>{Messages.donationPredefinedAmountsDescription}</div>,
            <PredefinedAmounts
                ref="predefinedAmounts"
                onUpdate={() => this.dispatchUpdate()}
                donationOffer={this.options.donationOffer}
            />,
        ];
    }
}
