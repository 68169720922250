import {UI} from "../../stem-core/src/ui/UIBase";
import {DashboardTablePage, MakeEmailEntry} from "../common/DashboardTablePage";
import {
    apiMerchantDeleteLogMessage,
    MerchantLogMessageStore
} from "../../client/state/merchant/MerchantLogMessageStore";
import {LoggingLevel} from "../../client/state/misc/BaseLogMessage.js";
import {LEFT_COLUMN} from "../common/theme/TableStyle";
import {DashboardStaticText} from "../common/DashboardStaticText";
import {CollapsibleTable} from "../../stem-core/src/ui/table/CollapsibleTable";
import {MerchantUserStore} from "../../client/state/merchant/MerchantUserStore";
import {DashboardLabel} from "../common/DashboardLabel";
import {BlinkInputField} from "../common/Input";
import {Select, TextInput} from "../../stem-core/src/ui/input/Input";
import {TrashCanIcon} from "../NavSidePanelIcons";
import {ConfirmationModal} from "../../blinkpay/ui/ConfirmationModal";
import {SearchInput} from "../ui/input/SearchInput.jsx";
import {UserSearchInput} from "../ui/input/UserSearchInput.jsx";


class LogMessageExtra extends UI.Element {
    render() {
        const {message} = this.options;
        const {extra} = message;
        if (!extra) {
            return "No extra information to show";
        }
        return <DashboardStaticText type="json" value={extra} />
    }
}


export class MerchantLoggingPage extends DashboardTablePage {
    getDefaultOptions() {
        return {
            title: "Logging messages",
            store: MerchantLogMessageStore,
            endpoint: "/merchant/get_log_messages/",
        }
    }

    async deleteMerchantMessage(logMessage) {
        const {message} = logMessage;
        const confirm = await ConfirmationModal.prompt({
            title: "Delete error message?",
            description: [<div>Are your sure you want to delete this error message?
                <p><i>{message}</i></p>
            </div>],
            confirmLabel: "Delete log message"
        });
        if (!confirm) {
            return;
        }
        await apiMerchantDeleteLogMessage({messageId: logMessage.id});
        await this.paginator.fetchPage();
    }

    getFilterSection() {
        const applyFilters = () => this.applyFilters();

        return <div>
            <BlinkInputField label="Min Level">
                <Select
                    options={[LoggingLevel.ERROR, LoggingLevel.WARNING, "Any"]}
                    initialValue={LoggingLevel.WARNING}
                    onChange={applyFilters}
                    ref="minLevelInput"
                />
            </BlinkInputField>

            <BlinkInputField label={"Code"}>
                {/*// TODO @Mihai make this multi input*/}
                <TextInput ref="codeInput" onChange={applyFilters} />
            </BlinkInputField>

            <UserSearchInput onChange={applyFilters} ref="userSearchInput"/>

            <BlinkInputField label={"Message text"}>
                <SearchInput inputAttributes={{placeholder:"Case insensitive search"}} ref="messageInput" onChange={applyFilters} />
            </BlinkInputField>
        </div>
    }

    getFilters() {
        let filters = {
            // messageText: this.messageInput.getValue(),
            minLevel: this.minLevelInput.getValue().value,
            userFilter: this.userSearchInput.getValue(),
            messageText: this.messageInput.getValue().trim() || null,
        };

        const code = this.codeInput.getValue();
        if (code && parseInt(code)) {
            filters.code = [parseInt(code)];
        } else {
            filters.code = null; // Clear it
        }

        return filters;
    }

    entriesTable() {
        // TODO @Mihai only show the collapsible icon when there is extra info
        const table = super.entriesTable();
        Object.assign(table.options, {
            tableClass: CollapsibleTable,
            tableOptions: {
                renderCollapsible: (message) => <LogMessageExtra message={message} />,
            }
        });
        return table;
    }

    getColumns() {
        const {merchant} = this.options;
        return [
            ["Date", message => message.createdAt, LEFT_COLUMN],
            ["Level", message => {
                const {level} = message;
                return <DashboardLabel level={level.getLabelLevel()}>{level}</DashboardLabel>
            }, LEFT_COLUMN],
            ["User", message => {
                const {userId} = message;
                if (!userId) {
                    return;
                }
                const merchantUser = MerchantUserStore.getByUserAndMerchant(userId, merchant.id);
                if (!merchantUser) {
                    return `UserId ${userId}`;
                }
                return MakeEmailEntry(merchantUser);
            }, LEFT_COLUMN],
            ["Code", message => message.code, LEFT_COLUMN],
            ["Message", message => message.message, LEFT_COLUMN],
            ["", message => <TrashCanIcon style={{cursor: "pointer", verticalAlign: "middle"}} size={24}  onClick={() => this.deleteMerchantMessage(message)} />]
        ]
    }
}
