import {UI} from "../../../stem-core/src/ui/UIBase";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {MerchantDropdown} from "../../../blinkpay/widget/ui/MerchantDropdown";
import {SubscriptionOfferStore} from "../../../blinkpay/State";
import {BlinkNumberInput} from "../Base";
import {StemDate} from "../../../stem-core/src/time/Date";

export class MerchantGrantSubscriptionModal extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Grant a subscription",
            description: "Manually grant the user a subscription." +
                " No payment will be charged, and the user will have to add one to continue having the subscription" +
                " (unless you manually extend it of course).",
            confirmLabel: "Create subscription",
            confirmAction: () => this.grantSubscription(),
            merchantUser: null,
        }
    }

    getSubscriptionPaidUntil() {
        const currentPaidUntil = StemDate.now();
        const addedHours = this.hoursInput?.getValue() || 0;
        const addedDays = this.daysInput?.getValue() || 0;
        const addedMonths = this.monthsInput?.getValue() || 0;

        return currentPaidUntil.add({hours: addedHours, days: addedDays, months: addedMonths});
    }

    getSelectedSubscriptionOffer() {
        return SubscriptionOfferStore
                .find(offer => this.getFormattedOfferName(offer) === this.subscriptionOfferSelector?.getValue());
    }

    getFormattedOfferName(offer) {
        return `${offer.title} (${offer.getBasePrice()}/${offer.getCycleDuration()})`
    }

    render() {
        const {merchantUser} = this.options;
        const subscriptionOffers = SubscriptionOfferStore.all();
        const inputOptions = {
            onChange: () => this.redraw(),
            style: {
                width: 120,
                display: "inline-block",
            }
        };

        return [
            <div>User: {merchantUser.getName()} ({merchantUser.getEmail()})</div>,
            <MerchantDropdown ref={"subscriptionOfferSelector"}
                              label={"Subscription Plan"}
                              onChange={() => this.redraw()}
                              initialValue={subscriptionOffers && this.getFormattedOfferName(subscriptionOffers[0])}
                              options={SubscriptionOfferStore.all().map(offer => this.getFormattedOfferName(offer))}
            />,
            <div>
                <BlinkNumberInput ref="hoursInput" {...inputOptions} /> hours to add.
            </div>,
            <div>
                <BlinkNumberInput ref="daysInput" {...inputOptions} /> days to add.
            </div>,
            <div>
                <BlinkNumberInput ref="monthsInput" {...inputOptions} /> months to add
            </div>,
            <div>
                Subscription marked as paid until {this.getSubscriptionPaidUntil()}.
            </div>,
        ]
    }

    async grantSubscription() {
        const request = {
            userId: this.options.merchantUser.userId,
            subscriptionOfferId: this.getSelectedSubscriptionOffer().id,
            paidUntil: this.getSubscriptionPaidUntil(),
        }

        const responseOk = await this.makeRequest("/merchant/grant_subscription", request);
        if (responseOk) {
            this.hide();
        }
    }
}
