import {UI} from "../../stem-core/src/ui/UIBase";
import {DashboardTablePage} from "../common/DashboardTablePage";
import {apiMerchantDeleteReport, MerchantReportStore} from "../../client/state/merchant/MerchantReportStore";
import {Messages} from "../../blinkpay/Messages";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw";
import {formatDate, formatDateRange, formatPercent} from "../common/Utils";
import {TrashCanIcon} from "../NavSidePanelIcons";
import {CENTER_COLUMN, LEFT_COLUMN} from "../common/theme/TableStyle";
import {bytesToSize} from "../../blinkpay/Utils";
import {ReportDownloadButton} from "../common/modals/MerchantReportProgressModal";

function getTimePeriod(report) {
    const filters = report.filters;

    if (filters.activeAt) {
        return formatDate(filters.activeAt);
    }

    if (filters.startDate && filters.endDate) {
        return formatDateRange(filters.startDate, filters.endDate);
    }

    return null;
}


class ReportActionOptions extends UI.Element {
    async deleteReport() {
        const {report, paginator} = this.options;
        await apiMerchantDeleteReport(report.id);
        paginator.fetchPage()
    }

    render() {
        const {report} = this.options;

        return [
            <ReportDownloadButton report={report} />,
            // TODO: we probably want an IconButton class, with some consistent style
            <TrashCanIcon style={{cursor: "pointer", verticalAlign: "middle"}} size={24} onClick={() => this.deleteReport()} />
        ];
    }
}

@autoredraw(MerchantReportStore)
export class MerchantReportsPage extends DashboardTablePage {
    getDefaultOptions() {
        return {
            title: Messages.reports,
            description: Messages.reportsPageDescription,
            store: MerchantReportStore,
            endpoint: "/merchant/get_reports/",
        }
    }

    getFilters() {
        return null;
    }

    getColumns() {
        return [
            ["Id", report => report.id, LEFT_COLUMN],
            ["Type", report => report.type, LEFT_COLUMN],
            ["Created At", report => report.createdAt, CENTER_COLUMN],

            ["File Size", report => bytesToSize(report.fileSize), CENTER_COLUMN],
            ["", report => <ReportActionOptions report={report} paginator={this.getPaginator()}/>, CENTER_COLUMN],
        ]
    }

    onMount() {
        super.onMount();
        this.attachListener(MerchantReportStore, "newOwnObject", () => this.paginator.fetchFirstPage());
    }
}
