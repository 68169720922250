// TODO @Mihai rename to ShippingPriceInput
import {UI} from "../../../stem-core/src/ui/UIBase";
import {InputableElement} from "../../../stem-core/src/ui/input/Input";
import {ShippingPriceEntry, ShippingPriceType} from "../../../client/state/misc/ShippingPrice";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {SimpleTable} from "../../ui/SimpleTable";
import {BlinkInputField} from "../Input";
import {RadioButtons} from "../../../blinkpay/ui/Button";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {DashboardMoneyInput} from "../../ui/input/DashboardMoneyInput";
import {Switcher} from "../../../stem-core/src/ui/Switcher";
import {TrashCanIcon} from "../../NavSidePanelIcons";
import {Level} from "../../../stem-core/src/ui/Constants";


export class CommaSeparatedInput extends TextInput {
    setValue(value) {
        if (Array.isArray(value)) {
            value = value.join(",");
        }
        super.setValue(value);
    }

    // Return an array of ISO codes
    getValue({emptyAsNull = true} = {}) {
        const value = super.getValue();
        // TODO @branch validate here to only use valid codes
        const arrayValues = value.split(",").map(value => value.trim()).filter(countryCode => countryCode.length > 0);
        if (emptyAsNull && arrayValues.length === 0) {
            return null;
        }
        return arrayValues;
    }
}

class MultiCountryInput extends CommaSeparatedInput {}


// TODO @Mihai make this a dropdown
class SingleCountryInput extends TextInput {
}

class ShippingPriceEntryInput extends UI.Element {
    value = null;
    type = ShippingPriceType.WORLDWIDE;

    getDefaultOptions(options) {
        return {
            initialValue: new ShippingPriceEntry(),
        }
    }

    getCurrency() {
        return this.value.currency || this.options.currency;
    }

    getValue() {
        const {type, value} = this;
        if (type === ShippingPriceType.WORLDWIDE) {
            value.countryCode = "*";
        }
        if (type === ShippingPriceType.MULTI_COUNTRY) {
            value.countryCode = this.multiCountryInput.getValue();
        }
        if (type === ShippingPriceType.SINGLE_COUNTRY) {
            value.countryCode = this.singleCountryInput.getValue();
            value.region = this.regionInput.getValue();
            value.postalCode = this.postalCodeInput.getValue();
        }
        value.amount = this.amountInput.getValue(false);
        return value;
    }

    // TODO @branch here and in the MerchantAudience editor we've got a pattern of input based on selector.
    getTypeSpecificFilter() {
        const {value, type} = this;

        if (!this.singleCountryCachedInput) {
            let defaultMultiCountry = value.countryCode;
            if (defaultMultiCountry === "*") {
                defaultMultiCountry = ["US"];
            }
            if (!Array.isArray(defaultMultiCountry)) {
                defaultMultiCountry = [defaultMultiCountry];
            }
            const defaultSingleCountry = defaultMultiCountry[0] || "US";

            this.singleCountryCachedInput = <div>
                <BlinkInputField label="Country (ISO code)">
                    <SingleCountryInput initialValue={defaultSingleCountry} ref="singleCountryInput"/>
                </BlinkInputField>
                <BlinkInputField label="States/Regions (optional, comma separated)">
                    <CommaSeparatedInput initialValue={value.region} ref="regionInput" />
                </BlinkInputField>
                <BlinkInputField label="Postal codes (optional, comma separated)">
                    <CommaSeparatedInput initialValue={value.postalCode} ref="postalCodeInput"/>
                </BlinkInputField>
            </div>;

            this.multiCountryCachedInput = <div>
                <BlinkInputField label="Countries (comma separated ISO codes)">
                    <MultiCountryInput initialValue={defaultMultiCountry} ref="multiCountryInput" />
                </BlinkInputField>
            </div>;
        }

        if (type === ShippingPriceType.SINGLE_COUNTRY) {
            return this.singleCountryCachedInput;
        }

        if (type === ShippingPriceType.MULTI_COUNTRY) {
            return this.multiCountryCachedInput;
        }

        return null;
    }

    setType(type) {
        this.type = type;
        this.switcher.setActive(this.getTypeSpecificFilter());
    }

    render() {
        if (this.value == null) {
            this.value = ShippingPriceEntry.clone(this.options.initialValue || new ShippingPriceEntry());
            this.type = this.value.getType();
        }

        const {value, type} = this;
        return [
            <div>
                <BlinkInputField label="Shipping price">
                    <DashboardMoneyInput currency={this.getCurrency()} initialValue={value.amount} ref="amountInput"/>
                </BlinkInputField>
            </div>,
            <div>
                <BlinkInputField label="Address filter type">
                    <RadioButtons
                        activeValue={type}
                        values={ShippingPriceType.all()}
                        onSelect={(type) => this.setType(type)}
                        ref="requiresDeliveryFilter"/>
                </BlinkInputField>
            </div>,
            <Switcher ref="switcher">
                {this.getTypeSpecificFilter()}
            </Switcher>
        ];
    }
}


class ShippingPriceEntryDialog extends ConfirmationModal {
    getDefaultOptions(options) {
        const {entry} = options;
        return {
            ...super.getDefaultOptions(),
            title: entry ? "Editing shipping price rule" : "New shipping price rule",
            confirmLabel: entry ? "Save changes" : "Create",
            confirmAction: () => this.resolve(this.input.getValue()),
        }
    }

    render() {
        const {entry, currency} = this.options;
        return <ShippingPriceEntryInput initialValue={entry} currency={currency} ref="input" />
    }
}

export class ShippingPricesInput extends InputableElement {
    entries = [];

    getValue() {
        return this.entries;
    }

    setValue(value) {
        this.entries = ShippingPriceEntry.loadArray(value, this.options.currency.id);
        this.redraw();
    }

    moveUpEntry(index) {
        if (index <= 0) {
            return;
        }
        const temp = this.entries[index];
        this.entries[index] = this.entries[index - 1];
        this.entries[index - 1] = temp;
        this.redraw();
    }

    moveDownEntry(index) {
        if (index >= this.entries.length - 1) {
            return;
        }
        const temp = this.entries[index];
        this.entries[index] = this.entries[index + 1];
        this.entries[index + 1] = temp;
        this.redraw();
    }

    async editEntry(index) {
        const entry = this.entries[index];
        const newEntry = await ShippingPriceEntryDialog.prompt({entry, currency: this.options.currency});
        if (newEntry) {
            this.entries[index] = newEntry;
            this.redraw();
        }
    }

    deleteEntry(index) {
        this.entries.splice(index, 1);
        this.redraw();
    }

    async createEntry() {
        const entry = await ShippingPriceEntryDialog.prompt({currency: this.options.currency});
        if (entry) {
            this.entries.push(entry);
            this.redraw();
        }
    }

    saveChanges() {
        this.dispatch("save", this.getValue());
    }

    addSaveListener(callback) {
        return this.addListener("save", callback);
    }

    render() {
        // TODO @Mihai it's a bit inconsistent how this works
        const {currency, onSave} = this.options;
        const arrowButtonStyle = {
            borderRadius: "100%",
            margin: 2,
            padding: 4
        };

        const columns = [
            // TODO these two should be icons, faking them for now through buttons
            ["", (entry, index) => <div>
                <Button onClick={() => this.moveUpEntry(index)} style={arrowButtonStyle} level={Level.SECONDARY}>▲</Button>
                <Button onClick={() => this.moveDownEntry(index)} style={arrowButtonStyle} level={Level.SECONDARY}>▼</Button>
            </div>, {headerStyle: {width: 90}}],
            // TODO: reenable name when we'll want to support multiple shipping prices for the same location (ex. Fedex vs USPS)
            // ["Name", entry => entry.name || "-"],
            ["Locations", entry => entry.formatLocation()],
            ["Price", entry => entry.getPrice(currency)],
            ["", (entry, index) => <div>
                <Button onClick={() => this.editEntry(index)}>Edit</Button>
                <TrashCanIcon style={{cursor: "pointer", verticalAlign: "middle"}} size={24} onClick={() => this.deleteEntry(index)} />
            </div>]
        ];

        return [
            <div>
                <Button onClick={() => this.createEntry()}>New Rule</Button>
                {onSave && <Button onClick={() => this.saveChanges()}>Save Changes</Button>}
            </div>,
            (this.entries.length === 0) ? <div>No shipping prices configured.</div> :
                <SimpleTable columns={columns} entries={this.entries}/>
        ]
    }
}
