import {isString} from "../../../stem-core/src/base/Utils";
import {merchantBuiltinFunctions} from "./Builtins";
import {generateUniqueId} from "../../../blinkpay/UtilsLib";
import {delayDebounced} from "../../utils/Utils";


// When encountering exceptions while running merchant code ("expr: ..." evaluations
// or functions registered in this module), the exception is caught, to not interrupt
// the Blink SDK code. The exception handler is called asynchronously to inform the
// merchant of the exception. By default, we batch exceptions together and print them
// to the console as a collapsed group.

const exceptionsBatch = [];
function printExceptionsBatch() {
    console.groupCollapsed("[BlinkSDK] Errors while running journeys");
    for (const {exception, key, code} of exceptionsBatch) {
        console.group(key ? "in function " + key : "in anonymous expression:");
        if (code) {
            console.log(code);
        }
        console.error(exception);
        console.groupEnd();
    }
    console.groupEnd();
    exceptionsBatch.splice(0, exceptionsBatch.length);
}
const defaultExceptionBatchHandler = delayDebounced(printExceptionsBatch, 10);

function defaultExceptionHandler(exception, key, code) {
    exceptionsBatch.push({exception, key, code});
    defaultExceptionBatchHandler();
}


class MerchantFunctionModule {
    functions = {};
    // If a merchant function throws an exception (or if a function
    // call is requested for a non-existent ID), this handler is
    // called (asynchronously) with the exception, so the Blink SDK's
    // current synchronous flow isn't broken.
    exceptionHandler = defaultExceptionHandler;

    get(key) {
        return this.all()[key];
    }

    add(functions) {
        Object.assign(this.functions, functions);
    }

    delete(key) {
        delete this.functions[key];
    }

    all() {
        return {
            ...merchantBuiltinFunctions,
            ...this.functions,
        };
    }

    // Returns an array of 2 elements: first one is a boolean, true if a function
    // was called and successfully returned, false if an exception occurred. Second
    // is null if the first is false, or the result of the function call otherwise.
    call(key, ...args) {
        if (Array.isArray(key)) {
            args = [...key, ...args];
            [key, ...args] = args;
        }
        let f = this.get(key);
        if (f == null) {
            const keychain = isString(key) ? key.split(".") : [key];
            let object = window;
            for (const key of keychain) {
                if (object && object[key]) {
                    object = object[key];
                } else {
                    object = null;
                    break;
                }
            }
            f = object;
        }
        let result = null;
        if (f == null) {
            this.handleException(new ReferenceError(`Function not found for key '${key}'.`), key);
            return [false, null];
        }
        try {
            result = f(...args);
        } catch (e) {
            this.handleException(e, key);
            return [false, null];
        }
        return [true, result];
    }

    handleException(e, key, code) {
        setTimeout(() => this.exceptionHandler(e, key, code), 0);
    }

    addAnonymous(func) {
        const key = "f_" + generateUniqueId().replace(/-/g, "_");
        this.add({[key]: func});
        return key;
    }
}

export const merchantFunctionModule = new MerchantFunctionModule();

// Useful for tests
export function clearMerchantFunctionModule() {
    merchantFunctionModule.exceptionHandler = defaultExceptionHandler;
    merchantFunctionModule.functions = {};
}
