// TODO @branch need to rewrite this whole file
import {registerStyle, styleRule, UI} from "stem-core/src/ui/UI";
import {Messages} from "../../../blinkpay/Messages";
import {DonationFormComponent, DonationFormComponentStyle} from "./DonationFormComponent";
import {DashboardRadio} from "../../ui/DashboardRadio";
import {CycleUnit} from "../../../client/state/RecurringPaymentStoreObject";
import {LabeledCheckbox} from "../../../blinkpay/ui/Checkbox";


class FrequenciesStyle extends DonationFormComponentStyle {
    @styleRule
    card = {
        borderLeft: "1px solid",
        borderColor: this.themeProps.FLAT_TAB_AREA_COLOR_BACKGROUND,
    };

    @styleRule
    table = {
        padding: 0,
        textAlign: "left",
        width: "100%",
    };

    @styleRule
    spacer = {
        display: "block",
        height: 18,
    };

    @styleRule
    tdCheckbox = {
        display: "flex",
    };
}


@registerStyle(FrequenciesStyle)
export class Frequencies extends DonationFormComponent {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.card);
    }

    getDefaultState() {
        const donationOffer = this.options.donationOffer;
        return {
            ...super.getDefaultState(),
            defaultFrequencyOption: donationOffer.defaultFrequency,
            frequencyOptions: donationOffer.getFrequencyOptions(),
        }
    }

    exportState(state) {
        return {
            defaultFrequency: state.defaultFrequencyOption,
            frequencyOptions: state.frequencyOptions.map(frequency => frequency.getValue()),
        }
    }

    frequencyOptionSelected(frequency) {
        return this.state.frequencyOptions.indexOf(frequency) !== -1;
    }

    isDefaultFrequencyOption(key) {
        return this.state.defaultFrequencyOption === key;
    }

    toggleFrequencyOption(frequency) {
        let frequencyOptions = [...this.state.frequencyOptions];

        if (this.frequencyOptionSelected(frequency)) {
            if (!this.isDefaultFrequencyOption(frequency)) {
                frequencyOptions.splice(frequencyOptions.indexOf(frequency), 1);
            }
        } else {
            frequencyOptions.push(frequency);
        }

        this.updateState({frequencyOptions});
    }

    setDefaultFrequencyOption(frequency) {
        this.updateState({defaultFrequencyOption: frequency});
    }

    renderRows() {
        const {styleSheet} = this;

        return CycleUnit.all().filter(cu => cu != CycleUnit.DAY).map((frequency) => {
            const isSelected = this.frequencyOptionSelected(frequency);
            const isDefault = this.isDefaultFrequencyOption(frequency);
            return [
                <tr>
                    <td className={styleSheet.tdCheckbox}>
                        <LabeledCheckbox initialValue={isSelected} label={frequency.toString()}
                                         onChange={() => this.toggleFrequencyOption(frequency)}/>
                    </td>
                    <td>
                        <DashboardRadio
                            className={isSelected ? "" : styleSheet.disabledSection}
                            selected={isDefault}
                            onClick={() => this.setDefaultFrequencyOption(frequency)}
                        />
                    </td>
                </tr>,
                <tr className={styleSheet.spacer} />
            ];
        });
    }

    render() {
        const {styleSheet} = this;
        return [
            <div className={styleSheet.headerText}>{Messages.donationFrequenciesTitle}</div>,
            <div className={styleSheet.descriptionText}>{Messages.donationFrequenciesDescription}</div>,
            <table className={styleSheet.table}>
                <thead className={styleSheet.headerText}>
                    <tr>
                        <th>{Messages.frequency}</th>
                        <th>{Messages.default}</th>
                    </tr>
                    <tr className={styleSheet.spacer} />
                </thead>
                <tbody>
                    {this.renderRows()}
                </tbody>
            </table>
        ]
    }
}
