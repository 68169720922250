import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme.js";
import {Link} from "../../../../stem-core/src/ui/UIPrimitives.jsx";
import {ArrowDownIcon, ArrowUpIcon, EditIcon} from "../../../../blinkpay/SVGElements.jsx";
import {formatPercent} from "../../../common/Utils.js";
import {TrashCanIcon} from "../../../NavSidePanelIcons.js";
import {DashboardLabel} from "../../../common/DashboardLabel.jsx";
import {Level} from "../../../../stem-core/src/ui/Constants.js";
import {Button} from "../../../../stem-core/src/ui/button/Button.jsx";
import {styleRule, StyleSheet} from "../../../../stem-core/src/ui/Style.js";

class JourneyEntrypointStyle extends StyleSheet {
    BORDER_STYLE = "1px solid " + this.themeProps.INPUT_BORDER_COLOR;

    @styleRule
    container = {
        display: "inline-flex",
        width: "100%",
        border: this.BORDER_STYLE,
        marginTop: -1,
        transition: ".2s ease",
        ":hover": {
            boxShadow: `1px 1px 3px ${this.themeProps.BUTTON_PRIMARY_BACKGROUND_COLOR}`,  // I like it, shows the entry you're hovering over
        }
    };

    @styleRule
    arrowButton = {
        cursor: "pointer",
        display: "inline-block",
    };

    @styleRule
    gutterSection = {
        width: 98,
    }

    @styleRule
    gutterTop = {
        display: "inline-flex"
    }

    @styleRule
    gutterBottom = {
        display: "flex",
        justifyContent: "space-around",
        padding: 4,
        marginTop: -12, // TODO @cleanup not so nice
    }

    @styleRule
    index = {
        fontFamily: "monospace",
        minWidth: 32,
        padding: 4,
        textAlign: "right",
    }

    @styleRule
    journeysContainer = {
        padding: 4,
        flex: "1 1 0%",
        borderRight: this.BORDER_STYLE,
        borderLeft: this.BORDER_STYLE,
    }

    @styleRule
    multipleJourneyContainer = {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)", // Two columns, same size
        ">:nth-child(odd)": {
            borderRight: this.BORDER_STYLE,
        }
    }

    @styleRule
    journeyBranchEntry = {
        textAlign: "center",
        paddingLeft: 4,
        paddingRight: 4,
        display: "flex",
    }

    @styleRule
    buttonSection = {
        width: 244,
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
    }
}

@registerStyle(JourneyEntrypointStyle)
export class JourneyEntrypointElement extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.container);
    }

    // TODO @cleanup move automation for this inside UIBase in Stem
    addMoveUpListener(callback) {
        return this.addListener("moveUp", callback);
    }

    addMoveDownListener(callback) {
        return this.addListener("moveDown", callback);
    }

    addAddJourneyListener(callback) {
        return this.addListener("addJourney", callback);
    }

    addRemoveEntrypointListener(callback) {
        return this.addListener("removeEntrypoint", callback);
    }

    renderJourneyLink(journey) {
        return <Link href={`/journeys/${journey.alias}`}>
            {journey.name} <EditIcon style={{paddingLeft: 12}} size={18}/>
        </Link>
    }

    renderSingleJourney(journey) {
        const {styleSheet} = this;

        return <div className={styleSheet.journeysContainer}>
            {this.renderJourneyLink(journey)}
        </div>
    }

    renderMultipleJourneys() {
        const {styleSheet} = this;
        const {state, journeyEntrypoint} = this.options;

        const journeys = journeyEntrypoint.getJourneys();
        const weights = journeyEntrypoint.getNormalizedWeights();

        const journeyEntries = journeys.map((journey, index) => <div className={styleSheet.journeyBranchEntry}>
            <div style={{flex: "1"}}>
                {this.renderJourneyLink(journey)}
                <div>
                    {formatPercent(100.0 * weights[index])}
                </div>
            </div>
            <div style={{margin: "auto"}}>
                <TrashCanIcon size={24} style={{cursor: "pointer"}}
                              onClick={() => state.removeJourneyFromEntrypoint(journeyEntrypoint, index)}/>
            </div>
        </div>)

        return <div className={styleSheet.journeysContainer + styleSheet.multipleJourneyContainer}>
            {journeyEntries}
        </div>
    }

    render() {
        const {styleSheet} = this;
        const {journeyEntrypoint, index} = this.options;

        const journeys = journeyEntrypoint.getJourneys();
        const haveMultipleJourneys = journeys.length > 1;

        return [
            <div className={styleSheet.gutterSection}>
                <div className={styleSheet.gutterTop}>
                    <ArrowUpIcon size={30} className={styleSheet.arrowButton}
                                 onClick={() => this.dispatch("moveUp", index)}/>
                    <ArrowDownIcon size={30} className={styleSheet.arrowButton}
                                   onClick={() => this.dispatch("moveDown", index)}/>
                    <span className={styleSheet.index}>
                    {index + 1}
                </span>
                </div>
                <div className={styleSheet.gutterBottom}>
                    {
                        haveMultipleJourneys ? <DashboardLabel level={Level.PRIMARY}>A/B Test</DashboardLabel> :
                            <DashboardLabel level={Level.INFO}>Journey</DashboardLabel>
                    }
                </div>
            </div>,

            haveMultipleJourneys ? this.renderMultipleJourneys() : this.renderSingleJourney(journeys[0]),

            <div className={styleSheet.buttonSection}>
                <Button disabled={journeys.length >= 4 || journeyEntrypoint.getAvailableJourneys().length === 0}
                        onClick={() => this.dispatch("addJourney", index)}>
                    {haveMultipleJourneys ? "Add Journey" : "Turn into A/B test"}
                </Button>
                <Button level={Level.SECONDARY} onClick={() => this.dispatch("removeEntrypoint", index)}>
                    Remove
                </Button>
            </div>
        ]
    }
}