import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../misc/StoreField";
import {EmailSendingStoreObject, MerchantEmailCampaignStatus} from "./MerchantEmailCampaignStore";
import {apiClient} from "../../connection/BlinkApiConnection";

class MerchantEmailCampaignSegment extends EmailSendingStoreObject {
    @field("Merchant") merchant;
    @field("MerchantEmailCampaign") campaign;
    @field(String) name;
    @field("EmailTemplate") template;
    @field(Date) createdAt;
    @field(MerchantEmailCampaignStatus) status;
    @field(Date) scheduledSendDate;
    @field(Object) extraFilters; // MerchantAudienceFilters
    @field(Number) maxEmailsToSend;

    canBeEdited() {
        return this.status === MerchantEmailCampaignStatus.UNSCHEDULED ||
               this.status === MerchantEmailCampaignStatus.SCHEDULED ||
               this.status === MerchantEmailCampaignStatus.STOPPED;
    }
}

export const MerchantEmailCampaignSegmentStore = MakeStore("MerchantEmailCampaignSegment", MerchantEmailCampaignSegment);

export async function apiMerchantCreateEmailCampaignSegment(request) {
    const response = await apiClient.post("/merchant/create_email_campaign_segment/", request);
    return MerchantEmailCampaignSegmentStore.loadObjectFromResponse(response);
}

export async function apiMerchantEditEmailCampaignSegment(request) {
    return await apiClient.post("/merchant/edit_email_campaign_segment", request, {allowNulls: true});
}

export async function apiMerchantStopEmailCampaignSegment(request) {
    return await apiClient.post("/merchant/stop_email_campaign_segment", request);
}