import {
    MerchantMailChimpInterestStore,
    MerchantMailChimpUserListStore,
    apiSyncMailChimpUserList
} from "../../client/state/merchant/external-providers/MerchantMailChimpUserListStore";
import {UI} from "../../stem-core/src/ui/UIBase";
import {Link} from "../../stem-core/src/ui/UIPrimitives";
import {DashboardTitle} from "../common/DashboardTitle";
import {SimpleTable} from "../ui/SimpleTable";
import {MerchantAudienceStore} from "../../client/state/merchant/MerchantAudienceStore";
import {Button} from "../../stem-core/src/ui/button/Button";
import {Duration, TimeUnit} from "../../stem-core/src/time/Duration";
import {StemDate} from "../../stem-core/src/time/Date";
import {autoredraw} from "../../stem-core/src/decorators/AutoRedraw";
import {TaskStatus} from "../../client/state/misc/GenericEnums";
import {MerchantFacebookPixelStore} from "../../client/state/merchant/external-providers/MerchantFacebookPixelStore";
import {MakeSimpleSummaryTable} from "../common/SimpleSummaryTable";
import {DashboardProgressBar} from "../common/theme/DashboardProgressBar";
import {Level} from "../../stem-core/src/ui/Constants";


@autoredraw(MerchantMailChimpUserListStore)
class MailChimpUserListElement extends UI.Element {
    renderMergeTagBlinkValue(mergeTag) {
        if (mergeTag.blinkField === "email_preferences_url") {
            return "Email preferences link";
        }
        if (mergeTag.blinkField === "is_audience_member") {
            return [
                "Is member of the audience: ",
                <Link href={`/email-newsletters/${mergeTag.blinkFieldParam}`}>{MerchantAudienceStore.get(mergeTag.blinkFieldParam).name}</Link>
            ]
        }
    }

    getDurationSinceLastUpdate() {
        return new Duration(StemDate.now() - this.options.mcUserList.lastSyncUpdatedAt);
    }

    shouldDisplaySubmitButton() {
        const {mcUserList} = this.options;
        if (mcUserList.lastSyncStatus !== TaskStatus.IN_PROGRESS) {
            return true;
        }
        return this.getDurationSinceLastUpdate() >= 5 * TimeUnit.MINUTE;
    }

    renderLastSyncStatus(mcUserList) {
        if (mcUserList.lastSyncStatus === TaskStatus.IN_PROGRESS) {
            const currentSyncDuration = new Duration(StemDate.now() - mcUserList.lastSyncStartedAt);
            return [
                <p>Synchronization {(mcUserList.lastSyncProgress * 100).toFixed(1)}% done in {currentSyncDuration} (started {mcUserList.lastSyncStartedAt})</p>,
                <DashboardProgressBar level={Level.PRIMARY} value={mcUserList.lastSyncProgress} />,
                <p>Last updated {this.getDurationSinceLastUpdate()} ago</p>,
            ];
        }
        const lastSyncDuration = new Duration(mcUserList.lastSyncFinishedAt - mcUserList.lastSyncStartedAt);
        return <p>Last synchronization on {mcUserList.lastSyncStartedAt} {mcUserList.lastSyncStatus.value} in {lastSyncDuration.format({maxEntries: 3})}</p>;
    }

    render() {
        const {mcUserList} = this.options;

        const interestsColumns = [
            ["Interest name", interest => interest.interestName ?? interest.audience?.name],
            ["Interest category", () => mcUserList.interestCategoryTitle],
            ["Blink Audience", interest => <Link href={`/email-newsletters/${interest.audienceId}`}>{interest.audience.name}</Link>],
        ];

        const mergeTagColumns = [
            ["Name", mergeTag => mergeTag.name],
            ["Merge Tag", mergeTag => <span style={{fontFamily: Theme.props.FONT_FAMILY_MONOSPACE}}>{mergeTag.key}</span>],
            ["Default value", mergeTag => mergeTag.defaultValue ?? ""],
            ["Blink generated value", mergeTag => this.renderMergeTagBlinkValue(mergeTag)],
        ];

        return [
            <h2>Mail Chimp User list {mcUserList.name}</h2>,
            this.renderLastSyncStatus(mcUserList),
            this.shouldDisplaySubmitButton() && <Button onClick={() => apiSyncMailChimpUserList(mcUserList.id)} style={{marginTop: 8}}>
                Start new synchronization
            </Button>,
            <h3>Interests</h3>,
            <SimpleTable columns={interestsColumns} entries={MerchantMailChimpInterestStore.filterBy({userListId: mcUserList.id})}/>,
            <h3>Merge tags</h3>,
            <SimpleTable columns={mergeTagColumns} entries={mcUserList.mergeTags} />,
        ];
    }
}


@autoredraw(MerchantFacebookPixelStore)
class FacebookPixelElement extends UI.Element {
    renderConversionEvent(conversionEvent) {
        if (!conversionEvent) {
            return "Not enabled";
        }
        return [
            <div><code>{conversionEvent.name}</code> (event track type <code>{conversionEvent.trackType}</code>)</div>,
            <div style={{paddingTop: 4}}>
                Event properties: {Object.keys(conversionEvent.builtinCustomProperties || {}).length === 0 && Object.keys(conversionEvent.extraCustomProperties || {}).length === 0 ? "None enabled" : ""}
                {Object.entries(conversionEvent.builtinCustomProperties || {}).map(([key, value]) => <li><code>{key}={value}</code></li>)}
                {Object.entries(conversionEvent.extraCustomProperties || {}).map(([key, value]) => <li><code>{key}='{value}'</code></li>)}
            </div>,
        ];
    }

    render() {
        const {facebookPixel} = this.options;
        return [
            <h2>Facebook pixel {facebookPixel.name}</h2>,
            MakeSimpleSummaryTable([
                ["Pixel ID", <code>{facebookPixel.pixelId}</code>],
                ["Subscription conversion event", this.renderConversionEvent(facebookPixel.subscriptionConversionEvent)],
                ["Donation conversion event", this.renderConversionEvent(facebookPixel.donationConversionEvent)],
                ["Newsletter conversion event", this.renderConversionEvent(facebookPixel.newsletterConversionEvent)],
            ], {verticalAlign: "top", padding: 12}, {padding: 12}),
        ];
    }
}

export class MerchantIntegrationsPage extends UI.Element {
    render() {
        const {merchant} = this.options;
        const sections = [];
        const mailChimpUserLists = MerchantMailChimpUserListStore.filterBy({merchantId: merchant.id});
        if (mailChimpUserLists.length) {
            sections.push(mailChimpUserLists.map(mcUserList => <MailChimpUserListElement mcUserList={mcUserList} />));
        }
        const facebookPixels = MerchantFacebookPixelStore.filterBy({merchantId: merchant.id});
        if (facebookPixels.length) {
            sections.push(facebookPixels.map(facebookPixel => <FacebookPixelElement facebookPixel={facebookPixel} />));
        }
        return [
            <DashboardTitle title="Integrations"/>,
            ...sections,
        ];
    }
}
