import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style";
import {UI} from "../../../stem-core/src/ui/UIBase";
import {unwrapArray} from "../../../stem-core/src/base/Utils";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {LabeledCheckbox} from "../../../blinkpay/ui/Checkbox";


class TreeViewCheckboxStyle extends StyleSheet {
    @styleRule
    container = {
    }
}

function CalcCheckedAndIndeterminate(entries) {
    let haveChecked = false, haveUnchecked = false, indeterminate = false;
    let allChecked = true;
    for (const entry of (entries || [])) {
        if (entry.checked) {
            haveChecked = true;
        } else {
            haveUnchecked = true;
        }
        indeterminate |= entry.indeterminate;
        if (!entry.checked || entry.indeterminate) {
            allChecked = false;
        }
    }
    indeterminate |= (haveChecked && haveUnchecked);
    return {
        haveChecked,
        haveUnchecked,
        checked: !indeterminate ? allChecked : null,
        indeterminate,
    }
}

function UpdateEntryRecursively(entry, checked) {
    if (Array.isArray(entry)) {
        for (const subentry of entry) {
            UpdateEntryRecursively(subentry, checked);
        }
        return;
    }

    if (checked instanceof Set && (checked.has(entry.value) || checked.has(entry))) {
        checked = true;
    }

    if (checked != null) {
        entry.checked = (checked === true);
        entry.indeterminate = false;
    }

    UpdateEntryRecursively(entry.children || [], checked);

    if (entry.children && (checked == null || checked instanceof Set)) {
        const {checked, indeterminate} = CalcCheckedAndIndeterminate(entry.children);
        entry.checked = checked;
        entry.indeterminate = indeterminate;
    }
}

// TODO @branch should these be 2 classes or 1? Consider merging
export class TreeViewCheckboxNode extends UI.Element {
    getLabeledCheckbox(entry) {
        return <LabeledCheckbox
            disabled={entry.disabled}
            initialValue={entry.checked}
            indeterminate={entry.indeterminate}
            label={entry.label}
            onChange={enabled => {
                // We're listening on click since we only care about user-generate actions
                // Propagate the change down
                UpdateEntryRecursively(entry, enabled);
                this.subentriesInput?.updateOptions({entries: entry.children}); // Update if it exists
                // Propagate the change upwards
                this.dispatchChange();
            }}
            ref="checkboxInput"
        />;
    }

    dispatchChange() {
        this.dispatch("change", this);
    }

    render() {
        const {entry} = this.options;

        // Recalculate based on children if missing information
        // TODO should be done in setOptions
        UpdateEntryRecursively(entry, entry.checked);

        return [
            this.getLabeledCheckbox(entry),
            entry.children && <div style={{marginLeft: 20}}>
                <TreeViewCheckbox
                    entries={entry.children}
                    onChange={() => {
                        const {checked, indeterminate} = CalcCheckedAndIndeterminate(entry.children);
                        // Update the current entry
                        if (entry.checked != checked || entry.indeterminate != indeterminate) {
                            entry.checked = checked;
                            entry.indeterminate = indeterminate;
                            this.checkboxInput.setValue(checked, indeterminate);
                        }
                        this.dispatchChange();
                    }}
                    ref="subentriesInput"
                />
            </div>
        ]
    }

    addChangeListener(listener) {
        return this.addListener("change", listener);
    }
}

// This class will use entries as the state as well
@registerStyle(TreeViewCheckboxStyle)
export class TreeViewCheckbox extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.container);
    }

    static entryToValue(entry) {
        if (!entry) {
            return [];
        }
        if (Array.isArray(entry)) {
            return unwrapArray(entry.map(subentry => this.entryToValue(subentry)));
        }
        return unwrapArray([entry.checked ? entry.value : null, this.entryToValue(entry.children)]);
    }

    getValue(linearize=true) {
        if (linearize) {
            return this.constructor.entryToValue(this.options.entries);
        }
        return this.options.entries;
    }

    setValue(value) {
        if (Array.isArray(value)) {
            value = new Set(value); // We can take in true, false or a set of entries
        }
        UpdateEntryRecursively(this.options.entries, value);
        this.redraw();
        this.dispatch("change", this);
    }

    allChecked() {
        return CalcCheckedAndIndeterminate(this.options.entries).checked;
    }

    renderEntry(entry) {
        return <TreeViewCheckboxNode
            entry={entry}
            onChange={() => {
                this.dispatch("change", this);
            }}
        />;
    }

    addChangeListener(listener) {
        return this.addListener("change", listener);
    }

    render() {
        return this.options.entries.map(entry => this.renderEntry(entry));
    }
}
