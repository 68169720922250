import {UI} from "../../../stem-core/src/ui/UIBase";
import {getActiveSettingsVersion, merchantService} from "../../misc/MerchantService";
import {Link} from "../../../stem-core/src/ui/UIPrimitives";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {LoadingSpinner} from "../../../blinkpay/ui/LoadingSpinner";
import {Input} from "../../../stem-core/src/ui/input/Input";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {Level, Size} from "../../../stem-core/src/ui/Constants";
import {DashboardTitle} from "../../common/DashboardTitle";
import {MakeSimpleSummaryTable} from "../../common/SimpleSummaryTable";
import {
    MerchantSDKSettingsStore,
    apiMerchantDeleteSettingsVersion,
    apiMerchantChangeSettingsVersionMaster
} from "../../../client/state/merchant/MerchantSDKSettingsStore";
import {MerchantUserJourney, MerchantUserJourneyStore} from "../../../client/state/merchant/MerchantUserJourneyStore";
import {MerchantPanelStore} from "../../../client/state/merchant/MerchantPanelStore";
import {DashboardSection} from "../../common/DashboardSection.jsx";
import {CopyTextToClipboard} from "../../common/Utils";
import {BorderedLabel} from "../../common/BorderedLabel";
import {styleRule, StyleSheet} from "../../../stem-core/src/ui/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {isDeepEqual} from "../../../blinkpay/UtilsLib";
import {BaseEnum, makeEnum} from "../../../client/state/misc/BaseEnum.js";
import {JourneyEntrypointState} from "./active-journeys/JourneyEntrypointState.js";


class VersionDiffComponentStyle extends StyleSheet {
    @styleRule
    container = {
        marginTop: -8,
    };

    @styleRule
    topLevelChangeLine = {
        paddingTop: 8,
        paddingBottom: 2,
    };

    @styleRule
    nestedChangeLine = {
        paddingLeft: 24,
        paddingBottom: 2,
    };
}

@makeEnum
class DiffType extends BaseEnum {
    static ADDED = {color: Theme.props.MERCHANT_SUCCESS};
    static EDITED = {color: Theme.props.COLOR_WARNING};
    static DELETED = {color: Theme.props.MERCHANT_ERROR};

    toUI() {
        return <span style={{color: this.color}}>{super.toString()}</span>
    }
}


@registerStyle(VersionDiffComponentStyle)
class VersionDiffComponent extends UI.Element {
    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.container);
    }

    renderChangeFirstLine(diffType, sdkResource) {
        const resourceName = sdkResource.name;
        const resourceType = (sdkResource instanceof MerchantUserJourney) ? "user journey" : "panel";
        const resourceUrl = (sdkResource instanceof MerchantUserJourney) ? `/journeys/${sdkResource.alias}` : `/panels/${sdkResource.alias}`;
        const resourceTitleElement = (diffType === DiffType.DELETED)
            ? <strong>{resourceName}</strong>
            : <strong><Link href={resourceUrl}>{resourceName}</Link></strong>;
        return <li className={this.styleSheet.topLevelChangeLine}>
            {diffType} {resourceType} {resourceTitleElement}
        </li>;
    }

    renderChangeLine(changeLineChildren) {
        return <li className={this.styleSheet.nestedChangeLine}>{changeLineChildren}</li>;
    }

    renderEditedResourceBaseChanges(resource) {
        const changes = [];
        if (resource.name !== resource.parent.name) {
            changes.push(this.renderChangeLine(["Renamed from ", <strong>{resource.parent.name}</strong>, " to ", <strong>{resource.name}</strong>]));
        }
        if (!isDeepEqual(resource.options, resource.parent.options)) {
            changes.push(this.renderChangeLine("Changed user experience options"));
        }
        if (!isDeepEqual(resource.dashboardOptions, resource.parent.dashboardOptions)) {
            changes.push(this.renderChangeLine("Changed dashboard options"));
        }
        return changes;
    }

    renderActiveJourneysDiff(sdkSettings) {
        const oldJourneyEntrypointState = new JourneyEntrypointState(sdkSettings.parentVersion);
        const newJourneyEntrypointState = new JourneyEntrypointState(sdkSettings);

        if (!oldJourneyEntrypointState.isEqual(newJourneyEntrypointState)) {
            return <li className={this.styleSheet.topLevelChangeLine}>
                {DiffType.EDITED} active user journeys
            </li>;
        }
    }

    renderDeletedResources(store, workingVersion) {
        const deleteObjects = store.filterBy({settingsVersion: workingVersion.parentVersion})
            .filter(obj => obj.getFork(workingVersion) == null);
        return deleteObjects.map(obj => this.renderChangeFirstLine(DiffType.DELETED, obj));
    }

    renderEditedResources(store, workingVersion) {
        return store.filterBy({settingsVersion: workingVersion}).map(obj => {
            if (!obj.parentId) {
                return;
            }
            let changes = this.renderEditedResourceBaseChanges(obj);
            if (changes.length > 0) {
                changes = [
                    this.renderChangeFirstLine(DiffType.EDITED, obj),
                    ...changes,
                ];
            }
            return changes;
        });
    }

    renderNewResources(store, workingVersion) {
        return store.filterBy({settingsVersion: workingVersion, parent: null})
            .map(obj => this.renderChangeFirstLine(DiffType.ADDED, obj));
    }

    render() {
        const activeSDKSettings = getActiveSettingsVersion(this.options.merchant);

        return [
            this.renderActiveJourneysDiff(activeSDKSettings),

            this.renderNewResources(MerchantUserJourneyStore, activeSDKSettings),
            this.renderNewResources(MerchantPanelStore, activeSDKSettings),

            this.renderEditedResources(MerchantUserJourneyStore, activeSDKSettings),
            this.renderEditedResources(MerchantPanelStore, activeSDKSettings),

            this.renderDeletedResources(MerchantPanelStore, activeSDKSettings),
            this.renderDeletedResources(MerchantUserJourneyStore, activeSDKSettings),
        ];
    }
}


class PreviewChangesLink extends UI.Element {
    setOptions(options) {
        this.url = this.url || options.url;
        return super.setOptions(options);
    }

    renderVersionedUrl() {
        let versionUrl;
        try {
            versionUrl = new URL(this.url);
        } catch {
            return <div>Invalid link provided</div>;
        }
        versionUrl.searchParams.set("blinkversion", this.options.settingsVersionKey);
        versionUrl = versionUrl.toString();
        return [
            <div style={{display: "flex", alignItems: "center"}}>
                <div style={{flex: 1, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth: 460}}>
                    <Link newTab href={versionUrl}>{versionUrl}</Link>
                </div>
                <div>
                    <Button onClick={() => CopyTextToClipboard(versionUrl)} label="Copy link" size={Size.SMALL}/>
                </div>
            </div>
        ];
    }

    render() {
        return MakeSimpleSummaryTable([
            ["Enter a link", <Input value={this.url} style={{width: "100%"}} onInput={(_, input) => {
                this.url = input.getValue();
                this.redraw();
            }}/>],
            ["Preview changes", this.renderVersionedUrl()],
            ["List of changes", <VersionDiffComponent merchant={this.options.merchant} />],
        ], {
            verticalAlign: "top",
        }, {}, {
            padding: "12px 0px",
            whiteSpace: "nowrap",
        });
    }
}


@autoredraw(MerchantSDKSettingsStore, MerchantUserJourneyStore, MerchantPanelStore)
export class UserExperiencePage extends UI.Element {
    async discardSettingsVersion() {
        const confirm = await ConfirmationModal.prompt({
            title: "Discard all draft changes?",
            description: "Revert back to the live version. This action cannot be undone, and all changes will be lost.",
        });
        if (!confirm) {
            return;
        }
        LoadingSpinner.show();
        await apiMerchantDeleteSettingsVersion({
            settingsVersionId: getActiveSettingsVersion(this.options.merchant).id,
        });
        LoadingSpinner.hide();
    }

    async publishSettingsVersion() {
        const confirm = await ConfirmationModal.prompt({
            title: "Publish all draft changes to live?",
            description: "You can roll this back by contacting us.",
        });
        if (!confirm) {
            return;
        }
        LoadingSpinner.show();
        await apiMerchantChangeSettingsVersionMaster({
            settingsVersionId: getActiveSettingsVersion(this.options.merchant).id,
        });
        LoadingSpinner.hide();
    }

    render() {
        const {merchant} = this.options;
        const sdkSettings = getActiveSettingsVersion(this.options.merchant);
        const journeyEntrypointState = new JourneyEntrypointState(sdkSettings);
        const journeyEntrypoints = journeyEntrypointState.entries;
        const draftChangesSectionChildren = [];
        if (!sdkSettings.isMaster) {
            const previewUrl = merchant.getUrl();
            draftChangesSectionChildren.push(
                <PreviewChangesLink url={previewUrl} settingsVersionKey={sdkSettings.key} merchant={merchant} />,
                <Button level={Level.DEFAULT} onClick={() => this.discardSettingsVersion()}>Discard changes</Button>,
                <Button onClick={() => this.publishSettingsVersion()}>Publish changes</Button>,
            );
        }
        return [
            <DashboardTitle title="User Experience"/>,
            MakeSimpleSummaryTable([
                ["Configured Panels", MerchantPanelStore.filterBy({settingsVersion: sdkSettings}).length],
                ["Configured User Journeys", MerchantUserJourneyStore.filterBy({settingsVersion: sdkSettings}).length],
                ["Active User Journeys", journeyEntrypoints.reduce((s, entrypoint) => s + entrypoint.numBranches(), 0)],
                ["Active A/B tests and experiments", journeyEntrypoints.reduce((s, entrypoint) => s + (entrypoint.numBranches() > 1), 0)],
            ]),
            <DashboardSection title="Draft changes"
                              style={{maxWidth: 720}}
                              isLast
                              description={sdkSettings.isMaster
                                  ? <div>
                                      There are no draft changes, all the User Experience configured is live.
                                  </div>
                                  : <div>
                                      There are draft changes that need to be published. Use the link below to preview
                                      the changes on the live
                                      website, or publish the changes using the button.
                                  </div>}>
                {draftChangesSectionChildren}
            </DashboardSection>,
        ];
    }
}


@autoredraw(MerchantSDKSettingsStore)
export class DraftVersionLabel extends UI.Primitive("span") {
    render() {
        const merchant = merchantService.getMerchant();
        if (!getActiveSettingsVersion(merchant).isMaster) {
            return <BorderedLabel style={{marginLeft: 4, fontSize: "0.8em"}}>DRAFT</BorderedLabel>;
        }
        return null;
    }
}
