import {UI, StyleSheet, styleRule, registerStyle, Theme} from "../../stem-core/src/ui/UI";

export class LineSeparatorStyle extends StyleSheet {
    @styleRule
    separatorContainer = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 15,
    };

    @styleRule
    text = {
        padding: "0 8px",
        fontStyle: "italic",
        color: this.themeProps.DIVIDER_TEXT_COLOR,
    };

    @styleRule
    line = {
        minHeight: 1,
        width: "100%",
        flex: 1,
    };
}

@registerStyle(LineSeparatorStyle)
export class LineSeparator extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            lineColor: Theme.props.DIVIDER_LINE_COLOR,
            text: null,
        }
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.separatorContainer);
    }

    render() {
        const {styleSheet} = this;
        const {text, lineColor} = this.options;
        return [
            <div className={styleSheet.line} style={{background: lineColor}}/>,
            text ? <div className={styleSheet.text}>{text}</div> : null,
            <div className={styleSheet.line} style={{background: lineColor}}/>,
        ]
    }
}
