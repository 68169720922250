import {UI} from "../../stem-core/src/ui/UIBase";
import {DashboardTablePage} from "../common/DashboardTablePage";
import {Messages} from "../../blinkpay/Messages";
import {MerchantPayoutStore} from "../../client/state/merchant/MerchantPayoutStore";
import {Money} from "../../client/state/misc/Money";
import {MakeSimpleSummaryTable} from "../common/SimpleSummaryTable";
import {CENTER_COLUMN, LEFT_COLUMN, MONEY_COLUMN} from "../common/theme/TableStyle";
import {DownloadButton, FileResourceLink} from "../ui/DownloadButton";

export class MerchantPayoutsPage extends DashboardTablePage {
    getDefaultOptions() {
        return {
            title: Messages.payouts,
            description: "A list of all your payouts, and information on revenue to be wired.",
            store: MerchantPayoutStore,
            endpoint: "/merchant/get_payouts/",
        }
    }

    getFilters() {
        return null;
    }

    summarySection() {
        const lastResponse = this.getSummaryLastResponse();
        if (!lastResponse) {
            return;
        }

        const {outstandingBalance, totalPaid} = lastResponse;
        const entries = [
            ["Outstanding balance", new Money(outstandingBalance, this.getCurrency())],
            ["Total paid", new Money(totalPaid, this.getCurrency())],
        ];

        return MakeSimpleSummaryTable(entries);
    }

    getPayoutLink(payout) {
        return <FileResourceLink fileResource={payout.pdfReport}>
            {payout.getName() + " (pdf)"}
        </FileResourceLink>
    }

    getDetailedReportLink(payout) {
        return <DownloadButton fileResource={payout.zipArchive}>
            Download .zip
        </DownloadButton>
    }

    getColumns() {
        return [
            ["Date", payout => payout.createdAt.format("MMM DD, YYYY"), LEFT_COLUMN],
            ["Paid To", payout => `Bank account ending in ${payout.bankAccountLastDigits || "****"}`, CENTER_COLUMN],
            ["Amount", payout => payout.paidAmount, MONEY_COLUMN],
            ["Payout", payout => this.getPayoutLink(payout), CENTER_COLUMN],
            ["Detailed Report", payout => this.getDetailedReportLink(payout), CENTER_COLUMN],
        ]
    }
}
