import {UI} from "../../../stem-core/src/ui/UIBase";
import {DonationOptions} from "./DonationOptions";
import {DonationOfferStore} from "../../../client/state/DonationOfferStore";
import {Messages} from "../../../blinkpay/Messages";
import {DashboardTitle} from "../../common/DashboardTitle";

export class MerchantDonationSettingsPage extends UI.Element {
    render() {
        const {merchant} = this.options;
        const donationOffer = DonationOfferStore.getByMerchantId(merchant.id)[0];

        return [
            <DashboardTitle title={Messages.donationOptionsTitle} description={Messages.donationOptionsDescription} />,
            donationOffer ?
                <DonationOptions donationOffer={donationOffer} /> :
                <div style={{fontSize: 18, marginTop: 24}}>
                    No donation offers available, enable by emailing us at <a>biz.support@blink.net</a>.
                </div>
        ]

    }
}
