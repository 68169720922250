import {UI} from "../../../stem-core/src/ui/UIBase";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {BlinkInput} from "../../../blinkpay/ui/Input";
import {Toast} from "../../../blinkpay/ui/Toast";
import {LoadingSpinner} from "../../../blinkpay/ui/LoadingSpinner";
import {
    apiMerchantCreateEmailSender
} from "../../../client/state/merchant/MerchantEmailSender";


export class MerchantAddEmailSenderModal extends ConfirmationModal {
    inputRefs = {};

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Add new email sender",
            description: "Will create an email sender entry which you can use to send emails to your audiences",
            confirmLabel: "Confirm",
            confirmAction: () => this.addEmailSender(),
        };
    }

    validateInputs() {
        let isValid = true;

        for (const [key, fieldInput] of Object.entries(this.inputRefs)) {
            fieldInput.validate();
            isValid = isValid && fieldInput.isValid();
        }

        return isValid;
    }

    async addEmailSender() {
        if (!this.validateInputs()) {
            return;
        }
        const request = {
            emailAddress: this.inputRefs.emailInput.getValue().trim(),
            name: this.inputRefs.nameInput.getValue().trim(),
        }

        LoadingSpinner.show();
        try {
            await apiMerchantCreateEmailSender(request);
            this.hide();
        } catch (error) {
            Toast.showError(error)
        } finally {
            LoadingSpinner.hide();
        }
    }

    render() {
        return <div>
            <BlinkInput ref={{parent: this.inputRefs, name:"emailInput"}}
                        label={"Email"}
                        inputAttributes={{placeholder: "Email"}}
                        validators={[{condition: () => !this.inputRefs.emailInput.isEmpty()}]}
            />
            <BlinkInput ref={{parent: this.inputRefs, name:"nameInput"}}
                        label={"Name (Optional)"}
                        inputAttributes={{placeholder: "Name"}}
            />
        </div>
    }
}
