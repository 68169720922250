import {Level, registerStyle, styleRule, styleRuleInherit, StyleSheet, UI} from "../../stem-core/src/ui/UI";
import {Button} from "../../stem-core/src/ui/UI";
import {DownloadIcon} from "../../blinkpay/SVGElements";
import {ButtonStyle} from "../../stem-core/src/ui/button/ButtonStyle";

class FileResourceStyle extends StyleSheet {
    @styleRule
    container = {
        cursor: "pointer",
    }
}


export const FileResourceLinkMixin = (BaseClass) => class FileResourceLink extends BaseClass {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            className: FileResourceStyle.getInstance().container,
            target: "_self",
        }
    }

    async triggerFileDownload() {
        const fileResource = this.getFileResource();
        // When triggering a download, we'll turn into a regular link
        // TODO it's possible the URL expired, in which case we'd need to get it again.
        if (!fileResource || this.options.href) {
            return;
        }
        const url = await fileResource.getURL();
        // Update the URL.
        this.updateOptions({href: url});
        // Simulate a click and trigger the download.
        // Even if somehow the browser blocks this (shouldn't user triggered flow), at least the URL would be correct later.
        // TODO @branch maybe accept an event here, and duplicate that (Ctrl + Click for instance)
        this.node.click();
    }

    getFileResource() {
        return this.options.fileResource;
    }

    onMount() {
        this.addClickListener(() => this.triggerFileDownload());
    }
}

export const FileResourceLink = FileResourceLinkMixin(UI.Primitive("a"));


class DownloadButtonStyle extends ButtonStyle {
    @styleRuleInherit
    SECONDARY = {
        ":active": {
            backgroundColor: "none",
        },
        ":focus": {
            backgroundColor: "none",
        },
        ":visited": {
            backgroundColor: "none",
        },
    };

    @styleRuleInherit
    DEFAULT = {
        ":focus": {
            backgroundColor: "none",
        }
    }

    @styleRule
    disabled = {
        cursor: "not-allowed !important",
    }
}

@registerStyle(DownloadButtonStyle)
export class DownloadButton extends FileResourceLinkMixin(UI.Primitive(Button, "a")) {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(options),
            level: Level.SECONDARY,
        }
    }

    getIcon() {
        return <DownloadIcon style={{paddingRight: 8, verticalAlign: "middle"}} />;
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        if (this.options.disabled) {
            attr.addClass(this.styleSheet.disabled);
        }
    }
}
