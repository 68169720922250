import {MakeStore, StoreObject} from "../../../../stem-core/src/state/Store";
import {field} from "../../misc/StoreField";
import {apiConnection} from "../../../connection/BlinkApiConnection";
import {TaskStatus} from "../../misc/GenericEnums";

export class MerchantMailChimpUserList extends StoreObject {
    @field("Merchant") merchant;

    @field(String) mcListId;
    @field(String) clientKeyRegion;
    // @field(String) clientKey;  // not sent to front end

    @field(String) interestCategoryTitle;
    @field(String) interestCategoryId;
    @field(Array) mergeTags;

    @field(TaskStatus) lastSyncStatus;
    @field(Date) lastSyncStartedAt;
    @field(Date) lastSyncUpdatedAt;
    @field(Date) lastSyncFinishedAt;
}

export class MerchantMailChimpInterest extends StoreObject {
    @field("Merchant") merchant;
    @field("MerchantMailChimpUserList") userList;
    @field("MerchantAudience") audience;
    @field(String) interestId;
    @field(String) interestName;
}

export const MerchantMailChimpUserListStore = MakeStore("MerchantMailChimpUserList", MerchantMailChimpUserList, {
    dependencies: ["Merchant"]
});

export const MerchantMailChimpInterestStore = MakeStore("MerchantMailChimpInterest", MerchantMailChimpInterest, {
    dependencies: ["Merchant", "MerchantMailChimpUserList", "MerchantAudience"],
});

export async function apiSyncMailChimpUserList(userListId) {
    return apiConnection.post("/external_providers/sync_mail_chimp_user_list/", {
        userListId,
    });
}
