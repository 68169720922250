import {MakeStore, StoreObject} from "../../stem-core/src/state/Store.js";
import {field} from "./misc/StoreField.js";
import {apiConnection} from "../connection/BlinkApiConnection.js";

class SubscriptionBenefit extends StoreObject {
    @field(String) name;
    @field(String) description;
    @field(Boolean) fullDigitalAccess;

    toString() {
        return this.name;
    }
}

export const SubscriptionBenefitStore = MakeStore("SubscriptionBenefit", SubscriptionBenefit);

export async function apiMerchantCreateSubscriptionBenefit(request) {
    const response = await apiConnection.post("/merchant/create_subscription_benefit/", request);
    return SubscriptionBenefitStore.loadObjectFromResponse(response);
}

export async function apiMerchantEditSubscriptionBenefit(request) {
    return apiConnection.post("/merchant/edit_subscription_benefit/", request);
}
