import {ProgressBar, ProgressBarStyle} from "../../../stem-core/src/ui/ProgressBar";
import {styleRuleInherit} from "../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";

export class DashboardProgressBarStyle extends ProgressBarStyle {
    @styleRuleInherit
    container = {
        height: 6,
        marginTop: 6,
        marginBottom: 6,
        borderRadius: this.themeProps.CARD_BORDER_RADIUS,
    }

    @styleRuleInherit
    DEFAULT = {
        boxShadow: null,
    }
}

@registerStyle(DashboardProgressBarStyle)
export class DashboardProgressBar extends ProgressBar {
}
