import {UI, registerStyle, styleRuleInherit} from "../../../stem-core/src/ui/UI";
import {BaseCard, BaseCardStyle} from "../../../blinkpay/ui/BaseCard";
import {Messages} from "../../../blinkpay/Messages";
import {ChooseMerchantList} from "./ChooseMerchantList";

export class ChooseMerchantCardStyle extends BaseCardStyle {
    @styleRuleInherit
    card = {
        margin: "36px 0px",
        width: 360,
        background: this.themeProps.CARD_BACKGROUND_COLOR,
    };

    @styleRuleInherit
    title = {
        color: this.themeProps.PAGE_TEXT_COLOR,
    };
}

@registerStyle(ChooseMerchantCardStyle)
export class ChooseMerchantCard extends BaseCard {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: Messages.chooseYourMerchant,
        };
    }

    render() {
        return <ChooseMerchantList />;
    }
}
