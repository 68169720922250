import {UI} from "../../../stem-core/src/ui/UIBase";
import {InputableElement, Select} from "../../../stem-core/src/ui/input/Input";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {SimpleTable} from "../../ui/SimpleTable";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {BlinkInputField} from "../../common/Input";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {TrashCanIcon} from "../../NavSidePanelIcons";
import {
    apiMerchantCreateSubscriptionBenefit, apiMerchantEditSubscriptionBenefit,
    SubscriptionBenefitStore
} from "../../../client/state/SubscriptionBenefitStore.js";
import {cleanObject} from "../../../stem-core/src/base/Utils.js";


class SubscriptionBenefitDialog extends ConfirmationModal {
    getDefaultOptions(options) {
        const {entry} = options;
        return {
            ...super.getDefaultOptions(options),
            title: entry ? "Edit benefit" : "New coverage benefit entry",
            description: "A subscription benefit is intended to be used to make decisions programatically (such as in journeys) or in bookkeeping.",
            confirmLabel: entry ? "Edit" : "Add entry",
            confirmAction: () => this.resolve(this.getValue()),
        }
    }

    getValue() {
        return {
            name: this.nameInput.getValue(),
            alias: this.aliasInput.getValue() || null,
            description: this.descriptionInput.getValue() || null,
        }
    }

    render() {
        const {entry} = this.options;
        return [
            <div>
                <BlinkInputField label="Name">
                    <TextInput initialValue={entry?.name} ref="nameInput"/>
                </BlinkInputField>
            </div>,
            // TODO @branch text input
            <div>
                <BlinkInputField label="Description (optional)">
                    <TextInput initialValue={entry?.description} ref="descriptionInput"/>
                </BlinkInputField>
            </div>,
            <div>
                <BlinkInputField label="Alias (optional alphanumeric, to identify the benefit in SDK calls)">
                    <TextInput initialValue={entry?.alias} ref="aliasInput"/>
                </BlinkInputField>
            </div>,
        ]
    }
}

export class SubscriptionBenefitsEditor extends InputableElement {
    entries = [];

    getValue() {
        return this.entries;
    }

    setValue(value) {
        this.entries = Array.from(value);
        this.redraw();
    }

    async createEntry() {
        let subscriptionBenefit = this.benefitToAddInput.getValue();
        if (!subscriptionBenefit.id) {
            const newEntry = await SubscriptionBenefitDialog.prompt();
            if (!newEntry) {
                return;
            }
            subscriptionBenefit = await apiMerchantCreateSubscriptionBenefit(cleanObject(newEntry));
        }

        this.entries.push(subscriptionBenefit.id);
        this.redraw();
    }

    async editEntry(index) {
        // TODO @branch On edit prompt where a subscription is used
        const entry = SubscriptionBenefitStore.get(this.entries[index]);
        const newValue = await SubscriptionBenefitDialog.prompt({entry});
        if (newValue) {
            await apiMerchantEditSubscriptionBenefit({
                benefitId: entry.id,
                ...newValue
            });
            this.redraw();
        }
    }

    deleteEntry(index) {
        this.entries.splice(index, 1);
        this.redraw();
        // TODO @branch delete the entry if it's unreferenced
    }

    render() {
        const columns = [
            ["Name", entry => entry.name],
            ["Alias", entry => entry.alias],
            ["Description", entry => entry.description],  // TODO @branch elipsis
            ["Actions", (entry, index) => <div>
                <Button onClick={() => this.editEntry(index)}>Edit</Button>
                <TrashCanIcon style={{cursor: "pointer", verticalAlign: "middle"}} size={24} onClick={() => this.deleteEntry(index)} />
            </div>]
        ];

        const usedBenefits = new Set(this.entries.map(benefitId => SubscriptionBenefitStore.get(benefitId)));
        const unusedBenefits = SubscriptionBenefitStore.all().filter(o => !usedBenefits.has(o));

        return [<p>
            Subscription benefits are used for internal logic and bookkeeping (segmenting digital access, etc).
        </p>,
            <div>
                <Select options={[
                    "Create new benefit",
                    ...unusedBenefits,
                ]}
                        ref="benefitToAddInput"
                /> <Button onClick={() => this.createEntry()}>Add Benefit</Button>
            </div>,
            (this.entries.length === 0) ? <div>No explicit benefits configured.</div> :
                <SimpleTable columns={columns} entries={this.entries.map(benefitId => SubscriptionBenefitStore.get(benefitId))}/>
        ]
    }
}
