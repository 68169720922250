import {StyleSheet, styleRule, UI, registerStyle} from "stem-core/src/ui/UI";
import {isDeepEqual} from "../../../blinkpay/UtilsLib";


export class DonationFormComponentStyle extends StyleSheet {
    @styleRule
    headerText = {
        fontSize: 12,
        fontWeight: "bold",
        textTransform: "uppercase",
        color: this.themeProps.MERCHANT_3,
        marginBottom: 18,
    };

    @styleRule
    descriptionText = {
        fontSize: 12,
        color: this.themeProps.MERCHANT_3,
        marginBottom: 18,
    };

    @styleRule
    labelText = {
        fontSize: 14,
        marginLeft: 15,
    };

    @styleRule
    disabledSection = {
        opacity: 0.5,
        pointerEvents: "none",
    };
}


@registerStyle(DonationFormComponentStyle)
export class DonationFormComponent extends UI.Element {
    updateState(newState, dispatchUpdate=true) {
        super.updateState(newState);
        if (dispatchUpdate) {
            this.dispatchUpdate();
        }
    }

    resetState() {
        this.updateState(this.getDefaultState(), false);
    }

    getData(state=null) {
        if (state === null) {
            return this.exportState(this.state);
        }
        return this.exportState(state);
    }

    exportState(state) {
        return state;
    }

    isChanged() {
        return !isDeepEqual(this.getData(this.state), this.getData(this.getDefaultState()));
    }

    hasInputError() {
        return false;
    }

    dispatchUpdate() {
        this.dispatch("update");
    }

    addUpdateListener(callback) {
        this.addListener("update", callback);
    }
}
