import {CleanupJobs, Dispatcher} from "../../stem-core/src/base/Dispatcher.js";

export class OnceDispatcher extends Dispatcher {
    dispatch(...args) {
        if (this.hasOwnProperty("dispatchArgs")) {
            console.error("Multiple dispatches on a once dispatcher");
            return;
        }
        this.dispatchArgs = args;
        super.dispatch(...args);
    }

    haveDispatched() {
        return !!this.dispatchArgs;
    }

    addListener(callback) {
        if (this.haveDispatched()) {
            callback(...this.dispatchArgs);
            return new CleanupJobs(); // Empty cleanup
        } else {
            let handler = super.addListener(function () {
                callback(...arguments);
                handler.remove();
            });
            return handler;
        }
    }

    addListenerOnce(callback) {
        return this.addListener(callback);
    }

    // TODO @branch maybe move this into the base Dispatcher class
    async awaitOnce() {
        return new Promise((resolve) => this.addListenerOnce((...args) => resolve(...args)));
    }
}
