import {registerStyle, styleRule, UI} from "stem-core/src/ui/UI";
import {DonationFormComponent, DonationFormComponentStyle} from "./DonationFormComponent";
import {Messages} from "../../../blinkpay/Messages";
import {TrashCanIcon} from "../../NavSidePanelIcons";
import {DonationAmountInput, DonationAmountInputController} from "./DonationAmountInput";
import {DashboardRadio} from "../../ui/DashboardRadio";
import {isIntegerInput} from "../../common/Utils";
import {LabeledCheckbox} from "../../../blinkpay/ui/Checkbox";


class PredefinedAmountsStyle extends DonationFormComponentStyle {
    @styleRule
    amountHeaderLabel = {
        display: "inline-block",
        width: 178,
    };

    @styleRule
    amountRow = {
        marginBottom: 6,
    };

    @styleRule
    actionsContainer = {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    };

    @styleRule
    errorMessage = {
        color: this.themeProps.INPUT_ERROR_COLOR,
        fontSize: 13,
        marginTop: 6,
    };

    @styleRule
    removeAmountAction = {
        cursor: "pointer",
    };

    @styleRule
    newAmountAction = {
        display: "inline-block",
        fontSize: 16,
        color: this.themeProps.MERCHANT_LINK,
        cursor: "pointer",
        marginTop: 6,
    };

    @styleRule
    addSign = {
        fontSize: 24,
        fontWeight: "bold",
        marginRight: 6,
    };

    @styleRule
    addText = {
        verticalAlign: "2.5px",
    };

    @styleRule
    allowCustomAmountRow = {
        display: "flex",
        marginTop: 40,
        marginBottom: 12,
    };
}


@registerStyle(PredefinedAmountsStyle)
export class PredefinedAmounts extends DonationAmountInputController(DonationFormComponent) {
    getDefaultState() {
        const donationOffer = this.options.donationOffer;
        const currency = donationOffer.getCurrency();
        return {
            ...super.getDefaultState(),
            predefinedAmounts: (donationOffer.predefinedAmounts || []).map((amount) => currency.amountToMainUnits(amount)),
            defaultPredefinedAmountIndex: donationOffer.defaultPredefinedAmountIndex,
            allowCustomAmount: donationOffer.allowCustomAmount,
            focusInputIndex: null,
        }
    }

    exportState(state) {
        const currency = this.options.donationOffer.getCurrency();
        const predefinedAmounts = state.predefinedAmounts.map(amount =>
            isIntegerInput(amount) ? currency.mainUnitsToAmount(amount) : amount
        );
        return {
            predefinedAmounts: predefinedAmounts,
            defaultPredefinedAmountIndex: state.defaultPredefinedAmountIndex,
            allowCustomAmount: state.allowCustomAmount,
        }
    }

    updateState(newState, dispatchUpdate=true) {
        super.updateState(newState, dispatchUpdate);
        this.state.predefinedAmounts.forEach((amount, idx) => {
            this["amount-" + idx].setValue(amount);
        });
        this.redraw();
    }

    hasInputError() {
        return this.hasInvalidAmounts() || this.hasDuplicateAmounts();
    }

    addNewAmountOption() {
        this.updateState({predefinedAmounts: [...this.state.predefinedAmounts, this.generateDefaultAmount()]});
    }

    generateDefaultAmount() {
        const validAmounts = this.state.predefinedAmounts.filter(amount => !this.isInvalidAmount(amount));
        const maxValidAmount = Math.max(0, ...validAmounts.map(amount => parseInt(amount)));
        const currency = this.options.donationOffer.getCurrency();
        const maxAmount = currency.amountToMainUnits(this.options.donationOffer.maxAmount);
        return Math.min(maxValidAmount + 10, maxAmount);
    }

    removeAmountOption(idx) {
        let predefinedAmounts = [...this.state.predefinedAmounts];
        predefinedAmounts.splice(idx, 1);

        let defaultPredefinedAmountIndex = this.state.defaultPredefinedAmountIndex;
        if (idx < defaultPredefinedAmountIndex) {
            defaultPredefinedAmountIndex = defaultPredefinedAmountIndex - 1;
        }
        defaultPredefinedAmountIndex = Math.min(defaultPredefinedAmountIndex, predefinedAmounts.length - 1);

        this.updateState({predefinedAmounts, defaultPredefinedAmountIndex});
    }

    updateAmountOption(idx, value) {
        let predefinedAmounts = [...this.state.predefinedAmounts];
        predefinedAmounts[idx] = value;
        this.updateState({predefinedAmounts});
    }

    setDefaultAmountOption(idx) {
        this.updateState({defaultPredefinedAmountIndex: idx});
    }

    toggleAllowCustomAmount() {
        this.updateState({allowCustomAmount: !this.state.allowCustomAmount})
    }

    setFocusInputIndex(idx) {
        this.updateState({focusInputIndex: idx});
    }

    hasInvalidAmounts() {
        return this.state.predefinedAmounts.reduce(
            (result, amount) => result || this.isInvalidAmount(amount), false);
    }

    hasDuplicateAmounts() {
        return this.state.predefinedAmounts.reduce(
            (result, amount) => result || this.isDuplicateAmount(amount), false);
    }

    isDuplicateAmount(amount) {
        return this.state.predefinedAmounts.reduce(
            (count, value) => count + (value.toString() === amount.toString()), 0) > 1;
    }

    renderRows() {
        const {styleSheet} = this;
        const canDelete = this.state.predefinedAmounts.length > 1;
        return this.state.predefinedAmounts.map((amount, index) => {
            let inputError = null;
            if (this.state.focusInputIndex !== index) {
                inputError = this.getInputErrorMessage(amount);
                if (inputError === null && this.state.focusInputIndex === null && this.isDuplicateAmount(amount)) {
                    inputError = Messages.duplicatedAmount;
                }
            }

            return (
                <div key={index} className={styleSheet.amountRow}>
                    <div className={styleSheet.actionsContainer}>
                        <DonationAmountInput
                            ref={"amount-" + index}
                            initialValue={amount}
                            currency={this.options.donationOffer.getCurrency()}
                            error={inputError !== null ? "" : null}  // set input to error state without error message
                            onInput={(event, input) => this.updateAmountOption(index, input.getValue())}
                            onFocus={() => this.setFocusInputIndex(index)}
                            onBlur={() => this.setFocusInputIndex(null)}
                        />
                        <DashboardRadio
                            selected={index === this.state.defaultPredefinedAmountIndex}
                            onClick={() => this.setDefaultAmountOption(index)}
                        />
                        <TrashCanIcon
                            color={canDelete ? styleSheet.themeProps.PAGE_TEXT_COLOR: "#6C6C6C"}
                            className={styleSheet.removeAmountAction} size={24}
                            onClick={() => canDelete && this.removeAmountOption(index)}
                        />
                    </div>
                    <div className={styleSheet.errorMessage}>{inputError}</div>
                </div>
            )
        });
    }

    render() {
        const {styleSheet} = this;
        return [
            <div>
                <div className={styleSheet.headerText}>
                    <span className={styleSheet.amountHeaderLabel}> {Messages.amounts}</span>
                    <span>{Messages.default}</span>
                </div>
                {this.renderRows()}
            </div>,
            this.state.predefinedAmounts.length >= this.options.donationOffer.getMaxPredefinedAmountsSize()? null :
            <div className={styleSheet.newAmountAction} onClick={() => this.addNewAmountOption()}>
                <span className={styleSheet.addSign}>+</span>
                <span className={styleSheet.addText}>{Messages.donationAddNewAmount}</span>
            </div>,
            <div>
                <div className={styleSheet.allowCustomAmountRow}>
                    <LabeledCheckbox initialValue={this.state.allowCustomAmount} label={Messages.donationAllowCustomAmount}
                                     onChange={() => this.toggleAllowCustomAmount()}/>
                </div>
                <div className={styleSheet.descriptionText}>
                    {
                        Messages.donationCustomAmountLimit(
                            this.formatAmount(this.options.donationOffer.maxAmount)
                        ).toString()
                    }
                </div>
            </div>
        ]
    }
}
