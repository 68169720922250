import {UI, styleRule, styleRuleInherit, StyleSheet, registerStyle} from "stem-core/src/ui/UI";
import {Direction} from "../../stem-core/src/ui/Constants";
import {Messages} from "../../blinkpay/Messages";
import {Money} from "../../client/state/misc/Money";
import {merchantService} from "../misc/MerchantService";
import {TimeUnit} from "../../stem-core/src/time/Duration";
import {formatDateRange} from "../common/Utils";
import {isString} from "../../stem-core/src/base/Utils";
import {StemDate} from "../../stem-core/src/time/Date";
import {AnchoredPopup, AnchoredPopupStyle} from "../../blinkpay/ui/AnchoredPopup";


class ChartPopupStyle extends AnchoredPopupStyle {
    @styleRuleInherit
    popup = {
        background: this.themeProps.CHART_TOOLTIP_BACKGROUND_COLOR,
        color: "",
        boxShadow: this.themeProps.CHART_TOOLTIP_DROPDOWN_SHADOW,
        fontSize: "inherit",
        padding: 18,
    };

    before = {
        display: "none",
    };

    @styleRule
    title = {
        color: this.themeProps.TEXT_SECONDARY_COLOR,
        textAlign: "center",
        marginBottom: 18,
    };

    @styleRule
    infoLine = {
        ":not(:last-child)": {
            marginBottom: 5,
        },
        ":last-child": {
            marginTop: 18,
        },
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    };

    @styleRule
    label = {
        display: "flex",
        marginRight: 30,
    };

    @styleRule
    labelSquare = {
        backgroundColor: "red",
        width: "12px",
        height: "12px",
        margin: "4px 6px 0px 0px",
        display: "inline-block",
        verticalAlign: "middle",
    }
}

@registerStyle(ChartPopupStyle)
class ChartPopup extends AnchoredPopup {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            colors: [],
            labels: [],
            data: [],
            title: null,
            valueType: Chart.ValueType.COUNT,
            displayTotal: true,
        };
    }

    render() {
        const infoLines = [];
        const {colors, labels, data, title} = this.options;
        const currency = merchantService.getMerchant().getCurrency();

        for (let i = 0; i < data.length; i += 1) {
            const amount = this.options.valueType === Chart.ValueType.MONEY ? new Money(data[i], currency) : data[i];
            if ((this.options.valueType === Chart.ValueType.MONEY && amount.isZero()) || !amount) {
                continue;
            }
            infoLines.push(
                <div className={this.styleSheet.infoLine}>
                    <div className={this.styleSheet.label}>
                        <div className={this.styleSheet.labelSquare} style={{backgroundColor: colors[i]}}/>
                        <div>{labels[i]}</div>
                    </div>
                    <div style={{color: colors[i]}}>{amount}</div>
                </div>
            );
        }
        if (this.options.displayTotal) {
            const total = data.reduce((a, b) => a + b, 0);
            infoLines.push(
                <div className={this.styleSheet.infoLine}>
                    <span style={{fontWeight: "bold"}}>{Messages.total}</span>
                    <div>
                        {this.options.valueType === Chart.ValueType.MONEY ? new Money(total, currency) : total}
                    </div>
                </div>
            );
        }

        return [
            <div className={this.styleSheet.title}>{title}</div>,
            infoLines
        ]
    }
}

class ChartBarStyle extends StyleSheet {
    @styleRule
    chartBar = {
        display: "flex",
        flexDirection: "column",
        ":hover>:last-child": {
            fontWeight: "bold",
        },
    };

    @styleRule
    barContainer = {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        opacity: 0.8,
        ":hover": {
            opacity: 1,
        },
        ">*": {
            transition: "flex ease .2s",
        },
    };

    @styleRule
    bar = {
        margin: "0 2px",
    };

    @styleRule
    label = {
        color: this.themeProps.TEXT_SECONDARY_COLOR,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        textAlign: "center",
        position: "relative",
        marginTop: 14,
        height: 20,
        fontSize: 13,
    };
}

@registerStyle(ChartBarStyle)
class ChartBar extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            date: "",
            title: "",
            maxValue: 0,
            data: [],
            colors: [],
            labels: [],
            valueType: Chart.ValueType.COUNT,
            tooltipOptions: {},
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.chartBar);
    }

    render() {
        const {styleSheet} = this;
        const {date, data, maxValue, colors} = this.options;

        const total = data.reduce((a, b) => a + b, 0);

        return [
            <div className={styleSheet.barContainer}>
                <div style={{flex: (1 - total / maxValue) * 10}} ref="emptySpace"/>
                <div style={{flex: (total / maxValue) * 10, display: "flex", flexDirection: "column"}} ref="bars">
                    {data.map((datum, i) => (
                        <div style={{flex: (datum / total) * 10, background: colors[i]}} className={styleSheet.bar} />
                    ))}
                </div>
            </div>,
            <div className={styleSheet.label}>
                <div>{date}</div>
            </div>,
        ];
    }

    showPopup() {
        const {title, valueType, data, colors, labels} = this.options;
        ChartPopup.show({
            offset: 20,
            anchor: this.bars,
            toggleOnSameAnchor: true,
            direction: Direction.UP,
            hideOnMouseLeave: true,
            valueType,
            title,
            data,
            colors,
            labels,
        });
    }

    onMount() {
        super.onMount();

        for (const eventType of ["mouseenter", "tap"]) {
            this.bars.addNodeListener(eventType, () => this.showPopup());
        }
    }
}

class ChartStyle extends StyleSheet {
    gridAreaHeight = "calc(100% - 17px)";

    @styleRule
    barsContainer = {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        position: "relative",
        zIndex: 1,
        ">*": {
            flex: 1,
        },
    };

    @styleRule
    chart = {
        height: 400,
        display: "flex",
        position: "relative",
    };

    @styleRule
    grid = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        paddingBottom: "24px",
        position: "absolute",
    };

    @styleRule
    gridLine = {
        display: "flex",
        "::after": {
            height: "1px",
            opacity: 0.6,
            background: this.themeProps.CHART_LINE_COLOR,
            content: '""',
            flex: 1,
        },
    };

    gridStyle = {
        color: this.themeProps.TEXT_SECONDARY_COLOR,
        display: "flex",
        flexDirection: "column",
        height: this.gridAreaHeight,
        justifyContent: "space-between",
    };

    gridValuesStyle = {
        ...this.gridStyle,
        paddingRight: 10,
    };

    @styleRule
    gridLines = [
        this.gridStyle,
        {
            flex: 1,
            paddingBottom: "1.1em",
            marginLeft: -6,
        },
    ];

    @styleRule
    gridValues = [
        this.gridValuesStyle,
        {
            marginTop: "-.6em",
            ">*": {
                textAlign: "right",
            },
            zIndex: 1,
        },
    ];

    @styleRule
    invisibleGridValues = [
        this.gridValuesStyle,
        {
            marginTop: 0,
            opacity: 0,
            pointerEvents: "none",
        },
    ];

    @styleRule
    gridLinesContainer = {
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        top: 0,
        left: 0,
    };

    @styleRule
    legend = {
        position: "absolute",
        top: 0,
        left: 0,
        transform: `translateY(calc(-100% - 70px))`, // TODO: what?
    };

    @styleRule
    legendSquare = {
        width: "1em",
        height: "1em",
        margin: "0 10px 0 20px",
        display: "inline-block",
        verticalAlign: "middle",
    };
}

@registerStyle(ChartStyle)
export class Chart extends UI.Element {
    static ValueType = {
        MONEY: "money",
        COUNT: "count",
    }

    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.chart);
    }

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            valueType: Chart.ValueType.COUNT,
            tooltipOptions: {},
        };
    }

    getEntries() {
        const {data, endDate, categories} = this.options;

        return data.map((obj, index) => {
            const prevStart = index > 0 ? data[index - 1].date : obj.date;
            const from = obj.date;
            const to = index < data.length - 1 ? (new StemDate(data[index + 1].date)).subtract(TimeUnit.DAY) : endDate;
            const values = categories.map(cat => {
                let value = cat.value;
                if (isString(value)) {
                    value = obj[value];
                } else {
                    value = value(obj);
                }
                return value || 0;
            });

            const total = values.reduce((a, b) => a + b);

            let dateFormat = "D";
            if (!from.isSame(prevStart, TimeUnit.YEAR)) {
                dateFormat = "D MMM YYYY";
            } else if (!from.isSame(prevStart, TimeUnit.MONTH)) {
                dateFormat = "D MMM";
            }

            return {
                values,
                total,
                date: from.format(dateFormat),
                title: formatDateRange(from, to),
            }
        });
    }

    getChartBars() {
        const {categories, valueType, tooltipOptions} = this.options;
        const entries = this.getEntries();
        const maxValue = this.getGridValues()[4];

        const colors = categories.map(c => c.color);
        const labels = categories.map(c => c.name);

        return entries.map(entry => (
            <ChartBar
                valueType={valueType}
                title={entry.title}
                date={entry.date}
                data={entry.values}
                labels={labels}
                maxValue={maxValue}
                colors={colors}
                tooltipOptions={tooltipOptions}
            />
        ));
    }

    getGridValues() {
        const minGridValue = this.options.valueType === Chart.ValueType.MONEY ? 1e6 : 1;
        const gridDivision = this.options.valueType === Chart.ValueType.MONEY ? 4e6 : 4;

        const entries = this.getEntries();
        let maxValue = Math.max(minGridValue, ...entries.map(el => el.total));

        maxValue = Math.ceil(maxValue);
        if (maxValue % gridDivision) {
            maxValue += gridDivision - (maxValue % gridDivision);
        }

        return [0, maxValue / 4, maxValue / 2, (3 * maxValue) / 4, maxValue];
    }

    render() {
        const {styleSheet} = this;
        const gridValues = this.getGridValues().reverse();
        const currency = merchantService.getMerchant().getCurrency();

        return [
            <div className={styleSheet.gridValues}>
                {gridValues.map(value => (
                    <div>{this.options.valueType === Chart.ValueType.MONEY ? new Money(value, currency).toMainUnitString({decimalsDisplayed: 0}) : value}</div>
                ))}
            </div>,
            <div className={styleSheet.barsContainer}>{this.getChartBars()}</div>,
            <div className={styleSheet.gridLinesContainer}>
                <div className={styleSheet.invisibleGridValues}>
                    {gridValues.map(value => (
                        <div>{this.options.valueType === Chart.ValueType.MONEY ? new Money(value, currency).toMainUnitString() : value}</div>
                    ))}
                </div>
                <div className={styleSheet.gridLines}>
                    {gridValues.map(() => (
                        <div className={styleSheet.gridLine} />
                    ))}
                </div>
            </div>,
        ];
    }
}
