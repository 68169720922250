import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {UI} from "../../../stem-core/src/ui/UIBase";
import {MerchantAudienceInput} from "./MerchantAudienceDialog";
import {apiMerchantEditAudience} from "../../../client/state/merchant/MerchantAudienceStore";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {Level} from "../../../stem-core/src/ui/Constants";
import {TopLevelTabArea} from "../../common/theme/TabAreaStyle";
import {Panel} from "../../../stem-core/src/ui/UIPrimitives";
import {DashboardTableColumnManager, DashboardTablePage, MakeEmailEntry} from "../../common/DashboardTablePage";
import {
    apiMerchantEditAudienceMembers, MerchantAudienceMember,
    MerchantAudienceMemberStatus,
    MerchantAudienceMemberStore,
    SubscribedStatuses, UnsubscribedStatuses
} from "../../../client/state/merchant/MerchantAudienceMemberStore";
import {MerchantUserStore} from "../../../client/state/merchant/MerchantUserStore";
import {RadioButtons} from "../../../blinkpay/ui/Button";
import {MerchantReportType} from "../../../client/state/merchant/MerchantReportStore";
import {AudienceImportElement} from "./MerchantAudienceImport";
import {BlinkInputField} from "../../common/Input";
import {MerchantAudienceMembershipFilters} from "./MerchantAudienceMembershipFilters";
import {capitalize} from "../../../stem-core/src/base/Utils";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {Annotation, GroupByField, AggregateDataViewManager} from "../../common/AggregateDataViewManager";
import {StemDate} from "../../../stem-core/src/time/Date";
import {UserSearchInput} from "../../ui/input/UserSearchInput.jsx";
import {
    DateRangeInput,
    DefaultMerchantDateRangeIntervals,
    MakeMerchantAllTimeInterval
} from "../../ui/input/DateRangeInput";


@autoredraw
export class MerchantAudienceSize extends UI.Primitive("span") {
    render() {
        const {audience} = this.options;
        audience.ensureSizeLoaded();
        return audience.getSize();
        // TODO use a centralized formatter
        // const numberFormatter = new Intl.NumberFormat("en-US");
        // return numberFormatter.format(audience.getSize());
    }
}

export function AudienceMemberStatus(statusValue) {
    let [scope, status] = statusValue.split("_");
    if (scope === "auto") {
        scope = "filter";
    }
    return `${capitalize(status)} (${capitalize(scope)})`;
}

@autoredraw(MerchantAudienceMemberStore)
export class MerchantAudienceInstanceMembersSection extends DashboardTablePage {
    getDefaultOptions(options) {
        return {
            store: MerchantUserStore,
            endpoint: "/merchant/get_audience_members/",
            reportType: MerchantReportType.AUDIENCE_MEMBERS,
            paginatorOptions: {
                audienceId: options.audience.id,
                fullAudience: true,
            }
        }
    }

    getTitleSection() {
    }

    getFilters() {
        const memberType = this.memberTypeFilter.getActive();
        let status = MerchantAudienceMemberStatus.all();

        if (memberType === "Subscribed") {
            status = SubscribedStatuses;
        }

        if (memberType === "Unsubscribed") {
            status = UnsubscribedStatuses;
        }

        return {
            userFilter: this.userSearchInput.getValue(),
            audienceId: this.options.audience.id,
            status,
            metadata: this.columnManager.getMetadataFilters(),
            ...this.changedStatusAtInput.valueOf(),
        };
    }


    getFilterSection() {
        const {merchant} = this.options.audience;
        return [
            <UserSearchInput ref="userSearchInput" filterByAudience={false} onChange={() => this.applyFilters()} />,

            <BlinkInputField label="Member type">
                <RadioButtons
                    activeValue="All"
                    values={["All", "Subscribed", "Unsubscribed"]}
                    onSelect={() => this.applyFilters()}
                    ref="memberTypeFilter"/>
            </BlinkInputField>,

            <BlinkInputField label="Changed status at">
                <DateRangeInput
                    ref="changedStatusAtInput"
                    style={{maxWidth: 350, display: "inline-block"}}
                    options={DefaultMerchantDateRangeIntervals(merchant)}
                    selectedInterval={MakeMerchantAllTimeInterval(merchant)}
                    onChange={() => this.applyFilters()}
                />
            </BlinkInputField>
        ];
    }

    getAudienceMemberForMerchantUser(merchantUser) {
        // TODO wow, so inefficient. Need to create indexes in a store. Use the FK to the merchantUser
        const audienceMember = MerchantAudienceMemberStore.findBy({audienceId: this.options.audience.id, userId: merchantUser.userId});

        // Return a fake object, in case there is not DB object for this audience member
        return audienceMember || new MerchantAudienceMember({
            isFake: true,
            merchantId: merchantUser.merchantId,
            audienceId: this.options.audience.id,
            status: MerchantAudienceMemberStatus.AUTO_SUBSCRIBED,
        });
    }

    async editAudienceMemberStatus(memberEmail, subscriptionStatus) {
        return apiMerchantEditAudienceMembers({
            audienceId: this.options.audience.id,
            status: subscriptionStatus,
            overwriteExistingMembers: true,
            members: [{email: memberEmail}],
            includeState: true,
        });
    }

    async unsubscribeUser(memberEmail) {
        const confirm = await ConfirmationModal.prompt({
            title: "Unsubscribe user",
            description: "Mark this user to not receive any emails from this audience, even if they are included in the membership filters."
        });
        if (!confirm) {
            return;
        }
        await this.editAudienceMemberStatus(memberEmail, MerchantAudienceMemberStatus.MANUALLY_UNSUBSCRIBED);
    }

    columnManager = new DashboardTableColumnManager("audience-members-table-columns", [
        // Columns are based on the merchantUser, as the audience member might be filter-generated
        // TODO: entries should have an audienceMember-like object, with a link to the merchatUser
        ["Email", merchantUser => MakeEmailEntry(merchantUser)],
        // ["Source", audienceMember]
        ["Status", merchantUser => {
            const audienceMember = this.getAudienceMemberForMerchantUser(merchantUser);
            return AudienceMemberStatus(audienceMember.status.value);
        }],
        ["Subscribed at", merchantUser => {
            const audienceMember = this.getAudienceMemberForMerchantUser(merchantUser);
            return !audienceMember.isFake && audienceMember.subscribedAt;
        }],
        ["Unsubscribed at", merchantUser => {
            const audienceMember = this.getAudienceMemberForMerchantUser(merchantUser);
            return !audienceMember.isFake && audienceMember.unsubscribedAt;
        }],
        ["Actions", merchantUser => {
            const audienceMember = this.getAudienceMemberForMerchantUser(merchantUser);
            const email = merchantUser.getEmail();
            if (audienceMember.isSubscribed()) {
                return <Button onClick={() => this.unsubscribeUser(email)}>Unsubscribe</Button>
            }
        }],
    ], {
        tablePage: this,
        metadataGetter: (merchantUser, metadataKey) => this.getAudienceMemberForMerchantUser(merchantUser)?.conversion?.metadata?.[metadataKey],
    });
    groupsManager = new AggregateDataViewManager([
        new Annotation("Last subscribe", "last_subscribe", "max", "subscribed_at", value => new StemDate(value)),
        new Annotation("Last unsubscribe", "last_unsubscribe", "max", "unsubscribed_at", value => new StemDate(value)),
    ], [
        new GroupByField("Status", "status", value => AudienceMemberStatus(value)),
    ]);
}


@autoredraw
export class MerchantAudienceInstancePage extends UI.Element {
    getAudienceFiltersPanel() {
        const {audience} = this.options;

        // TODO add a component with clear changes, save changes, and Changes not saved (copy CSA?)
        return <Panel title="Membership filters">
            Audience members can be either explicitly added (by themselves or by you), or added via membership rules.
            <div>
                <Button onClick={() => this.resetAudienceFilters()} level={Level.SECONDARY} label="Clear changes"/>
                <Button onClick={() => this.saveAudienceFilters()} label="Save filters"/>
            </div>
            <MerchantAudienceMembershipFilters initialValue={audience.filters} audience={audience}
                                               ref="membershipFiltersInput"/>
        </Panel>
    }

    resetAudienceFilters() {
        const {audience} = this.options;
        this.membershipFiltersInput.setValue(audience.filters);
        this.redraw();
    }

    async saveAudienceFilters() {
        const {audience} = this.options;
        const filters = this.membershipFiltersInput.getValue();

        const response = await apiMerchantEditAudience({
            filters,
            audienceId: audience.id,
            merchantId: audience.merchantId,
        });
    }

    async saveSettings() {
        const {audience} = this.options;
        const request = {
            audienceId: audience.id,
            merchantId: audience.merchantId,
            ...this.settingsInput.getValue(),
        }

        return await apiMerchantEditAudience(request);
    }

    render() {
        const {audience, merchant} = this.options;
        return [
            // TODO @branch See if we can fix this pattern of needing to pass in the parent, have the parent always render a title
            this.options.parent.makeTitle(audience.name, null),
            <TopLevelTabArea style={{marginTop: 8}}>
                <MerchantAudienceInstanceMembersSection audience={audience} merchant={merchant} title="Members"/>
                {this.getAudienceFiltersPanel()}
                <Panel title="Settings">
                    <div>
                        <Button onClick={() => this.saveSettings()} label="Save"/>
                    </div>
                    <MerchantAudienceInput ref="settingsInput" audience={audience}/>
                </Panel>
                <AudienceImportElement audience={audience} title="Import members"/>
            </TopLevelTabArea>
        ]
    }
}
