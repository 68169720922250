import {UI} from "../../stem-core/src/ui/UIBase";
import {EndpointPaginator} from "../../client/state/EndpointPaginator";
import {CollapsiblePanel} from "../../stem-core/src/ui/collapsible/CollapsiblePanel";
import {SimpleTable} from "../ui/SimpleTable";
import {Button} from "../../stem-core/src/ui/button/Button";
import {UserDetailsModal} from "./UserDetails";
import {apiMerchantCreateReport, MerchantReportStore} from "../../client/state/merchant/MerchantReportStore";
import {Toast} from "../../blinkpay/ui/Toast";
import {Messages} from "../../blinkpay/Messages";
import {DashboardPageStyle} from "./theme/DashboardTheme";
import {DashboardTitle} from "./DashboardTitle";
import {merchantService} from "../misc/MerchantService";
import {CallThrottler, cleanObject} from "../../stem-core/src/base/Utils";
import {Dispatchable} from "../../stem-core/src/base/Dispatcher";
import {ColumnHandler} from "../../stem-core/src/ui/table/Table";
import {FilterFunnelSVG, GearIcon} from "../SVGElements";
import {LabeledCheckbox} from "../../blinkpay/ui/Checkbox";
import {makeMetadataColumnFunction, makeMetadataColumnName, AggregateDataFilters} from "./AggregateDataViewManager";
import {MerchantReportProgressModal} from "./modals/MerchantReportProgressModal";
import {TopLevelTabArea} from "./theme/TabAreaStyle";
import {BaseEnum, makeEnum} from "../../client/state/misc/BaseEnum";
import {DashboardAnchoredPopup} from "../ui/input/PopupInput";


class MetadataColumnHandler extends ColumnHandler {
    constructor(key, headerName, value, tablePage) {
        super({
            rawHeaderName: headerName,
            headerName: () => this.renderHeader(),
            rawValue: value,
            value: entry => this.renderEntry(entry),
            key,
            tablePage,
        });
    }

    getFilterValue() {
        return this.filterValue;
    }

    setFilterValue(value) {
        this.filterValue = value;
        this.tablePage?.applyFilters();  // Triggering the request
    }

    renderHeader() {
        const headerName = this.rawHeaderName;
        const filterValue = this.getFilterValue();
        if (!filterValue) {
            return headerName;
        }
        return [headerName, " ", <FilterFunnelSVG title={"Click to remove filter by " + filterValue}
                                                  size={20} onClick={() => this.setFilterValue(null)}/>];
    }

    renderEntry(entry) {
        const rawValue = this.rawValue(entry);
        const {tablePage} = this;
        if (!rawValue || this.getFilterValue() || !tablePage) {
            return rawValue;
        }

        // TODO @branch Add a filter info on hover, and do this on double-click
        return <div onDoubleClick={() => this.setFilterValue(rawValue)} title={"Double-click to filter by value"}
                    style={{cursor: "help"}}>
            {rawValue}
        </div>
    }
}


export class DashboardTableColumnManager extends Dispatchable {
    metadataColumnMap = new Map();

    constructor(localStorageKey, columnDefinitions, {metadataGetter = null, tablePage = null} = {}) {
        super();
        this.tablePage = tablePage;
        this.localStorageKey = localStorageKey;
        this.columnDefinitions = columnDefinitions;
        this.metadataKeys = [];
        let storedSelection;
        try {
            storedSelection = merchantService.getLocalStorageMap().get(localStorageKey);
        } catch (e) {
            storedSelection = {};
        }
        this.selectedColumns = new Set(storedSelection?.selectedColumns ?? this.columnDefinitions.map((columnDef, index) => index));
        this.selectedMetadataColumns = new Set(storedSelection?.selectedMetadataColumns ?? []);
        this.metadataGetter = metadataGetter || ((entry, key) => entry?.conversion?.metadata?.[key]);
    }

    toggleSelectedColumn(index) {
        if (this.selectedColumns.has(index)) {
            this.selectedColumns.delete(index);
        } else {
            this.selectedColumns.add(index);
        }
        this.saveSelectionToStorage();
    }

    toggleSelectedMetadataColumn(key) {
        if (this.selectedMetadataColumns.has(key)) {
            this.selectedMetadataColumns.delete(key);
        } else {
            this.selectedMetadataColumns.add(key);
        }
        this.saveSelectionToStorage();
    }

    makeMetadataColumn(key) {
        // We want to cache them, to preserve filters in the ColumnHandler
        if (!this.metadataColumnMap.has(key)) {
            const metadataColumnFunction = makeMetadataColumnFunction(key);
            this.metadataColumnMap.set(key, new MetadataColumnHandler(
                key,
                makeMetadataColumnName(key),
                (entry) => metadataColumnFunction(this.metadataGetter(entry, key)),
                this.tablePage,
            ));
        }
        return this.metadataColumnMap.get(key);
    }

    getMetadataFilters() {
        let filters = {};
        for (const [key, columnHandler] of this.metadataColumnMap.entries()) {
            if (columnHandler.filterValue) {
                filters[key] = columnHandler.filterValue;
            }
        }
        return filters;
    }

    getColumns() {
        return [
            ...this.columnDefinitions.filter((columnDef, index) => this.selectedColumns.has(index)),
            ...[...this.selectedMetadataColumns].map(metadataKey => this.makeMetadataColumn(metadataKey)),
        ];
    }

    saveSelectionToStorage() {
        merchantService.getLocalStorageMap().set(this.localStorageKey, {
            selectedColumns: [...this.selectedColumns],
            selectedMetadataColumns: [...this.selectedMetadataColumns],
        })
        this.dispatch("update");
    }
}


export function MakeEmailEntry(merchantUser) {
    return <div className={DashboardPageStyle.getInstance().userEmail}
                onClick={() => UserDetailsModal.show({merchantUser})}>
        {merchantUser.getEmail()}
    </div>
}


class TableSettingsInput extends DashboardAnchoredPopup {
    render() {
        const {columnManager} = this.options;

        return (
            <div style={{display: "flex", padding: 8}}>
                <div>
                    Columns
                    {columnManager.columnDefinitions.map(([title, entry], index) =>
                        <div>
                            <LabeledCheckbox initialValue={columnManager.selectedColumns.has(index)} label={title}
                                         onChange={() => columnManager.toggleSelectedColumn(index)}/>
                        </div>
                    )}
                </div>
                {columnManager.metadataKeys.length > 0 && <div style={{paddingLeft: 8}}>
                    Metadata columns
                    {columnManager.metadataKeys.map((key) =>
                        <div>
                            <LabeledCheckbox initialValue={columnManager.selectedMetadataColumns.has(key)}
                                             onChange={() => columnManager.toggleSelectedMetadataColumn(key)}
                                             label={makeMetadataColumnName(key)}/>
                        </div>
                    )}
                </div>}
            </div>
        )
    }
}

class ReportTable extends SimpleTable {
    requestTableSettings(anchor) {
        const {columnManager} = this.options;
        TableSettingsInput.show({columnManager, anchor});
    }

    getHeaderAction() {
        const elements = [];
        if (this.options.columnManager) {
            elements.push(<GearIcon size={20} style={{cursor: "pointer"}} onClick={(event, element) => this.requestTableSettings(element)} />);
        }
        const {exportCallback} = this.options;
        if (exportCallback) {
            elements.push(
                <Button onClick={exportCallback}>Export to CSV</Button>,
                <span style={{verticalAlign: "center"}}>Export all filtered data.</span>
            );
        }
        return elements;
    }
}


// This should be a multiple inheritance from TabArea and DashboardBaseInput, but for now it's quite simple
export class FlatTabInput extends TopLevelTabArea {
    render() {
        const {options} = this.options;
        const currentValue = this.getValue();
        return options.map(option => <div title={option} active={option === currentValue} value={option}/>);
    }

    onSetActive(panel) {
        super.onSetActive(panel);
        const newValue = panel.options.value;
        if (newValue !== this.getValue()) {
            this.value = newValue;
            this.dispatch("change", newValue, this);
        }
    }

    getValue() {
        return this.value || this.options.options[0];
    }
}


@makeEnum
class DashboardTablePageMode extends BaseEnum {
    static INDIVIDUAL;
    static AGGREGATE;
}


export class DashboardTablePage extends UI.Element {
    filtersThrottler = new CallThrottler({debounce: 200});
    applyFilters = this.filtersThrottler.wrap(() => {
        const filters = this.getFilters();

        this.getPaginator().updateFilter(filters);
    });
    groupsManager = null;
    columnManager = null;

    getMerchant() {
        return this.options.merchant;
    }

    getCurrency() {
        return this.getMerchant().getCurrency();
    }

    getViewMode() {
        return this.viewModeInput?.getValue() || DashboardTablePageMode.INDIVIDUAL;
    }

    isInAggregateView() {
        return this.getViewMode() === DashboardTablePageMode.AGGREGATE;
    }

    getPaginator() {
        const {store, endpoint, paginatorOptions} = this.options;
        this.paginator = this.paginator || new EndpointPaginator(store, endpoint, {
            ...paginatorOptions,
        });
        return this.isInAggregateView() ? this.groupsManager.getPaginator(endpoint, paginatorOptions) : this.paginator;
    }

    getTitleSection() {
        const {title, description} = this.options;
        return title && <DashboardTitle title={title} description={description}/>
    }

    getFilterSection() {
    }

    // If the paginator for the current view doesn't have a last response, fall back
    // to the one in the default paginator.
    getSummaryLastResponse() {
        return this.getPaginator().getLastResponse() || this.paginator.getLastResponse();
    }

    getFilters() {
        return {};
    }

    summaryContent(summaryLastResponse) {
    }

    summarySection() {
        const summaryLastResponse = this.getSummaryLastResponse();
        if (!summaryLastResponse) {
            return;
        }
        const content = this.summaryContent(summaryLastResponse);
        if (!content) {
            return;
        }
        return <CollapsiblePanel
            collapsed={false}
            style={{marginTop: 20}}
            title="Summary & Statistics">
            {content}
        </CollapsiblePanel>
    }

    getColumns() {
        return this.columnManager?.getColumns() || [];
    }

    async generateReport() {
        const {merchant, reportType} = this.options;
        const filters = this.getFilters();
        try {
            const response = await apiMerchantCreateReport(merchant.id, reportType, filters);
            const merchantReport = MerchantReportStore.loadObjectFromResponse(response);
            if (merchantReport) {
                MerchantReportProgressModal.show({merchantReport});
            } else {
                throw "Unknown error in generating report";
            }

        } catch (error) {
            Toast.showError(Messages.reportNotGenerated, {visibleTimeout: 6000});
            throw error;
        }
    }

    entriesTable() {
        const currentViewMode = this.getViewMode();
        const commonTableOptions = {
            paginator: this.getPaginator(),
            noAutofetch: true,
        }

        if (this.isInAggregateView()) {
            return [
                <AggregateDataFilters groupsManager={this.groupsManager}/>,
                <ReportTable
                    {...commonTableOptions}
                    columns={this.groupsManager.getTableColumns()}
                />
            ]
        }

        return (
            <ReportTable
                {...commonTableOptions}
                columnManager={this.columnManager}
                exportCallback={this.options.reportType && (() => this.generateReport())}
                columns={this.getColumns()}
            />
        );
    }

    render() {
        return [
            this.getTitleSection(),
            this.getFilterSection(),
            this.summarySection(),
            this.groupsManager && <FlatTabInput
                ref="viewModeInput"
                options={DashboardTablePageMode.all()}
                onChange={(value) => this.redrawAndApplyFilters()}
            />,
            this.entriesTable(),
        ];
    }

    redrawAndApplyFilters() {
        this.redraw();
        this.applyFilters();
    }

    onMount() {
        // For the first page load, also load the metadata keys.
        this.attachListenerOnce(this.getPaginator(), "pageLoaded", (results, response) => {
            if (response && response.allMetadataKeys) {
                if (this.columnManager) {
                    this.columnManager.metadataKeys = response.allMetadataKeys;
                }
                if (this.groupsManager) {
                    this.groupsManager.addMetadataKeys(response.allMetadataKeys);
                }
            }
        });
        this.attachListener(this.paginator, "pageLoaded", () => this.redraw());
        this.applyFilters();
        if (this.columnManager) {
            this.attachUpdateListener(this.columnManager, () => this.redraw());
        }
        if (this.groupsManager) {
            this.attachUpdateListener(this.groupsManager, () => this.redraw());
        }
    }
}
