import {merchantVariableModule} from "./merchant-state/MerchantVariableModule";
import {merchantFunctionModule} from "./merchant-state/MerchantFunctionModule";
import {isPlainObject, isString} from "../../stem-core/src/base/Utils";

export function evaluateContextTemplate(context) {
    return {
        ...merchantVariableModule.all(),
        ...merchantFunctionModule.all(),
        ...context,
    };
}

function objectToCodeValue(obj) {
    if (isString(obj)) {
        return obj;
    }
    return "{" + Object.keys(obj).map(key => `"${key}": ${objectToCodeValue(obj[key])}`).join(",") + "}";
}

function makeVarDeclarations(flatObject, keyTransform) {
    // We un-flatten the context variables into an object (e.g. {"namespace.variable": 5}
    // becomes {namespace: {variable: 5}}) so expressions such as "namespace.variable >= 4"
    // work well when executed using eval or new Function(code).
    const unFlattenedObject = {};
    // Sorted in ascending order by length so if a key is
    // a prefix of another, the prefix comes first. This
    // way, if the object contains both "a" and "a.b",
    // "a.b" can be applied after "a".
    const flatKeys = Object.keys(flatObject).sort((a, b) => a.length - b.length);
    for (const flatKey of flatKeys) {
        const keychain = flatKey.split(".");
        let currentObj = unFlattenedObject;
        for (let i = 0; i + 1 < keychain.length; i++) {
            if (!isPlainObject(currentObj[keychain[i]])) {
                currentObj[keychain[i]] = {};
            }
            currentObj = currentObj[keychain[i]];
        }
        currentObj[keychain[keychain.length - 1]] = keyTransform(flatKey);
    }
    // now we need to stringify the unFlattenedObject
    // Sorted in ascending order by length so if a key is
    // a prefix of another, the prefix comes first. This
    // way, if the context contains both "a" and "a.b",
    // "a.b" can be applied after "a".
    const keys = Object.keys(unFlattenedObject).sort((a, b) => a.length - b.length);
    if (keys.length === 0) {
        return "";
    }
    return keys.map(key => `${key} = ${objectToCodeValue(unFlattenedObject[key])}`);
}

export function makeExpressionEvaluator(variableValues) {
    const variableDeclarations = makeVarDeclarations(variableValues, key => `Blink$Variables["${key}"]`);
    let functionPreamble =`"use strict";\n`;
    if (variableDeclarations.length > 0) {
        functionPreamble += `var ${variableDeclarations.join(",\n  ")};\n`;
    }
    return expr => {
        const functionCode = functionPreamble + "return " + expr;
        try {
            // This uses a Function constructor instead of eval, to not expose any internals of the
            // SDK to the merchant-provided function. It still acts as a global function (has access
            // to "window" and the publisher's JS runtime).
            return (new Function("Blink$Variables", functionCode))(variableValues);
        } catch (error) {
            merchantFunctionModule.handleException(error, null, functionCode);
            return null;
        }
    };
}

export function evaluateTemplate(template, context) {
    if (!template) {
        return template;
    }
    const evaluateExpr = makeExpressionEvaluator(evaluateContextTemplate(context));
    const evaluateNode = contentNode => {
        if (isString(contentNode)) {
            if (contentNode.startsWith("expr:")) {
                return evaluateExpr(contentNode.substr(5));
            }
            return contentNode.replace(/{{([^{}]*)}}/g, (match, expr) => String(evaluateExpr(expr) ?? "").toString());
        }
        if (Array.isArray(contentNode)) {
            return contentNode.map(evaluateNode);
        }
        if (!isPlainObject(contentNode)) {
            return contentNode;
        }
        // Evaluate special nodes
        if (isString(contentNode.tag) && contentNode.tag.toLowerCase() === "if") {
            if (evaluateExpr(contentNode.expr)) {
                return evaluateNode(contentNode.then || contentNode.children);
            } else {
                return evaluateNode(contentNode.else);
            }
        }
        const processedNode = {};
        for (const key of Object.keys(contentNode)) {
            processedNode[key] = evaluateNode(contentNode[key]);
        }
        return processedNode;
    };

    return evaluateNode(template);
}

