import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../misc/StoreField";
import {apiClient} from "../../connection/BlinkApiConnection";
import {BaseEnum, makeEnum} from "../misc/BaseEnum";
import {formatPercentFromRatio} from "../../../dashboard/common/Utils";

@makeEnum
export class MerchantEmailCampaignStatus extends BaseEnum {
    static UNSCHEDULED;
    static VALIDATION_FAILED;
    static SCHEDULED;
    static STARTED;
    static STOPPED;
    static FINISHED;
}

export class EmailSendingStoreObject extends StoreObject {
    @field(Number) numSentEmails;
    @field(Number) numReadEmails;
    @field(Number) numBouncedEmails;
    @field(Number) numFailedEmails;
    @field(Number) totalNumReads;

    getAllFailed() {
        return this.numBouncedEmails + this.numFailedEmails;
    }

    formatOpenPercent() {
        return formatPercentFromRatio(this.numReadEmails, this.numSentEmails);
    }
}

class MerchantEmailCampaign extends EmailSendingStoreObject {
    @field("Merchant") merchant;
    @field("MerchantAudience") audience;
    @field(String) name;
    @field(Date) createdAt;
    @field(Date) scheduledSendDate;

    getAllEmailTemplates() {
        return this.getStore("EmailTemplate").filterBy({campaignId: this.id});
    }
}

export const MerchantEmailCampaignStore = MakeStore("MerchantEmailCampaign", MerchantEmailCampaign);

export async function apiMerchantCreateEmailCampaign(request) {
    const response = await apiClient.post("/merchant/create_email_campaign/", request);
    return MerchantEmailCampaignStore.loadObjectFromResponse(response);
}

export async function apiMerchantEditEmailCampaign(request) {
    return apiClient.post("/merchant/edit_email_campaign", request);
}

export async function apiMerchantDeleteEmailCampaign(request) {
    return apiClient.post("/merchant/delete_email_campaign", request);
}
