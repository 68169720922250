import {UI} from "../../../stem-core/src/ui/UIBase";
import {
    apiMerchantCreateAudience,
    apiMerchantDeleteAudience, AudienceNewsletterFrequency,
    MerchantAudienceStore
} from "../../../client/state/merchant/MerchantAudienceStore";
import {merchantService} from "../../misc/MerchantService";
import {SimpleTable, NoClickPropagate} from "../../ui/SimpleTable";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {CENTER_COLUMN, LEFT_COLUMN} from "../../common/theme/TableStyle";
import {MerchantAudienceCreateDialog} from "./MerchantAudienceDialog";
import {MerchantAudienceInstancePage} from "./MerchantAudienceInstancePage";
import {StemDate} from "../../../stem-core/src/time/Date";
import {MerchantAudienceSize} from "./MerchantAudienceInstancePage";
import {DashboardSelectorPage} from "../../common/DashboardSelectorPage.jsx";


@autoredraw(MerchantAudienceStore)
export class MerchantAudiencesPage extends DashboardSelectorPage {
    getDefaultOptions() {
        return {
            store: MerchantAudienceStore,
            title: "Email Audiences",
            description: "Segment your users and create email newsletters. Public newsletters are subscribable by users.",
        }
    }

    getMerchant() {
        return merchantService.getMerchant();
    }

    async createAudience() {
        const request = await MerchantAudienceCreateDialog.prompt();
        if (!request) {
            return;
        }
        let defaultSendTime = new StemDate();
        defaultSendTime.setHours(8, 0, 0, 0);

        const response = await apiMerchantCreateAudience({
            ...request,
            isPublic: false,
            defaultFrequency: AudienceNewsletterFrequency.CUSTOM,
            defaultFrequencyOffsets: [],
            defaultSendTime,
            merchantId: this.getMerchant().id,
        });

        const newAudience = MerchantAudienceStore.loadObjectFromResponse(response);
        if (newAudience) {
            this.goToEntry(newAudience);
        }
    }

    async deleteAudience(audience) {
        const confirm = await ConfirmationModal.prompt({
            title: "Confirm audience deletion",
            description: "Warning: deleting the audience will lose all subscribers to it and is irreversible.",
            confirmLabel: "Delete audience"
        });
        if (!confirm) {
            return;
        }
        return await apiMerchantDeleteAudience({
            merchantId: this.getMerchant().id,
            audienceId: audience.id,
        });
    }

    render() {
        if (this.selectedEntry) {
            return <MerchantAudienceInstancePage parent={this} audience={this.selectedEntry} merchant={this.options.merchant} />;
        }

        const merchant = this.getMerchant();
        const audiences = MerchantAudienceStore.filterBy({merchantId: merchant.id}).sort((a, b) => b.id - a.id);
        const columns = [
            ["Id", audience => audience.id, LEFT_COLUMN],
            ["Name", audience => audience.name, LEFT_COLUMN],
            ["Is Public Newsletter", audience => audience.isPublic ? "Yes" : "No", CENTER_COLUMN],
            ["Autosubscribe", audience => audience.autosubscribeNewUsers ? "Yes" : "No", CENTER_COLUMN],
            ["Audience size", audience => <MerchantAudienceSize audience={audience}/>, CENTER_COLUMN],
            ["Actions", audience => {
                return <NoClickPropagate>
                    <Button onClick={() => this.deleteAudience(audience)} label="Delete" />
                </NoClickPropagate>
            }],
        ]

        return [
            this.makeTitle(),
            <div style={{paddingBottom: 12}}>
                <Button
                    onClick={() => this.createAudience()}
                    label="New Audience"
                />
            </div>,
            this.makeTable(audiences, columns),
        ]
    }
}
