import {UI, StyleSheet, styleRule, registerStyle} from "../../../stem-core/src/ui/UI";

import {DividerPositions, List} from "../../../blinkpay/ui/List";
import {LoadingSpinner} from "../../../blinkpay/ui/LoadingSpinner";
import {merchantService} from "../../misc/MerchantService";
import {Messages} from "../../../blinkpay/Messages";
import {SearchInput} from "../../ui/input/SearchInput";
import {MerchantStore} from "../../../client/state/MerchantStore.js";


export class ChooseMerchantListStyle extends StyleSheet {
    @styleRule
    option = {
        cursor: "pointer",
        fontSize: 16,
        width: this.themeProps.FIT_CONTENT,
        color: this.themeProps.LABEL,
        fontWeight: "normal",
    };

    @styleRule
    optionContainer = {
        padding: "12px 6px",
    };

    @styleRule
    searchBoxContainer = {
        marginBottom: 28,
        maxWidth: 244,
    };

    @styleRule
    listContainer = {
        height: 480,
        overflow: "auto",
    }
}

@registerStyle(ChooseMerchantListStyle)
export class ChooseMerchantList extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            nameFilter: "",
        };
    }

    changeMerchant(merchant) {
        LoadingSpinner.show();
        merchantService.setCurrentMerchantId(merchant.id);
        if (location.href.endsWith("/choose-merchant")) {
            // For some reason, this can be canceled in this event loop.
            location.assign("/");
        } else {
            location.reload();
        }
    }

    getOptionElement(merchant) {
        const {styleSheet} = this;

        return <div className={styleSheet.optionContainer}>
            <div className={styleSheet.option} onClick={() => this.changeMerchant(merchant)}>
                {merchant}
            </div>
        </div>
    }

    render() {
        const {styleSheet} = this;
        const {nameFilter} = this.options;
        const merchants = MerchantStore.all().sort((a,b) => {
            if (a.name < b.name) {
                return -1;
            }
            return 1;
        }).filter(merchant => {
            return merchant.name.toLowerCase().indexOf(nameFilter.toLowerCase()) >= 0;
        });

        return [
            <div className={styleSheet.searchBoxContainer}>
                <SearchInput ref="merchantSearch"
                             inputAttributes={{placeholder: Messages.searchMerchantByName}}
                             onChange={() => this.updateOptions({nameFilter: this.merchantSearch.getValue()})}/>
            </div>,
            <div className={styleSheet.listContainer}>
                <List dividerOptions={{position: DividerPositions.BOTTOM}}>
                    {merchants.map(merchant => this.getOptionElement(merchant))}
                </List>
            </div>
        ];
    }
}
