import {UI, StyleSheet, styleRule, registerStyle} from "../../../stem-core/src/ui/UI";

import {ChooseMerchantCard} from "./ChooseMerchantCard";
import {merchantService} from "../../misc/MerchantService";
import {LoadingSpinner} from "../../../blinkpay/ui/LoadingSpinner";

export class ChooseMerchantPageStyle extends StyleSheet {
    @styleRule
    center = {
        display: "flex",
        justifyContent: "center"
    }
}

@registerStyle(ChooseMerchantPageStyle)
export class ChooseMerchantPage extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Choose your merchant",
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.center);
    }

    render() {
        if (!merchantService.isMerchantDataRequestFinished()) {
            return [
                <div className={this.styleSheet.mainPage}>
                    <LoadingSpinner />
                </div>,
            ];
        }

        return <ChooseMerchantCard />;
    }
    onMount() {
        super.onMount();
        this.attachListener(merchantService, "merchantDataRequestFinished", () => {this.redraw()});
    }
}
