import {styleRule, styleRuleInherit, UI} from "stem-core/src/ui/UI";

import {Modal, ModalStyle} from "../../../blinkpay/ui/Modal";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {ChooseMerchantList} from "./ChooseMerchantList";


export class ChooseMerchantModalStyle extends ModalStyle {
    @styleRuleInherit
    modal = {
        width: 360,
        background: this.themeProps.CARD_BACKGROUND_COLOR,
        overflow: 'hidden',
        ' div[class*="title-"]': {
            color: this.themeProps.PAGE_TEXT_COLOR,
        },
    };
}

@registerStyle(ChooseMerchantModalStyle)
export class ChooseMerchantModal extends Modal {
    getDefaultOptions() {
        return Object.assign({}, super.getDefaultOptions(), {
            title: "Choose your merchant",
        });
    }

    render() {
        return <ChooseMerchantList />;
    }
}
