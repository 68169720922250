import {MakeStore, StoreObject} from "../../../../stem-core/src/state/Store";
import {field} from "../../misc/StoreField";
import {apiConnection} from "../../../connection/BlinkApiConnection";

class MerchantCDSPromoKeys extends StoreObject {
    @field("Merchant") merchant;
    @field("MerchantCDSProduct") product;
    @field("SubscriptionOffer") subscriptionOffer;
    @field(Array) promoKeys;
    @field(Boolean) allowUnverified;
}

export const MerchantCDSPromoKeysStore = MakeStore("MerchantCDSPromoKeys", MerchantCDSPromoKeys);

export async function apiMerchantEditCDSPromoKeys(request) {
    return apiConnection.post("/external_providers/edit_cds_promo_keys/", request);
}