import {MakeStore, StoreObject} from "../../../stem-core/src/state/Store";
import {field} from "../misc/StoreField";

export class AggregateDataGrouping extends StoreObject {
    @field(String) id;
    @field(Array) groupByFields;
    @field(Object) entry;
}

export const AggregateDataGroupingStore = MakeStore("AggregateDataGrouping", AggregateDataGrouping);
