import {Dispatchable} from "../../../../stem-core/src/base/Dispatcher.js";
import {PropertyCache} from "../../../../stem-core/src/data-structures/PropertyCache.js";

export class SDKResourceEditorState extends Dispatchable {
    static instanceCache = new PropertyCache("editorState");

    static instantiate(resource, rootElement) {
        const obj = this.instanceCache.get(resource, () => new this(resource));  // Getter must be here, since we're only used in inheritors
        obj.rootElement = rootElement;
        return obj;
    }

    dispatchChange() {
        this.dispatch("change", this);
    }

    setAdvancedMode(advancedMode) {
        this.advancedMode = advancedMode;
        this.dispatchChange();
        this.rootElement?.redraw();
    }

    discardChanges() {
        this.load();
        this.dispatchChange();
        this.rootElement?.redraw();
    }
}