import {JSONStateInput} from "../components/JSONStateInput.jsx";
import {styleRule, StyleSheet} from "../../../../stem-core/src/ui/Style.js";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme.js";
import {autoredraw} from "../../../../stem-core/src/decorators/AutoRedraw.js";
import {
    apiEditMerchantUserJourney,
    MerchantUserJourneyStore
} from "../../../../client/state/merchant/MerchantUserJourneyStore.js";
import {MerchantSDKSettingsStore} from "../../../../client/state/merchant/MerchantSDKSettingsStore.js";
import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {deepCopy} from "../../../../stem-core/src/base/Utils.js";
import {ToggleButton} from "../../../../blinkpay/ui/Button.jsx";
import {SaveOrDiscardButtons} from "../../../common/SaveOrDiscardButtons.jsx";
import {LoadingSpinner} from "../../../../blinkpay/ui/LoadingSpinner.jsx";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {JourneyEditorState} from "./JourneyEditorState.js";

class JourneyEditorStyle extends StyleSheet {
    @styleRule
    editorArea = {
        display: "flex",
    };

    @styleRule
    optionsEditorArea = {
        flex: 1,
        padding: "16px 8px",
    };
}

@registerStyle(JourneyEditorStyle)
@autoredraw(MerchantUserJourneyStore, MerchantSDKSettingsStore)
export class JourneyEditor extends UI.Element {
    setOptions(options) {
        super.setOptions(options);
        this.state = JourneyEditorState.instantiate(this.options.userJourney, this);
    }

    render() {
        const {userJourney} = this.options;

        return [
            this.options.parent.makeTitle(userJourney.name),
            <div style={{marginBottom: 12, fontSize: 16}}>
                Journey alias: <span style={{fontFamily: Theme.props.FONT_FAMILY_MONOSPACE}}>{userJourney.alias}</span>
            </div>,
            <div style={{display: "flex", alignItems: "center"}}>
                <span style={{display: "flex", paddingRight: 6}}>
                    <ToggleButton
                        active={this.state.advancedMode}
                        autoChange={false}
                        onToggle={(active) => this.state.setAdvancedMode(active)}/>
                </span> Advanced
                <SaveOrDiscardButtons state={this.state}/>
            </div>,
            <div className={this.styleSheet.editorArea}>
                <div className={this.styleSheet.optionsEditorArea}>
                    <JSONStateInput state={this.state}
                                    stateKey="journeyOptions"
                                    codeEditorHeight={600}/>
                </div>
            </div>,
        ];
    }
}