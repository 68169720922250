import {UI} from "../../../../stem-core/src/ui/UIBase";
import {ConfirmationModal, ConfirmationModalStyle} from "../../../../blinkpay/ui/ConfirmationModal";
import {BlinkInputField} from "../../../common/Input";
import {Input} from "../../../../stem-core/src/ui/input/Input";
import {Select} from "../../../../stem-core/src/ui/input/Input";
import {styleRule} from "../../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../../stem-core/src/ui/style/Theme";
import {NumberInput} from "../../../../stem-core/src/ui/input/Input";
import {BaseEnum, makeEnum} from "../../../../client/state/misc/BaseEnum";
import {TextArea} from "../../../../stem-core/src/ui/input/Input";


@makeEnum
export class FormFieldVariableType extends BaseEnum {
    static TEXT_LINE;
    static TEXT;
}

export class AddContextVariableModalStyle extends ConfirmationModalStyle {
    @styleRule
    formField = {
        width: "100%",
        ">:last-child": {
            width: "100%",
            height: 32,
        },
        " input:disabled": {
            opacity: 0.7,
            color: "rgb(170,170,170)",
        }
    };

    @styleRule
    row = {
        display: "flex",
    };
}

@registerStyle(AddContextVariableModalStyle)
export class AddContextVariableModal extends ConfirmationModal {
    confirmButtonDisabled = true;

    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "Add variable",
            confirmLabel: "Add",
        };
    }

    getValue() {
        let value = {
            key: this.keyInput.getValue(),
            description: this.descriptionInput.getValue(),
            type: this.typeInput.getValue().toString(),
        };
        if (value.type === FormFieldVariableType.TEXT_LINE.toString() && this.characterLimitInput.getValue()) {
            value.characterLimit = parseInt(this.characterLimitInput.getValue(), 10);
        }
        return value;
    }

    render() {
        const {styleSheet} = this;
        const onInputChange = () => {
            this.confirmButtonDisabled = !this.keyInput.getValue() || !this.typeInput.getValue();
            this.redraw();
        };

        return [
            <BlinkInputField label="Variable name" className={styleSheet.formField}>
                <Input ref="keyInput" onKeyUp={onInputChange} placeholder="ARTICLE_TITLE"/>
            </BlinkInputField>,
            <BlinkInputField label="Type" className={styleSheet.formField}>
                <Select ref="typeInput" options={FormFieldVariableType.all()} onChange={onInputChange}/>
            </BlinkInputField>,
            <BlinkInputField label="Description" className={styleSheet.formField}>
                <TextArea ref="descriptionInput" onKeyUp={onInputChange} style={{minHeight: 100}}/>
            </BlinkInputField>,
            (!this.typeInput || this.typeInput?.getValue() === FormFieldVariableType.TEXT_LINE) ?
                <BlinkInputField label="Character limit" className={styleSheet.formField}>
                    <NumberInput ref="characterLimitInput" onKeyUp={onInputChange}/>
                </BlinkInputField> : null
        ];
    }
}
