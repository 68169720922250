import {UI} from "../../../stem-core/src/ui/UIBase";
import {DashboardTablePage, MakeEmailEntry} from "../../common/DashboardTablePage";
import {MerchantReportType} from "../../../client/state/merchant/MerchantReportStore";
import {BlinkInputField} from "../../common/Input";
import {Money} from "../../../client/state/misc/Money";
import {InfoBlockGroup} from "../../common/InfoBlock";
import {CENTER_COLUMN, LEFT_COLUMN, MONEY_COLUMN} from "../../common/theme/TableStyle";
import {RadioButtons} from "../../../blinkpay/ui/Button";
import {UserSearchInput} from "../../ui/input/UserSearchInput.jsx";
import {MerchantUserStore} from "../../../client/state/merchant/MerchantUserStore";
import {Theme} from "../../../stem-core/src/ui/style/Theme";
import {DonationTypeFilter} from "../emailing/MerchantAudienceMembershipFilters";


function MakeRecurringDonationStatusEntry(recurringDonation) {
    if (!recurringDonation) {
        return "—"
    }

    const formattedPrice = <span><b>{recurringDonation.getPrice()}</b>/{recurringDonation.getShortenedFrequency()}</span>
    if (recurringDonation.isCanceled() || recurringDonation.isFinished()) {
        return <div style={{color: Theme.props.TEXT_SECONDARY_COLOR}}>Canceled {formattedPrice}</div>
    }

    if (recurringDonation.isUnpaid()) {
        return <div style={{color: Theme.props.ERROR}}>Failed {formattedPrice}</div>
    }

    return <div>Active {formattedPrice}</div>
}

export class MerchantDonorsPage extends DashboardTablePage {
    getDefaultOptions() {
        return {
            title: "Donors",
            store: MerchantUserStore,
            endpoint: "/merchant/get_donors/",
            reportType: MerchantReportType.DONORS,
        }
    }

    getFilterSection() {
        return <div>
            <UserSearchInput
                ref="userSearchInput"
                onChange={() => this.applyFilters()}
            />

            <BlinkInputField label="Donation type">
                <RadioButtons values={[DonationTypeFilter.ALL, DonationTypeFilter.RECURRING, DonationTypeFilter.ONE_TIME]}
                              onSelect={() => this.applyFilters()}
                              ref="recurringFilter"/>
            </BlinkInputField>
        </div>
    }

    getFilters() {
        return {
            userFilter: this.userSearchInput.getValue(),
            isRecurring: this.recurringFilter.getValue().isRecurring(),
        }
    }

    summaryContent(summaryLastResponse) {
        const {count, totalAmount, distribution} = summaryLastResponse;

        return [
            <InfoBlockGroup label="Overview" entries={[
                ["donors", count],
                ["total lifetime revenue", new Money(totalAmount, this.getCurrency())],
            ]} />,

            <InfoBlockGroup label="Breakdown" entries={[
                ["one-time", distribution.oneTime || 0],
                ["recurring", distribution.recurring || 0],
                ["both", distribution.both || 0],
            ]} />,
        ];
    }

    getColumns() {
        return [
            ["Email", donor => MakeEmailEntry(donor), LEFT_COLUMN],
            ["Name", donor => donor.getName(), LEFT_COLUMN],
            ["Lifetime Donated", donor => donor.getLifetimeDonated(), MONEY_COLUMN],
            ["Recurring status", donor => MakeRecurringDonationStatusEntry(donor.latestRecurringDonation), CENTER_COLUMN],
            ["Last donation", donor => (
                <span>
                    <b>{donor.latestDonationPayment.getAmount()}</b> on {donor.latestDonationPayment.createdAt}
                </span>
            ), CENTER_COLUMN],
        ]
    }
}
