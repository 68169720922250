import {deepCopy} from "../../../../stem-core/src/base/Utils.js";
import {evaluatePanelOptions} from "../../../../blink-sdk/PanelOptionsLogic";
import {isDeepEqual} from "../../../../blinkpay/UtilsLib.js";
import {LoadingSpinner} from "../../../../blinkpay/ui/LoadingSpinner.jsx";
import {apiCreateMerchantPanel, apiEditMerchantPanel} from "../../../../client/state/merchant/MerchantPanelStore.js";
import {getMerchantPanelsForVersion} from "./Helpers";
import {SDKResourceEditorState} from "../components/SDKResourceEditorState.js";

export class PanelEditorState extends SDKResourceEditorState {
    constructor(panel) {
        super();
        this.panel = panel;
        this.advancedMode = false;
        this.load();
    }

    getInitialPanelOptions() {
        return deepCopy({}, this.panel.options);
    }

    getInitialDashboardOptions() {
        return deepCopy({formFields: [], variables: {}}, this.panel.dashboardOptions);
    }

    load() {
        this.panelOptions = this.getInitialPanelOptions();
        this.dashboardOptions = this.getInitialDashboardOptions();
    }

    getPreviewPanelOptions() {
        const {panelOptions, dashboardOptions} = this;
        const {merchant, settingsVersion} = this.panel;
        const mockedPanel = {
            ...this.panel, // Simulate the panel (needed for alias at least)
            id: 0, // We want a unique reference
            options: panelOptions,
            dashboardOptions: dashboardOptions,
        }
        const merchantPanels = [
            ...getMerchantPanelsForVersion(merchant, settingsVersion),
            mockedPanel,
        ];


        return evaluatePanelOptions({panel: mockedPanel.id}, merchantPanels, dashboardOptions?.variables);
    }

    isEdited() {
        return !isDeepEqual(this.dashboardOptions, this.getInitialDashboardOptions())
            || !isDeepEqual(this.panelOptions, this.getInitialPanelOptions());
    }

    async saveChanges() {
        const {panelOptions, dashboardOptions} = this;
        LoadingSpinner.show();
        try {
            if (this.panel.isMocked) {
                // This is a fake panel, create it instead of updating.
                this.panel = await apiCreateMerchantPanel({
                    sdkSettingsId: this.panel.settingsVersionId,
                    name: this.panel.name,
                    alias: this.panel.alias,
                    options: panelOptions,
                    dashboardOptions,
                });
            } else {
                this.panel = await apiEditMerchantPanel({
                    sdkSettingsId: this.panel.settingsVersionId,
                    panelId: this.panel.id,
                    options: panelOptions,
                    dashboardOptions,
                });
            }
        } finally {
            LoadingSpinner.hide();
        }

        this.discardChanges(); // Reload and dispatch change
    }
}