import {merchantSessionStorage} from "../modules/merchant-state/MerchantStorage";
import {MERCHANT_STORAGE_KEYS, UTM_PARAM_KEYS} from "../Constants";
import {getQueryParam} from "../../blinkpay/UtilsLib";

let cachedUTMParams = null;
let cachedReferrerUrl = null;

export function loadUTMParams() {
    if (cachedUTMParams == null) {
        const previousParams = merchantSessionStorage.getItem(MERCHANT_STORAGE_KEYS.utm) || {};
        cachedUTMParams = {};
        for (const param of UTM_PARAM_KEYS) {
            const value = getQueryParam(`utm_${param}`) ?? previousParams[param];
            if (value != null) {
                cachedUTMParams[param] = value;
            }
        }
        if (Object.keys(cachedUTMParams).length > 0) {
            merchantSessionStorage.setItem(MERCHANT_STORAGE_KEYS.utm, cachedUTMParams);
        }
    }
    return cachedUTMParams;
}

export function loadReferrerUrl() {
    if (cachedReferrerUrl == null) {
        cachedReferrerUrl = merchantSessionStorage.getItem(MERCHANT_STORAGE_KEYS.referrer) || document.referrer || "";
        if (!!cachedReferrerUrl) {
            merchantSessionStorage.setItem(MERCHANT_STORAGE_KEYS.referrer, cachedReferrerUrl);
        }
    }
    return cachedReferrerUrl || null;
}
