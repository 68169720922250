import {
    BLINK_API_URL,
    BLINK_VERSION_URL_PARAM,
    IS_PRODUCTION,
    PUBLIC_DATA_BASE_URL,
    PUBLIC_DATA_SOURCE_PARAM
} from "../Constants";
import {markPageLoadTiming, PAGE_LOAD_TIMING_MARKS} from "../utils/pageLoadTiming";
import {getQueryParam} from "../../blinkpay/UtilsLib";
import {OnceDispatcher} from "../messaging/OnceDispatcher.js";

let merchantId = null;
let publicData = null;
export const publicDataLoaded = new OnceDispatcher();

export function fetchPublicData(clientId) {
    const versionParam = getQueryParam(BLINK_VERSION_URL_PARAM);
    const shouldLoadFromAPI = !IS_PRODUCTION && (!PUBLIC_DATA_BASE_URL || getQueryParam(PUBLIC_DATA_SOURCE_PARAM) === "api");
    const publicDataURL = shouldLoadFromAPI ? (
        versionParam
            ? BLINK_API_URL + "/public_data/?clientId=" + clientId + "&versionKey=" + versionParam
            : BLINK_API_URL + "/public_data/?clientId=" + clientId
    ) : (
        versionParam
            ? PUBLIC_DATA_BASE_URL + "/public_data." + clientId + "." + versionParam + ".json"
            : PUBLIC_DATA_BASE_URL + "/public_data." + clientId + ".json"
    );

    const fetch = (url, errorCallback) => {
        let executedErrorCallback = false;
        const wrappedErrorCallback = () => {
            merchantId = null;
            publicData = null;
            if (executedErrorCallback) {
                return;
            }
            executedErrorCallback = true;
            errorCallback();
        };
        const xhr = new XMLHttpRequest();
        xhr.onerror = wrappedErrorCallback;
        xhr.onabort = wrappedErrorCallback;
        xhr.onreadystatechange = () => {
            if (xhr.readyState === XMLHttpRequest.DONE) {
                if (xhr.status < 300) {
                    try {
                        publicData = JSON.parse(xhr.responseText);
                    } catch {
                        wrappedErrorCallback();
                        return;
                    }
                    // Intentionally use == instead of === because the
                    // numeric ID might be a string (if it comes from the URL)
                    merchantId = getPublicDataStore("Merchant", null)
                        .find(merchant => merchant.id == clientId || merchant.alias == clientId).id;
                    if (!merchantId) {
                        console.error(`[BlinkSDK]: Unrecognized option clientId="${clientId}" provided to blinkSDK.init.`);
                        return;
                    }
                    markPageLoadTiming(PAGE_LOAD_TIMING_MARKS.publicData);
                    publicDataLoaded.dispatch(publicData);
                } else {
                    wrappedErrorCallback();
                }
            }
        };
        xhr.open("GET", url, true);
        xhr.send();
    };

    let retryTimeout = 1000;
    const errorCallback = () => {
        setTimeout(() => {
            if (retryTimeout < 8000) {
                retryTimeout *= 2;
            }
            fetch(publicDataURL, errorCallback);
        }, retryTimeout);
    };
    fetch(publicDataURL, errorCallback);
}

// Always use this, since the backend does not guarantee casing of store names
export function getPublicDataStore(objectType, merchantIdField = "merchantId") {
    for (const key of Object.keys(publicData && publicData.state || {})) {
        if (key.toLowerCase() === objectType.toLowerCase()) {
            const store = publicData.state[key] || [];
            // TODO @Mihai: Skip filtering by merchantIdField
            if (merchantIdField != null) {
                return store.filter(obj => obj[merchantIdField] == null || obj[merchantIdField] === merchantId);
            }
            return store;
        }
    }
    return [];
}

export function onPublicDataLoaded(callback) {
    return publicDataLoaded.addListenerOnce(callback);
}

export function isPublicDataLoaded() {
    return merchantId != null;
}

export function getMerchantId() {
    return merchantId;
}

export function getPublicData() {
    return publicData;
}

export function getMerchant() {
    return getPublicDataStore("Merchant", "id")[0] || null;
}

export function isMerchantActive() {
    return getMerchant()?.sdkStatus === "active";
}

export function getDefaultSDKOptions() {
    const settings = getPublicDataStore("MerchantSDKSettings")[0] || {};
    return (settings && settings.options) || {};
}

export function getMerchantUserJourney(aliasOrId) {
    return getPublicDataStore("MerchantUserJourney").find(uj => uj.id == aliasOrId || uj.alias == aliasOrId);
}

export function getMerchantPanels() {
    return getPublicDataStore("MerchantPanel");
}

export function getMerchantPanel(aliasOrId) {
    return getMerchantPanels().find(panel => panel.id == aliasOrId || panel.alias == aliasOrId);
}

export function getMerchantAudience(nameOrId) {
    return getPublicDataStore("MerchantAudience").find(audience => audience.id == nameOrId || audience.name == nameOrId);
}

export function getMerchantFacebookPixel() {
    return getPublicDataStore("MerchantFacebookPixel")[0];
}

// Useful for tests
export function setPublicDataState(merchant, state) {
    merchantId = merchant;
    publicData = {state};
}
