import {UI} from "../../../stem-core/src/ui/UIBase";
import {CommaSeparatedInput, ShippingPricesInput} from "../../common/input/ShippingPriceEditor";
import {BlinkInputField} from "../../common/Input";
import {DashboardMoneyInput} from "../../ui/input/DashboardMoneyInput";
import {BillingCycleInput} from "./BillingCycleInput";
import {RadioButtons} from "../../../blinkpay/ui/Button";
import {NumberInput} from "../../../stem-core/src/ui/input/Input";
import {
    apiMerchantDeleteSubscriptionOffer,
    apiMerchantEditBillingPlan,
    apiMerchantEditSubscriptionOffer,
    BillingPlanStore, SubscriptionOfferStore
} from "../../../client/state/SubscriptionOfferStore";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {Toast} from "../../../blinkpay/ui/Toast";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {DashboardSection} from "../../common/DashboardSection.jsx";
import {EditableObjectStatus, InputFieldRequiredType, VisibilityStatus} from "../../../client/state/misc/GenericEnums";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {Level} from "../../../stem-core/src/ui/Constants";
import {DashboardDescription} from "../../common/DashboardTitle";
import {MerchantCDSProductStore} from "../../../client/state/merchant/external-providers/MerchantCDSProductStore";
import {apiMerchantEditCDSPromoKeys, MerchantCDSPromoKeysStore} from "../../../client/state/merchant/external-providers/MerchantCDSPromoKeysStore";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {LoadingSpinner} from "../../../blinkpay/ui/LoadingSpinner";
import {LabeledCheckbox} from "../../../blinkpay/ui/Checkbox";


class TrialPeriodInput extends UI.Element {
    getValue() {
        return {
            freeTrialDays: this.numFreeTrialDaysInput.getValue(),
            trialPaymentMethodRequired: this.paymentMethodRequiredInput.getValue(),
        }
    }

    setValue(value) {
        const {freeTrialDays, trialPaymentMethodRequired} = value;
        this.enableTrialPeriodInput.setValue(freeTrialDays > 0);
        this.numFreeTrialDaysInput.setValue(freeTrialDays);
        this.paymentMethodRequiredInput.setValue(trialPaymentMethodRequired || InputFieldRequiredType.REQUIRED);
        this.recheckVisibility();
    }

    recheckVisibility() {
        // Keep the element in flow
        if (this.enableTrialPeriodInput.getValue()) {
            this.extraInputs.setStyle({opacity: 1});
        } else {
            this.extraInputs.setStyle({opacity: 0});
        }
    }

    render() {
        return [
            <LabeledCheckbox label={" Enable a trial period of "}
                onChange={() => this.recheckVisibility()}
                ref="enableTrialPeriodInput"/>,
            <NumberInput
                min={1} max={90}
                initialValue={7}
                ref="numFreeTrialDaysInput"
            />, " days.",
            <BlinkInputField label="Payment method for trial" ref="extraInputs">
                <RadioButtons
                    activeValue={InputFieldRequiredType.REQUIRED}
                    values={InputFieldRequiredType.all()}
                    ref="paymentMethodRequiredInput"/>
            </BlinkInputField>
        ]
    }

    onMount() {
        const {initialValue} = this.options;
        this.setValue(initialValue || {freeTrialDays: 0, trialPaymentMethodRequired: InputFieldRequiredType.REQUIRED});
    }
}

@autoredraw(SubscriptionOfferStore, BillingPlanStore)
export class SubscriptionOfferStatusSection extends DashboardSection {
    getDefaultOptions() {
        return {
            title: "Status"
        }
    }

    getStatusText() {
        const {status} = this.options.subscriptionOffer.billingPlan;
        if (status === EditableObjectStatus.DRAFT) {
            return [
                <div>This subscription plan is in a draft state.</div>,
                <div>It needs to be activated in order to create subscriptions of this type.</div>,
                <div>Once a subscription is activated billing amounts and frequency can no longer be edited.</div>
            ];
        }

        if (status === EditableObjectStatus.ACTIVE) {
            return [
                <div>This subscription plan is active, and new subscriptions of this type can be created.</div>,
                <div>You can disable it to not allow new subscriptions of this type to be created.</div>,
                <div>Disabled subscription plans can later be reactivated.</div>
            ];
        }
        return [
            <div>No new subscription with this plan can be created.</div>
        ];
    }

    async editStatus(status) {
        const {billingPlan} = this.options.subscriptionOffer;

        // Only active and inactive status can be set, DRAFT is system initialized.
        const confirmationModalOptions = (status === EditableObjectStatus.INACTIVE) ? {
            title: "Disable subscription plan?",
            description: "Once a subscription is made inactive, plans are still running but no new subscriptions can be created with this plan (not even by admins)."
        } : {
            title: "Activate subscription plan?",
            description: "This would allow new subscription with this plan to be created."
        }

        const confirm = await ConfirmationModal.prompt(confirmationModalOptions);

        if (!confirm) {
            return;
        }

        await apiMerchantEditBillingPlan({
            billingPlanId: billingPlan.id,
            status,
        });
    }

    async deleteSubscriptionOffer() {
        const {subscriptionOffer, parentPage} = this.options;
        const confirm = await ConfirmationModal.prompt({
            title: "Delete subscription plan?",
            description: "As this is a draft subscription plan, no active subscribers exist with this plan. It can be safely deleted."
        });
        if (!confirm) {
            return;
        }

        await apiMerchantDeleteSubscriptionOffer({
            subscriptionOfferId: subscriptionOffer.id,
        });

        parentPage.goToEntry(null);
    }

    async changeVisibility(visibility) {
        const {subscriptionOffer} = this.options;
        if (visibility === subscriptionOffer.visibility) {
            return;
        }

        const visibilityDescriptions = {
            [VisibilityStatus.PUBLIC]: "This offer will be displayed to users by default as one of the options in subscription flows, unless explicitly excluded from Panel options.",
            [VisibilityStatus.HIDDEN]: "This offer will not be displayed to users by default, but Panels can explicitly use this offer and users will be allowed to subscribe to it.",
            [VisibilityStatus.PRIVATE]: "This offer will not be displayed to users, and users will not be allowed to subscribe to it even if they know about its existence. Private offers should be used for internal record-keeping, custom manual gifts to specific users etc.",
        };

        const confirm = await ConfirmationModal.prompt({
            title: `Change offer visibility to ${visibility}?`,
            description: visibilityDescriptions[visibility],
        });
        if (confirm) {
            LoadingSpinner.show();
            try {
                await apiMerchantEditSubscriptionOffer({
                    subscriptionOfferId: subscriptionOffer.id,
                    visibility,
                })
            } finally {
                LoadingSpinner.hide();
            }
        }
    }

    render() {
        const {subscriptionOffer} = this.options;
        const {billingPlan} = subscriptionOffer;

        return [
            <div style={{display: "flex"}}>
                <DashboardDescription>
                    {this.getStatusText()}
                </DashboardDescription>
                <div>
                    {
                        billingPlan.status !== EditableObjectStatus.ACTIVE ?
                        <Button onClick={() => this.editStatus(EditableObjectStatus.ACTIVE)}>Activate</Button> :
                        <Button onClick={() => this.editStatus(EditableObjectStatus.INACTIVE)} level={Level.WARNING}>Deactivate</Button>
                    }
                    {
                        billingPlan.status === EditableObjectStatus.DRAFT &&
                        <Button onClick={() => this.deleteSubscriptionOffer()}>Delete</Button>
                    }
                </div>
            </div>,
            // TODO @Mihai use a method here
            (billingPlan.status === EditableObjectStatus.ACTIVE && !subscriptionOffer.deletedAt) && <div>
                <BlinkInputField label="Visibility">
                    <RadioButtons ref="visibilityInput"
                                  onSelect={(value) => this.changeVisibility(value)}
                                  values={VisibilityStatus.all()} activeValue={subscriptionOffer.visibility} />
                </BlinkInputField>
            </div>
        ]
    }
}

export class MerchantSubscriptionOfferEditor extends UI.Element {
    async saveChanges() {
        const {subscriptionOffer} = this.options;
        const {billingPlan} = subscriptionOffer;
        const editBillingPlanRequest = {
            billingPlanId: billingPlan.id,
            amount: this.basePriceInput.getValue(false),
            ...this.billingCycleInput.getValue(),
            minNumberOfCycles: this.minNumberOfCyclesInput.getValue(),
            maxNumberOfCycles: null, // TODO @Mihai implement
            // TODO @branch using enums???
            autorenewEditable: this.autorenewEditableInput.getValue() === "Editable",
            autorenewDefault: this.autorenewDefaultInput.getValue() === "Default on",
            ...this.trialPeriodInput.getValue(),
        };
        let editSubscriptionOfferRequest = {
            subscriptionOfferId: subscriptionOffer.id,
            title: this.nameInput.getValue(),
        };

        await Promise.all([
            apiMerchantEditBillingPlan(editBillingPlanRequest),
            apiMerchantEditSubscriptionOffer(editSubscriptionOfferRequest),
        ]);
    }

    async saveShippingPrices(shippingPrices) {
        const {billingPlan} = this.options.subscriptionOffer;
        const request = {
            billingPlanId: billingPlan.id,
            shippingPrices,
        }

        await apiMerchantEditBillingPlan(request);
    }

    getAddressSection() {
        const {subscriptionOffer} = this.options;
        if (!subscriptionOffer.coverage.requiresAddress) {
            return;
        }

        return (
            <DashboardSection
                title="Shipping Prices"
                description="These are added on top of the base plan price, and can be used to limit the scope of the delivery.
                If no valid shipping price is configured for an address, our system will not allow the subscription to go through.
                You can have multiple shipping price rules, and the first matching one is applied."
            >
                <ShippingPricesInput
                    currency={subscriptionOffer.billingPlan.currency}
                    initialValue={subscriptionOffer.billingPlan.getShippingPrices()}
                    onSave={value => this.saveShippingPrices(value)} />
            </DashboardSection>
        )
    }

    getCDSSection() {
        const {subscriptionOffer} = this.options;
        const cdsProducts = MerchantCDSProductStore.filterBy({merchantId: subscriptionOffer.merchantId, canMakeSubscriptions: true});
        if (cdsProducts.length == 0) {
            return;
        }

        return <MerchantCDSPromoKeysSection subscriptionOffer={subscriptionOffer} cdsProducts={cdsProducts} />
    }

    render() {
        const {subscriptionOffer, parentPage} = this.options;
        const {billingPlan} = subscriptionOffer;

        return [
            <div>
                <Button onClick={() => this.saveChanges()}>Save changes</Button>
            </div>,
            <SubscriptionOfferStatusSection parentPage={parentPage} subscriptionOffer={subscriptionOffer} />,
            // TODO: @branch change visibility and if users can subscribe to it.
            <DashboardSection
                title="Billing settings"
            >
                <div>
                    <BlinkInputField label={`Name (optional). If not empty overwrites the coverage name (${subscriptionOffer.coverage})`}>
                        <TextInput
                            initialValue={subscriptionOffer.title}
                            ref="nameInput"
                        />
                    </BlinkInputField>
                </div>

                <div>
                    <BlinkInputField label="Base price">
                        <DashboardMoneyInput
                            currency={billingPlan.currency}
                            initialValue={billingPlan.amount}
                            ref="basePriceInput"
                        />
                    </BlinkInputField>
                    <BlinkInputField label="Billing Cycle">
                        <BillingCycleInput initialValue={billingPlan} ref="billingCycleInput"/>
                    </BlinkInputField>
                </div>

                <TrialPeriodInput initialValue={billingPlan} ref="trialPeriodInput"/>
                <div>
                    <BlinkInputField label="Minimum contracted billing cycles">
                        <NumberInput
                            style={{width: 200}}
                            min={1} max={100}
                            initialValue={billingPlan.minNumberOfCycles}
                            ref="minNumberOfCyclesInput"/>
                    </BlinkInputField>
                </div>

                <div style={{display: "inline-flex", paddingTop: 12, paddingBottom: 12, height: 60, alignItems: "center"}}>
                    <div style={{whiteSpace: "nowrap"}}>
                        Auto-renew after minimum billing cycles
                    </div>
                    <RadioButtons style={{width: "initial", marginLeft: 20}}
                        activeValue={billingPlan.autorenewEditable ? "Editable" : "Uneditable"}
                        values={["Editable", "Uneditable"]}
                        ref="autorenewEditableInput"/>
                    <RadioButtons style={{width: "initial", marginLeft: 20}}
                        activeValue={billingPlan.autorenewDefault ? "Default on" : "Default off"}
                        values={["Default on", "Default off"]}
                        ref="autorenewDefaultInput"/>
                </div>
            </DashboardSection>,

            this.getAddressSection(),

            this.getCDSSection(),
        ]
    }
}

@autoredraw
export class MerchantCDSPromoKeysSection extends DashboardSection {
    getDefaultOptions() {
        return {
            title: "CDS Promotional Keys",
            description: "Control the default promo keys to be used for this subscription. These can be overwritten in individual flows.",
        }
    }

    getCDSProduct() {
        const {cdsProducts} = this.options;
        if (cdsProducts.length != 1) {
            return;
        }
        return cdsProducts[0];
    }

    async saveChanges() {
        const {subscriptionOffer} = this.options;
        const cdsProduct = this.getCDSProduct();

        const request = {
            merchantId: subscriptionOffer.merchantId,
            productId: cdsProduct.id,
            subscriptionOfferId: subscriptionOffer.id,
            promoKeys: this.promoKeysInput.getValue({emptyAsNull: false}),
            allowUnverified: this.allowUnverifiedInput.getValue(),
        }
        const response = await apiMerchantEditCDSPromoKeys(request);
    }

    render() {
        const {subscriptionOffer, cdsProducts} = this.options;
        if (!this.getCDSProduct()) {
            return [<div>
                !! CDS configuration error, invalid number of subscription products: {cdsProducts.length} ({cdsProducts.map(prod => prod.productId)})
            </div>
            ]
        }

        const cdsProduct = cdsProducts[0];
        const existingPromoKeys = MerchantCDSPromoKeysStore.findBy({productId: cdsProduct.id, subscriptionOfferId: subscriptionOffer.id}) || {
            isVirtual: true,
            product: cdsProduct,
            merchant: subscriptionOffer.merchant,
            subscriptionOffer,
            promoKeys: [],
            allowUnverified: false,
        };

        return [
            <div>
                <Button onClick={() => this.saveChanges()}>Save changes</Button>
            </div>,
            <div>
                <BlinkInputField label="Promo Keys (comma separated values, first is default)">
                    <CommaSeparatedInput ref="promoKeysInput" initialValue={existingPromoKeys.promoKeys}/>
                </BlinkInputField>
            </div>,
            <div>
                <LabeledCheckbox ref="allowUnverifiedInput" initialValue={existingPromoKeys.allowUnverified}
                                 label={"Allow user journeys to use promo keys outside this list."}/>
            </div>
        ]
    }
}
