import {MakeStore} from "../../../stem-core/src/state/Store";
import {field} from "../misc/StoreField";
import {apiConnection} from "../../connection/BlinkApiConnection";
import {BaseLogMessage} from "../misc/BaseLogMessage.js";
import {TaskStatus} from "../misc/GenericEnums.js";


class MerchantLogMessage extends BaseLogMessage {
    @field("User") assignedTo;
    @field(TaskStatus) status;
}

export const MerchantLogMessageStore = MakeStore("MerchantLogMessage", MerchantLogMessage);

export async function apiMerchantEditLogMessage(request) {
    return apiConnection.post("/merchant/edit_log_message/", request);
}

export async function apiMerchantDeleteLogMessage(request) {
    return apiConnection.post("/merchant/delete_log_message/", request);
}
