import {UI} from "../../../stem-core/src/ui/UIBase";
import {StyleSheet} from "../../../stem-core/src/ui/Style";
import {bytesToSize} from "../../../blinkpay/Utils";
import {CSVColumnMapper, CSVReader} from "../../../stem-core/src/base/CSV";
import {ArrayPaginator} from "../../../client/state/EndpointPaginator";
import {SimpleTable} from "../../ui/SimpleTable";
import {
    apiMerchantCreateAudienceMembers,
    MerchantAudienceMemberStatus
} from "../../../client/state/merchant/MerchantAudienceMemberStore";
import {FileInput, Select} from "../../../stem-core/src/ui/input/Input";
import {BlinkInputField} from "../../common/Input";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {sleepUntil} from "../../common/Utils";
import {StemDate} from "../../../stem-core/src/time/Date";
import {styleRule} from "../../../stem-core/src/decorators/Style";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {AudienceMemberImportStatus, AudienceImportStatusElement} from "./MerchantAudienceImportStatus";
import {LabeledCheckbox} from "../../../blinkpay/ui/Checkbox";


const MAX_CSV_FILE_SIZE = 10 << 20;

class AudienceMemberStatusSelect extends UI.Element {
    static ALL_OPTIONS = [
        // TODO make this a checkbox
        {value: MerchantAudienceMemberStatus.MANUALLY_UNSUBSCRIBED, toString: () => "Unsubscribed"},
        {value: MerchantAudienceMemberStatus.MANUALLY_SUBSCRIBED, toString: () => "Subscribed"},
    ];

    getValue() {
        return this.input?.getValue().value || MerchantAudienceMemberStatus.AUTO_UNSUBSCRIBED;
    }

    render() {
        return [
            <BlinkInputField label="Status in which to import users">
                <Select ref="input" options={this.constructor.ALL_OPTIONS} onChange={() => this.redraw()}/>
            </BlinkInputField>,
            (this.getValue() == MerchantAudienceMemberStatus.MANUALLY_SUBSCRIBED) && <div>
                Warning: Anti-spam legislation asks that you have the user's explicit permission to add them in the
                Subscribed state. Audience members that have explicitly unsubscribed themselves cannot the manually
                changed.
            </div>
        ]
    }
}


class AudienceImportElementStyle extends StyleSheet {
    @styleRule
    importMembersSection = {
        display: "flex",
        alignItems: "flex-end"
    }

    @styleRule
    singleMemberSelector = {
        marginBottom: 8,
    }

    @styleRule
    importMembersSeparator = {
        margin: 10,
    }
}

@registerStyle(AudienceImportElementStyle)
export class AudienceImportElement extends UI.Element {
    entries = null;
    columns = null;
    error = null;
    status = null;

    setError(error) {
        this.error = error;
        this.redraw();
    }

    setStatus(status) {
        this.status = status;
        this.redraw();
    }

    async loadFile() {
        this.paginator = null;
        this.headerRow = null;
        this.entries = null;
        this.csvColumnMapper = null;
        this.error = null;
        this.status = null;

        const file = this.fileInput.getFile();
        if (!file) {
            // TODO handle clearing the file, preserver the last one
            return;
        }
        if (file.size > MAX_CSV_FILE_SIZE) {
            this.setError(`File size too large: ${bytesToSize(file.size)}. Max accepted is ${bytesToSize(MAX_CSV_FILE_SIZE)}.`);
            return;
        }
        try {
            const fileContent = await file.text();
            const csvReader = new CSVReader();

            const csvRows = csvReader.readInput(fileContent);
            [this.headerRow, ...this.entries] = csvRows;
            this.csvColumnMapper = new CSVColumnMapper(this.headerRow, [
                [["email", "email_address", "email address", "email:"], {required: true}],
                ["name"],
            ]);
            if (this.entries.length == 0) {
                throw "No valid entries in file";
            }
        } catch (error) {
            this.setError(error);
            return;
        }

        this.paginator = new ArrayPaginator(this.entries, 5);
        this.redraw();
    }

    makeTable() {
        if (this.error || !this.csvColumnMapper) {
            return;
        }

        const columns = this.csvColumnMapper.columns.map(col => [
            col.originalName, entry => entry[col.index], {style: {width: 200, minWidth: 200}}
        ]);

        const numSkippedColumns = this.csvColumnMapper.numOriginalColumns() - this.csvColumnMapper.numColumns();

        // TODO have a checkbox that can toggle to show all the columns
        return [
            <div style={{width: "100%", maxHeight: 400}}>
                {(numSkippedColumns > 0) && <div style={{paddingTop: 12}}>Only showing relevant columns,
                    ignored {numSkippedColumns} column(s).</div>}
                <SimpleTable columns={columns} paginator={this.paginator}/>
            </div>
        ];
    }

    async importMembers() {
        const {audience} = this.options;
        const statusObj = new AudienceMemberImportStatus(this.entries.length);

        this.setStatus(statusObj);

        const requestPaginator = new ArrayPaginator(this.entries, 50);
        for (let pageIndex = 1; pageIndex <= requestPaginator.getNumPages(); pageIndex++) {
            if (!statusObj.isProcessing()) {
                // Someone stopped us
                return;
            }
            const startTime = new StemDate();
            const requestEntries = requestPaginator.fetchPage(pageIndex).map(entry => this.csvColumnMapper.toObject(entry));

            // Skip all the entries without a valid email
            const filteredEntries = requestEntries.filter(entry => entry.email);
            statusObj.missingEmailCount += requestEntries.length - filteredEntries.length;

            const request = {
                merchantId: audience.merchantId,
                audienceId: audience.id,
                status: this.getMemberStatusInputValue(),
                overwriteExistingMembers: this.overwriteExistingMembersInput.getValue(),
                createNewUsers: this.createUsersInput?.getValue(),
                members: filteredEntries,
            }
            try {
                const response = await apiMerchantCreateAudienceMembers(request);
                statusObj.addResponse({size: requestEntries.length}, response);
            } catch (error) {
                statusObj.setError(error);
                break;
            }
            await sleepUntil(startTime.add(200)); // Take at least 200 ms between requests
        }

        this.stopImporting(); // Just to clear the status
    }

    stopImporting() {
        this.status.setProcessing(false);
        this.redraw();
    }

    getMemberStatusInputValue() {
        return this.memberStatusInput?.getValue().value;
    }

    render() {
        const {styleSheet} = this;

        // TODO Add back in the previous add single user
        return [
            <div className={styleSheet.importMembersSection}>
                <BlinkInputField label="Open a csv or tsv file:">
                    <FileInput ref={"fileInput"}
                               multipleFiles={false}
                               fileTypes=".csv,.tsv"
                               onChange={() => this.loadFile()}
                    />
                </BlinkInputField>
{/*
                <div className={styleSheet.importMembersSeparator}>or</div>
                <UserSelector className={styleSheet.singleMemberSelector}
                              btnText="Add a single member"
                              displayLabelAfterSelect={false}
                              modalOptions={{
                                  allowUserCreation: true,
                                  paginatorStore: MerchantUserStore,
                                  paginatorEndpoint: "merchant/get_users/"
                              }}
                              onSelect={async (selectedUser) => {
                                  await apiMerchantCreateAudienceMembers({
                                      audienceId: this.options.audience.id,
                                      status: MerchantAudienceMemberStatus.MANUALLY_SUBSCRIBED,
                                      overwriteExistingMembers: true,
                                      members: [{email: selectedUser.email}]
                                  });
                                  Toast.show("Successfully added");
                              }}
                />
*/}
            </div>,
            this.makeTable(),
            this.error && <div style={{maxWidth: 375, paddingTop: 12, color: "red"}}>
                Error: {this.error}
            </div>,
            (!this.error && this.entries) && <div style={{paddingTop: 12}}>
                <div>
                    {/*<LabeledCheckbox ref="createUsersInput" initialValue={false} label="Create missing users"/> */}
                    <LabeledCheckbox ref="overwriteExistingMembersInput" initialValue={false}
                                     label="Overwrite existing audience members"/>
                    <AudienceMemberStatusSelect ref="memberStatusInput"/>
                </div>
                <div>
                    <Button
                        disabled={this.status?.isProcessing()}
                        label={`Import ${this.entries.length} entries`} onClick={() => this.importMembers()}/>
                    {this.status?.isProcessing() && <Button onClick={() => this.stopImporting()} label="Stop importing"/>}
                </div>

                {this.status && <AudienceImportStatusElement style={{paddingTop: 12}} status={this.status}/>}
            </div>
        ]
    }
}
