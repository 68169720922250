import {UI} from "../../stem-core/src/ui/UIBase";
import {LabeledCheckbox} from "../../blinkpay/ui/Checkbox";

export class MultiselectInput extends UI.Element {
    getAllOptions() {
        return this.options.options;
    }

    getValue() {
        return this.getAllOptions().filter((entry, index) => this["checkbox" + index].getValue());
    }

    setValue(values) {
        const valuesSet = new Set(values || []);
        this.getAllOptions().map((entry, index) => {
            this["checkbox" + index].setValue(valuesSet.has(entry));
        });
    }

    allSelected() {
        return this.getAllOptions().length === this.getValue().length;
    }

    render() {
        return this.getAllOptions().map((entry, index) => <div>
            <LabeledCheckbox label={entry}
                onChange={() => this.dispatch("change")}
                ref={"checkbox" + index} />
        </div>)
    }

    onMount() {
        const {initialValue} = this.options;
        this.setValue(initialValue);
    }
}
