import {UI} from "../../../../../stem-core/src/ui/UIBase";
import {BlinkSDKInputField, SDKArrayInput} from "../../components/Primitives.jsx";
import {MerchantDropdown} from "../../../../../blinkpay/widget/ui/MerchantDropdown";
import {SubscriptionOfferStore} from "../../../../../blinkpay/State";
import {VisibilityStatus} from "../../../../../client/state/misc/GenericEnums";
import {MerchantAudienceStore} from "../../../../../client/state/merchant/MerchantAudienceStore";
import {PanelEditorTabStyle} from "./PanelEditorTabStyle.js";
import {registerStyle} from "../../../../../stem-core/src/ui/style/Theme.js";


@registerStyle(PanelEditorTabStyle)
export class PanelOffersEditor extends UI.Element {
    render() {
        const {state} = this.options;
        const {merchant} = state.panel;

        const subscriptionOffers = SubscriptionOfferStore.filterBy({merchantId: merchant.id})
            .filter(({visibility}) => visibility === VisibilityStatus.HIDDEN || visibility === VisibilityStatus.PUBLIC);
        const newsletterOffers = MerchantAudienceStore.filterBy({merchantId: merchant.id, isPublic: true});

        return [
            <BlinkSDKInputField label="Subscription offers"
                                optional
                                stateKey={["panelOptions", "subscriptionOffer"]}
                                state={state}
                                allowExpr
                                InputClass={SDKArrayInput}
                                inputOptions={{
                                    box: false,
                                    addButtonText: "Add subscription offer",
                                    ElementInputClass: MerchantDropdown,
                                    elementInputOptions: {
                                        renderOption: id => SubscriptionOfferStore.get(id),
                                        options: subscriptionOffers.map(offer => offer.id),
                                    }
                                }}/>,

            <div></div>, // To align them on top of another

            <BlinkSDKInputField label="Newsletters"
                                optional
                                stateKey={["panelOptions", "newsletter"]}
                                state={state}
                                allowExpr
                                InputClass={SDKArrayInput}
                                inputOptions={{
                                    box: false,
                                    addButtonText: "Add newsletter",
                                    ElementInputClass: MerchantDropdown,
                                    elementInputOptions: {
                                        renderOption: id => MerchantAudienceStore.get(id),
                                        options: newsletterOffers.map(offer => offer.id),
                                    }
                                }}/>,
        ]
    }
}
