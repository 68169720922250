import {UI, registerStyle, styleRule, styleRuleInherit} from "../../../stem-core/src/ui/UI";
import {UserProfileStore} from "../../../client/state/UserProfileStore";
import {DashboardCard, DashboardCardStyle} from "../../ui/DashboardCard";
import {Messages} from "../../../blinkpay/Messages";
import {Button} from "../../../stem-core/src/ui/UI";
import {LoadingSpinner} from "../../../blinkpay/ui/LoadingSpinner";
import {authService, userService} from "../../../client/connection/Services";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {MessageElement} from "../../../blinkpay/widget/ui/MessageElement";

export class SwitchAccountCardStyle extends DashboardCardStyle {
    @styleRuleInherit
    card = {
        textAlign: "center",
    };

    @styleRuleInherit
    contentContainer = {
        padding: "60px 12px",
    };

    @styleRule
    contentTitleLine = {
        marginBottom: 24,
        fontSize: 24,
        color: this.themeProps.CARD_CONTENT_TEXT_COLOR,
    };

    @styleRule
    contentDescription = {
        color: this.themeProps.CARD_CONTENT_TEXT_LIGHTER_COLOR,
        fontSize: 18,
        lineHeight: 27,
        marginBottom: 24,
    };

    @styleRule
    switchBtn = {
        maxWidth: 275,
        width: "100%",
    }
}

@registerStyle(SwitchAccountCardStyle)
@autoredraw(UserProfileStore)
export class SwitchAccountCard extends DashboardCard {
    render() {
        const {styleSheet} = this;

        return [
            <div className={styleSheet.contentTitleLine}>{Messages.unauthorizedUserAccountTitle}</div>,
            <div className={styleSheet.contentDescription}>
                <MessageElement
                    message={Messages.unauthorizedUserAccountDescriptionLine1(userService.getUserEmail())}/>
                <div>{Messages.unauthorizedUserAccountDescriptionLine2}</div>
            </div>,
            <Button className={styleSheet.switchBtn}
                             onClick={() => this.handleSwitchAccountClick()}>
                {Messages.switchAccount}
            </Button>
        ];
    }

    async handleSwitchAccountClick() {
        LoadingSpinner.show();
        await authService.logout();
        window.location.href = "/";
        LoadingSpinner.hide();
    }

    onMount() {
        super.onMount();
        userService.ensureUserDataRequested();
    }
}
