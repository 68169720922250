import {UI} from "../../stem-core/src/ui/UIBase";
import {StyleSheet, styleRule, styleRuleInherit} from "../../stem-core/src/ui/Style";
import {registerStyle} from "../../stem-core/src/ui/style/Theme";
import {Input, TextArea} from "../../stem-core/src/ui/input/Input";
import {isSmallScreen} from "../../blinkpay/Utils";
import {NOOP_FUNCTION} from "../../stem-core/src/base/Utils";


export class DashboardNumberInput extends Input {
    initListeners() {
        this.addNodeListener("input", () => {
            const value = this.getValue();
            const digits = value.replace(/(^0+)|(\D)/g,'');
            if (value !== digits) {
                this.setValue(digits);
            }
        });
    }

    onMount() {
        super.onMount();
        this.initListeners();
    }
}


export class DashboardInputStyle extends StyleSheet {
    @styleRule
    element = {
        fontSize: () => (isSmallScreen() ? 18 : 24),
        margin: "auto",
    };

    @styleRule
    label = {
        fontSize: 12,
        marginBottom: 8,
        color: this.themeProps.INPUT_LABEL_COLOR,
        fontWeight: "bold",
    };

    @styleRule
    input = {
        borderRadius: 4,
        outline: "none",
        border: "1px solid " + this.themeProps.INPUT_BORDER_COLOR,
        padding: "12px 18px",
        fontSize: 14,
        width: "100%",
        color: this.themeProps.INPUT_TEXT_COLOR,

        ":focus": {
            border: "1px solid " + this.themeProps.ACTIVE_BORDER_COLOR,
            boxShadow: "0 2px 8 #064953",
        }
    };

    @styleRule
    error = {
        boxShadow: () => "inset 0 0 0 1px " + this.themeProps.INPUT_ERROR_COLOR,
    };

    @styleRule
    errorMessage = {
        color: () => this.themeProps.INPUT_ERROR_COLOR,
        fontSize: () => (isSmallScreen() ? 16 : 13),
        position: "absolute",
        padding: "0 6px",
        marginTop: 18,
    };
}

@registerStyle(DashboardInputStyle)
export class DashboardInput extends UI.Element {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            onEnter: NOOP_FUNCTION,
            onChange: NOOP_FUNCTION,
            error: null,
            width: null,
            InputClass: Input,
            initialValue: "",
        };
    }

    extraNodeAttributes(attr) {
        attr.addClass(this.styleSheet.element);
        attr.setStyle({width: this.options.width});
    }

    setValue(value) {
        this.input.setValue(value);
    }

    getValue() {
        return (this.input && this.input.getValue()) || "";
    }

    isEmpty() {
        const value = this.input.getValue();
        return value.trim().length === 0;
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {InputClass, label, inputAttributes, error} = this.options;

        return [
            label ? <div className={styleSheet.label}>{label}</div> : null,
            <InputClass ref="input" className={styleSheet.input + (error ? styleSheet.error : "")} {...inputAttributes} />,
            this.render(),
            <div className={styleSheet.errorMessage}>{error}</div>,
        ];
    }

    addInputListener(callback) {
        this.input.addNodeListener("input", callback);
    }

    addFocusListener(callback) {
        this.input.addNodeListener("focus", callback);
    }

    addBlurListener(callback) {
        this.input.addNodeListener("blur", callback);
    }

    clearError() {
        this.updateOptions({error: null});
    }

    onMount() {
        this.input.addNodeListener("focus", () => {
            this.clearError();
        });
        this.input.addNodeListener("mousedown", () => {
            this.clearError();
        });
        this.input.addNodeListener("keyup", event => {
            this.clearError();

            if (event.code === "Enter" || event.key === "Enter") {
                this.options.onEnter(this.getValue());
            } else {
                this.options.onChange(this.getValue());
            }
        });

        this.input.setValue(this.options.initialValue);
    }
}


export class DashboardCurrencyInputStyle extends DashboardInputStyle {
    @styleRule
    container = {
        position: "relative",
    };

    @styleRuleInherit
    input = {
        paddingLeft: 36,
    };

    @styleRule
    currency = {
        position: "absolute",
        transform: "translateY(-50%)",
        top: "50%",
        paddingLeft: 18,
        fontSize: 14,
    }
}

@registerStyle(DashboardCurrencyInputStyle)
export class DashboardCurrencyInput extends DashboardInput {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            InputClass: DashboardNumberInput,
        };
    }

    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.container);
    }

    render() {
        return [
            <div className={this.styleSheet.currency}>{this.options.currency.getMainUnitSymbol()}</div>,
        ];
    }
}
