import {UI} from "../../../../stem-core/src/ui/UIBase";
import {JSONStateInput} from "../components/JSONStateInput.jsx";
import {PanelOffersEditor} from "./editor-tabs/PanelOffersEditor";
import {TopLevelTabArea} from "../../../common/theme/TabAreaStyle";
import {PanelContextEditor} from "./editor-tabs/PanelContextEditor";
import {PanelContentEditor} from "./editor-tabs/PanelContentEditor";
import {PanelDeveloperEditor} from "./editor-tabs/PanelDeveloperEditor";
import {PanelPreviewSettingsEditor} from "./editor-tabs/PanelPreviewSettingsEditor.jsx";


export class PanelEditorInput extends JSONStateInput {
    renderSimplifiedMode() {
        const {state} = this.options;

        return <TopLevelTabArea>
            <PanelContextEditor title="Context" state={state} />
            <PanelOffersEditor title="Offers" state={state} />
            <PanelContentEditor title="Content" state={state} />
            <PanelDeveloperEditor title="Developer" state={state} />
            <PanelPreviewSettingsEditor title="Preview Options" state={state} />
        </TopLevelTabArea>;
    }
}
