import {deepCopy} from "../../../../stem-core/src/base/Utils.js";
import {LoadingSpinner} from "../../../../blinkpay/ui/LoadingSpinner.jsx";
import {apiEditMerchantUserJourney} from "../../../../client/state/merchant/MerchantUserJourneyStore.js";
import {isDeepEqual} from "../../../../blinkpay/UtilsLib.js";
import {SDKResourceEditorState} from "../components/SDKResourceEditorState.js";

export class JourneyEditorState extends SDKResourceEditorState {
    constructor(journey) {
        super();
        this.journey = journey;
        this.advancedMode = true;
        this.load();
    }

    load() {
        this.journeyOptions = deepCopy({}, this.journey.options);
    }

    isEdited() {
        return !isDeepEqual(this.journeyOptions, this.journey.options || {})
    }

    async saveChanges() {
        this.journey = await LoadingSpinner.wrap(() => apiEditMerchantUserJourney({
            sdkSettingsId: this.journey.settingsVersionId,
            userJourneyId: this.journey.id,
            options: this.journeyOptions,
        }));

        this.discardChanges();
    }
}
