import {
    DEFAULT_PANEL_CONTENT,
    GATE_COVER_PANEL_POSITION,
    GATE_MOUNT_TYPE,
    PANEL_FRAME,
    PANEL_TYPE,
    SOCIAL_APPS
} from "./Constants";
import {getMerchantPanel, getMerchantPanels} from "./modules/PublicData.js";
import {merchantVariableModule} from "./modules/merchant-state/MerchantVariableModule.js";
import {isString} from "../stem-core/src/base/Utils.js";
import {convertHTMLStringToJSON} from "../blinkpay/UtilsLib.js";
import {evaluateTemplate} from "./modules/TemplateEvaluation.js";

// TODO @branch why is this exported?
export const DEFAULT_PANEL_OPTIONS = {
    // Id or alias of a panel that this one inherits from. Because of versioning, it's highly recommended
    // using an alias here (aliases are stable across versions, IDs are not).
    parentPanel: null,

    type: PANEL_TYPE.custom,

    // TODO @Mihai this should only be null or array of id/alias
    // Ids or aliases of the subscription offer(s) to use in this panel. If not provided,
    // all subscription offers with visibility=PUBLIC are used.
    subscriptionOffer: null,

    // Ids or aliases of the donation offer(s) to use in this panel. If not provided,
    // all donation offers with visibility=PUBLIC are used.
    // Note: This is for future-compatibility for when we actually support multiple donation offers.
    donationOffer: null,

    // Ids or aliases of the public newsletter(s) to use in this panel. If not provided,
    // all public newsletters are displayed.
    newsletter: null,

    // Ids or aliases of the product(s) to use in this panel. If not provided,
    // all products with visibility=PUBLIC are used.
    products: null,

    // Whether to skip the first panel (the one that's fully merchant customizable)
    // and go straight into the flow.
    skipCTA: false,

    // Whether to enter the flow of editing an active recurring payment instead of creating a new
    // one. This only applies for subscribe & donation flows.
    editRecurring: false,

    // A list of tabs to display in the Inline User Dashboard. The default is to display tabs
    // for all functionality the merchant has enabled.
    dashboardTabs: null,

    // A list of social providers to enable on the authentication page for this panel.
    // Besides the default options below, we also support CDS for merchants with CDS
    // subscriptions/donations backend.
    authSocialProviders: [SOCIAL_APPS.Facebook, SOCIAL_APPS.Google, SOCIAL_APPS.Twitter, SOCIAL_APPS.LinkedIn],

    // Details about the payment. Required if type is "payment".
    // Ignored if type is not "payment".
    // Typescript format of the object:
    // paymentDetails: {
    //   resourceId: String,
    //   amount?: Number,
    //   amountInSubunits?: Number,
    //   comment?: Object,
    // }
    // TODO @branch wait, are we not specifying the currency?????
    paymentDetails: null,

    // URL of the logo to display at the top of the panel. If this is not
    // provided, no logo is displayed. The URL must be an absolute path
    // to an HTTPS resource.
    logoSrc: null,

    // The ALT text to be applied to the logo image.
    logoAlt: "",

    // Style of the logo displayed at the top of the panel. It supports the format of a Stem styleRule.
    logoStyle: {},

    // Text displayed at the top of the panel. If this is left null, the
    // default differ based on panel type, subscription/donation offer etc.
    text: null,

    // Base style of the text section. It supports the format of a Stem styleRule.
    textStyle: {},

    // Some panels have titles that can be overwritten.
    title: null,

    // Text displayed in the panel button. If this is left null, the
    // default differ based on panel type, subscription/donation offer etc.
    buttonText: null,

    // Base style of the button. It supports the format of a Stem styleRule.
    actionButtonStyle: {},

    // Array of font faces. The format of a font face object is:
    /*{
        family: String,
        src: String,
        style?: String,
        weight?: Number
        unicodeRange?: String,
    }*/
    fonts: [],

    // Overrides for Stem's Theme properties.
    theme: {},

    // Overrides for the Messages object values.
    messages: {},

    // TODO @version rename "metadata" in panels to conversionMetadata
    // Key-value metadata to be saved for conversions (e.g. newsletter
    // registrations, donations, subscriptions etc.)
    metadata: {},

    content: null,

    context: null,

    frame: PANEL_FRAME.logo,

    // Supported values are available in the GATE_TYPE enum.
    gateType: null,

    // Extra style to apply to the gate element itself.
    customStyle: {},

    // Extra style to apply to the cancel button.
    cancelButtonCustomStyle: {},

    // Height (in px) of the preview area. Only applicable for type
    // "preview".
    previewHeight: 200,

    // Enable a fade-out effect between the content and the panel. Only
    // applicable for type "preview".
    previewFadeOut: true,

    // Height (in px) of the fade-out zone of the preview area. Only
    // applicable for type "preview", if "previewFadeOut" is enabled.
    previewFadeOutHeight: 100,

    // Color of the bottom of the fade-out area. Only applicable for type
    // "preview", if "previewFadeOut" is enabled.
    previewFadeOutToColor: "white",

    // Color of the cover effect of the gate. Only applicable for type
    // "cover".
    coverColor: "rgba(255, 255, 255, 0.9)",

    // Position of the panel with regards to the gate (which covers the
    // whole area of the content in this case). Only applicable for type
    // "cover".
    coverPanelPosition: GATE_COVER_PANEL_POSITION.center,

    mountType: GATE_MOUNT_TYPE.lastChild,

    closeButton: true,

    scrimClickCollapse: true,

    // TODO: Add scrimBlockScroll

    // Callbacks

    onMount: null,

    onFlowCheckpoint: null,

    onFlowSuccess: null,

    onFlowFinish: null,
};

// Apply one layer of panel options on top of target options. This modifies the targetOptions
// in-place in addition to returning them, similar to Object.assign.
function overwritePanelOptions(targetOptions, layerOptions) {
    if (!layerOptions) {
        return;
    }
    // Fonts, theme constants, messages, metadata and contexts are merged between the options.
    Object.assign(targetOptions, layerOptions, {
        fonts: [
            ...(targetOptions.fonts || []),
            ...(layerOptions.fonts || []),
        ],
        theme: {
            ...targetOptions.theme,
            ...layerOptions.theme,
        },
        messages: {
            ...targetOptions.messages,
            ...layerOptions.messages,
        },
        metadata: {
            ...targetOptions.metadata,
            ...layerOptions.metadata,
        },
        context: {
            ...targetOptions.context,
            ...layerOptions.context,
        },
    });
}

// Build the panel options based on inheritance rules and template evaluation.
// Only panels in merchantPanels are used for inheritance.
function composePanelOptions(givenPanelOptions, merchantPanels, context={}) {
    const panelOptions = {};

    overwritePanelOptions(panelOptions, DEFAULT_PANEL_OPTIONS);

    const panelInheritenceChain = [];

    let panelId = givenPanelOptions.panel;
    while (panelId != null) {
        const panel = merchantPanels.find(panel => panel.id == panelId || panel.alias == panelId); // TODO @cleanup have a general matchByAliasOrId function
        if (panel == null) {
            break;
        }
        panelInheritenceChain.push(panel);
        panelId = panel.options.parentPanel;
    }

    // If we didn't find any panels in the inheritance chain, the "panel" option may be a panel
    // type instead. This behaviour is here to support legacy journeys, but it may not be so bad to
    // keep this fallback even if we fix those.
    if (panelInheritenceChain.length === 0 && !givenPanelOptions.type && Object.values(PANEL_TYPE).indexOf(givenPanelOptions.panel) >= 0) {
        givenPanelOptions.type = givenPanelOptions.panel;
        delete givenPanelOptions.panel;
    }

    // Beyond the inheritance chain defined by the panel, there are two "special" panels we inherit from:
    // the base panel of our type and the global base panel.
    const basePanelOfType = merchantPanels.find(panel => panel.alias === givenPanelOptions.type);
    if (givenPanelOptions.type !== PANEL_TYPE.custom && basePanelOfType) {
        panelInheritenceChain.push(basePanelOfType);
    }
    const basePanel = merchantPanels.find(panel => panel.alias === "base");
    if (basePanel) {
        panelInheritenceChain.push(basePanel);
    }

    const mainPanel = panelInheritenceChain[0];

    // Keep track of these options for analytics
    overwritePanelOptions(panelOptions, {
        metadata: {
            panelId: mainPanel?.id,
            panelAlias: mainPanel?.alias || panelOptions.type, // For a while, this might be a non-existing panel
        }
    });

    for (const layer of panelInheritenceChain.reverse()) {
        overwritePanelOptions(panelOptions, layer.options);
    }

    overwritePanelOptions(panelOptions, {
        context,
    });
    overwritePanelOptions(panelOptions, givenPanelOptions);

    // TODO @cleanup remove these
    if (DEFAULT_PANEL_CONTENT[panelOptions.type] && !panelOptions.content) {
        panelOptions.content = DEFAULT_PANEL_CONTENT[panelOptions.type];
    }
    if (!panelOptions.mountType) {
        // TODO @Mihai this needs to be in some default gate options
        panelOptions.mountType = panelOptions.gateType ? GATE_MOUNT_TYPE.lastChild : GATE_MOUNT_TYPE.replaceChildren;
    }

    return panelOptions;
}


function evaluateNodeAnalyticsOptions(options) {
    for (const key of Object.keys(options)) {
        if (key.startsWith("analytics-")) {
            options["data-" + key] = options[key];
        }
    }
    return options;
}

export function evaluatePanelOptions(options, merchantPanels = getMerchantPanels(), context = merchantVariableModule.all()) {
    options = composePanelOptions(options, merchantPanels, context);

    if (isString(options.content)) {
        options.content = convertHTMLStringToJSON(options.content, evaluateNodeAnalyticsOptions);
    }

    for (const key of Object.keys(options)) {
        if (key === "context" || key === "parentPanel") {
            // Some entries shouldn't be evaluated.
            continue;
        }

        options[key] = evaluateTemplate(options[key], options.context);
    }

    return options;
}

export function editPanel(aliasOrId, options) {
    if (Array.isArray(aliasOrId)) {
        for (const panel of aliasOrId) {
            editPanel(panel, options);
        }
        return;
    }
    const panel = getMerchantPanel(aliasOrId);

    // Edit the object in the store directly
    if (panel) {
        panel.options = panel.options || {};
        overwritePanelOptions(panel.options, options);
    }
}
