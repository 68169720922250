import {registerStyle, styleRuleInherit, UI} from "stem-core/src/ui/UI";
import {DashboardCurrencyInput, DashboardCurrencyInputStyle} from "../../ui/DashboardInput";
import {Money} from "../../../client/state/misc/Money";
import {Messages} from "../../../blinkpay/Messages";
import {isIntegerInput} from "../../common/Utils";


class DonationAmountInputStyle extends DashboardCurrencyInputStyle {
    @styleRuleInherit
    element = {
        margin: 0,
    };

    @styleRuleInherit
    input = {
        padding: "8px 16px",
        width: 120,
    };

    @styleRuleInherit
    errorMessage = {
        marginTop: 6,
    };
}

// TODO @branch deprecate this
@registerStyle(DonationAmountInputStyle)
export class DonationAmountInput extends DashboardCurrencyInput {
    getChildrenToRender() {
        const {styleSheet} = this;
        const {InputClass, inputAttributes, error} = this.options;
        return [
            <InputClass ref="input" className={styleSheet.input + (error !== null ? styleSheet.error : "")} {...inputAttributes} />,
            this.render(),
            error ? <div className={styleSheet.errorMessage}>{error}</div> : null,
        ];
    }

    onMount() {
        this.input.setValue(this.options.initialValue);
    }
}

export const DonationAmountInputController = (Base = UI.Element) => (
    class DonationAmountInputControllerMixin extends Base {
        getDefaultOptions(options) {
            return {
                donationOffer: null,
                ...super.getDefaultOptions(options),
            }
        }

        isInvalidAmount(amount) {
            return !isIntegerInput(amount) || !this.isInRange(amount);
        }

        isInRange(amount) {
            const minAmount = this.options.donationOffer.minAmount;
            const maxAmount = this.options.donationOffer.maxAmount;
            const currency = this.options.donationOffer.getCurrency();
            const intAmount = currency.mainUnitsToAmount(parseInt(amount));
            return minAmount <= intAmount && intAmount <= maxAmount;
        }

        formatAmount(amount) {
            const currency = this.options.donationOffer.getCurrency();
            return new Money(amount, currency).toMainUnitString({decimalsDisplayed: 0});
        }

        getInputErrorMessage(amount) {
            if (!amount) {
                return Messages.fieldIsRequired;
            }

            if (!this.isInRange(amount)) {
                return Messages.enterValueBetween(
                    this.formatAmount(this.options.donationOffer.minAmount),
                    this.formatAmount(this.options.donationOffer.maxAmount),
                ).toString();
            }

            return null;
        }
    }
);
