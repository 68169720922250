import {UI} from "../../../stem-core/src/ui/UIBase";
import {longWeekdays, StemDate} from "../../../stem-core/src/time/Date";
import {Select, TextInput, TimeInput} from "../../../stem-core/src/ui/input/Input";
import {BlinkInputField} from "../../common/Input";
import {findFirstFreeVersion, unwrapArray} from "../../../stem-core/src/base/Utils";
import {AudienceNewsletterFrequency, MerchantAudienceStore} from "../../../client/state/merchant/MerchantAudienceStore";
import {Switcher} from "../../../stem-core/src/ui/Switcher";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {LineSeparator} from "../../../blinkpay/ui/LineSeparator";
import {LabeledCheckbox} from "../../../blinkpay/ui/Checkbox";

class WeekdayMultiSelector extends UI.Element {
    render() {
        const WEEKDAYS = longWeekdays;
        const valuesSelected = new Set(this.options.initialValue);
        return WEEKDAYS.map((name, index) => <span>
            <LabeledCheckbox ref={"day" + index} initialValue={valuesSelected.has(index)} label={name}/>
        </span>);
    }

    getValue() {
        let result = [];
        for (let index = 0; index < longWeekdays.length; index++) {
            if (this["day" + index].getValue()) {
                result.push(index);
            }
        }
        return result;
    }
}

class MonthDayMultiSelector extends UI.Element {
    render() {
        let {initialValue} = this.options;
        return [
            <BlinkInputField label="Days of the month, comma separated (from 1 to 31)">
                <TextInput ref="input" value={initialValue.join(",")} />
            </BlinkInputField>
        ]
    }

    getValue() {
        let value = this.input.getValue();
        let values = value.split(",").map(x => parseInt(x));
        return unwrapArray(values);
    }
}

export class MerchantAudienceInput extends UI.Element {
    getDefaultOptions() {
        let defaultSendTime = new StemDate();
        defaultSendTime.setHours(9, 0, 0, 0);
        return {
            audience: {
                isPublic: false,
                autosubscribeNewUsers: false,
                defaultFrequency: AudienceNewsletterFrequency.WEEKLY,
                defaultFrequencyOffsets: [1, 15], // Works for both monthly and weekly
                defaultSendTime,
            }
        }
    }

    getValue() {
        return {
            name: this.nameInput.getValue(),
            isPublic: this.isPublicInput.getValue(),
            defaultFrequency: this.frequencyInput.getValue(),
            defaultFrequencyOffsets: this.frequencyOffsetsInput?.getValue() || [],
            defaultSendTime: this.sendTimeInput.getValue(),
            autosubscribeNewUsers: this.autosubscribeInput.getValue(),
        }
    }

    getFrequencyOffsetsSelector() {
        let {audience} = this.options;

        if (!this.weekdaySelector) {
            const weekdaySelectorDefault = (audience.defaultFrequency == AudienceNewsletterFrequency.WEEKLY) ?
                        audience.defaultFrequencyOffsets : [1];
            const monthDaySelectorDefault = (audience.defaultFrequency == AudienceNewsletterFrequency.MONTHLY) ?
                        audience.defaultFrequencyOffsets : [1, 15];
            this.weekdaySelector = <WeekdayMultiSelector ref="frequencyOffsetsInput"
                                                         initialValue={weekdaySelectorDefault}
                                                         />;
            this.monthDaySelector = <MonthDayMultiSelector ref="frequencyOffsetsInput"
                                                           initialValue={monthDaySelectorDefault}
                                                           />;
        }

        const selectedFrequency = this.frequencyInput?.getValue() || audience.defaultFrequency;
        if (selectedFrequency == AudienceNewsletterFrequency.WEEKLY) {
            return this.weekdaySelector;
        }
        if (selectedFrequency == AudienceNewsletterFrequency.MONTHLY) {
            return this.monthDaySelector;
        }
        return null;
    }

    render() {
        let {audience} = this.options;

        return [
            <div>
                <BlinkInputField label="Name">
                    <TextInput ref="nameInput" value={audience.name}/>
                </BlinkInputField>
            </div>,
            <div>
                <LabeledCheckbox ref="isPublicInput" initialValue={audience.isPublic} label={"Is Public Newsletter"}/>
            </div>,
            <div>
                <LabeledCheckbox ref={"autosubscribeInput"} initialValue={audience.autosubscribeNewUsers} label={"Automatically Add New Users"} />
            </div>,
            <LineSeparator text="Campaign template options, used when creating new campaigns for this audience."/>,
            <div>
                <BlinkInputField label="Default Frequency">
                    <Select ref="frequencyInput"
                            options={AudienceNewsletterFrequency.all()}
                            initialValue={audience.defaultFrequency}
                            onChange={() => {
                                this.switcher.setActive(this.getFrequencyOffsetsSelector());
                            }}
                    />
                </BlinkInputField>
            </div>,

            <Switcher ref="switcher">
                {this.getFrequencyOffsetsSelector()}
            </Switcher>,

            <BlinkInputField label="Default send time">
                <TimeInput ref="sendTimeInput" value={audience.defaultSendTime}/>
            </BlinkInputField>,
        ]
    }
}


export class MerchantAudienceCreateDialog extends ConfirmationModal {
    getDefaultOptions(options) {
        return {
            ...super.getDefaultOptions(),
            title: "Create audience",
            confirmLabel: "Create & continue editing",
            confirmAction: () => this.resolve({
                name: this.nameInput.getValue(),
            }),
        };
    }

    render() {
        const defaultName = findFirstFreeVersion("New Audience", (name) => MerchantAudienceStore.find(audience => audience.name === name));
        return [
            <BlinkInputField label="Name">
                <TextInput ref="nameInput" value={defaultName} style={{minWidth: 300}}/>
            </BlinkInputField>
        ]
    }
}
