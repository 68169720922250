import {UI} from "../../../stem-core/src/ui/UIBase";
import {styleRule, styleRuleInherit} from "../../../stem-core/src/ui/Style";

import {SearchIcon} from "../../NavSidePanelIcons";
import {registerStyle} from "../../../stem-core/src/ui/style/Theme";
import {BaseInput, BaseInputStyle} from "../../../blinkpay/ui/Input";


class SearchInputStyle extends BaseInputStyle {
    @styleRuleInherit
    element = {
        color: "inherit",
    };

    @styleRuleInherit
    inputContainer = {
        height: "",
        border: "1px solid " + this.themeProps.INPUT_BORDER_COLOR,
        borderRadius: this.themeProps.BUTTON_BORDER_RADIUS,
        paddingLeft: "20px",
    };

    @styleRuleInherit
    input = {
        padding: 0,
        margin: 0,
    };

    @styleRule
    searchIcon = {
        height: 16,
        position: "absolute",
        pointerEvents: "none",
        left: 10,
        top: "50%",
        transform: "translateY(-50%)",
    };
}

@registerStyle(SearchInputStyle)
export class SearchInput extends BaseInput {
    render() {
        return <SearchIcon className={this.styleSheet.searchIcon}
                           color={this.styleSheet.themeProps.BASE_INPUT_PLACEHOLDER_COLOR}/>;
    }
}
