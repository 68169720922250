// We're using SubscriptionCoverage to mean the subscription, and subscriptionsOffers to be plans.
import {UI} from "../../../stem-core/src/ui/UIBase";
import {apiConnection} from "../../../client/connection/BlinkApiConnection";
import {loadBefore} from "../../../blinkpay/ui/decorators/LoadBefore";
import {SubscriptionOfferStore} from "../../../client/state/SubscriptionOfferStore";
import {SimpleTable, TableHoverStyle} from "../../ui/SimpleTable";
import {CENTER_COLUMN, LEFT_COLUMN, MONEY_COLUMN} from "../../common/theme/TableStyle";
import {DashboardSelectorPage} from "../../common/DashboardSelectorPage.jsx";
import {MakeSubscriptionOfferStatusLabel, MerchantSubscriptionInstancePage} from "./MerchantSubscriptionInstancePage";
import {
    apiMerchantCreateSubscriptionCoverage,
    SubscriptionCoverageStore
} from "../../../client/state/SubscriptionCoverageStore";
import {ConfirmationModal} from "../../../blinkpay/ui/ConfirmationModal";
import {BlinkInputField} from "../../common/Input";
import {TextInput} from "../../../stem-core/src/ui/input/Input";
import {Link} from "../../../stem-core/src/ui/UIPrimitives";
import {Button} from "../../../stem-core/src/ui/button/Button";
import {CollapsibleTable} from "../../../stem-core/src/ui/table/CollapsibleTable";


class CreateSubscriptionCoverageDialog extends ConfirmationModal {
    getDefaultOptions() {
        return {
            ...super.getDefaultOptions(),
            title: "New subscription tier",
            description: "Create a new subscription tier. Subscription tiers are defined by their benefits, and can have multiple payment plans (monthly, yearly, etc.) associated.",
            confirmLabel: "Create & continue editing",
            confirmAction: () => this.resolve(this.getValue()),
        }
    }

    getValue() {
        const {merchant} = this.options;
        return {
            merchantId: merchant.id,
            name: this.nameInput.getValue(),
        }
    }

    render() {
        return [
            <div>
                <BlinkInputField label="Name">
                    <TextInput placeholder="Subscription name" ref="nameInput"/>
                </BlinkInputField>
            </div>,
        ]
    }
}


function augmentSubscriptionUsageSummary(usageData, key) {
    let coverageMap = new Map();
    for (const entry of usageData) {
        const subscriptionOffer = SubscriptionOfferStore.get(entry.offerId);
        const {coverage} = subscriptionOffer;
        subscriptionOffer[key] = entry.count;

        const coveragePartialCount = coverageMap.get(coverage) || 0;
        coverageMap.set(coverage, coveragePartialCount + entry.count);
    }
    for (const [coverage, count] of coverageMap.entries()) {
        coverage[key] = count;
    }
}

async function loadSubscriptionOffersSummary({merchant}) {
    const response = await apiConnection.get("/merchant/get_subscription_offers_summary/");
    const {active, allTime} = response;

    // TODO @branch maybe return a nicer format or do it in the app data?
    augmentSubscriptionUsageSummary(active, "currentSubscribersCount");
    augmentSubscriptionUsageSummary(allTime, "allTimeSubscribersCount");

    return response;
}

class SubscriptionTableCollapsedElement extends UI.Element {
    extraNodeAttributes(attr) {
        attr.setStyle({margin: 12});
    }

    render() {
        const {subscriptionCoverage} = this.options;
        const subscriptionOffers = SubscriptionOfferStore.filterBy({coverageId: subscriptionCoverage.id});
        if (subscriptionOffers.length === 0) {
            return "No pricing plans available for this offer. Open the subscription tier to setup a first pricing plan.";
        }

        const columns = [
            ["Name", sub => sub.title, LEFT_COLUMN],
            ["Price", sub => sub.getBasePrice(), MONEY_COLUMN],
            ["Billing Frequency", sub => sub.getCycleDuration(), CENTER_COLUMN],
            ["Current Subscribers", sub => sub.currentSubscribersCount || 0, CENTER_COLUMN],
            ["All Time Subscribers", sub => sub.allTimeSubscribersCount || 0, CENTER_COLUMN],
            ["Status", sub => MakeSubscriptionOfferStatusLabel(sub), CENTER_COLUMN],
        ];

        return [
            <SimpleTable columns={columns} entries={subscriptionOffers} />
        ];
    }
}

@loadBefore(loadSubscriptionOffersSummary)
export class MerchantSubscriptionsPage extends DashboardSelectorPage {
    getDefaultOptions() {
        return {
            store: SubscriptionCoverageStore, // These are the objects we're actually opening
            title: "Subscription Tiers",
            description: "Your subscription tiers represent the various possible benefit packages, which can have associated multiple individual payment plans.",
        }
    }

    goToEntry(entry) {
        if (entry?.coverage) {
            // Any subscription offer should take us to the subscription coverage
            entry = entry.coverage;
        }
        super.goToEntry(entry);
    }

    async createSubscription() {
        const {merchant} = this.options;
        const subscriptionRequest = await CreateSubscriptionCoverageDialog.prompt({merchant});
        if (!subscriptionRequest) {
            return;
        }
        const subscriptionCoverage = await apiMerchantCreateSubscriptionCoverage(subscriptionRequest);
        if (subscriptionCoverage) {
            this.goToEntry(subscriptionCoverage);
        }
    }

    getSubscriptionOffersTable() {
        const {merchant} = this.options;
        const subscriptionCoverages = SubscriptionCoverageStore.filterBy({merchantId: merchant.id});

        const coverageColumns = [
            ["Subscription", sub => <Link href={this.getUrlForEntry(sub)} className={TableHoverStyle.getInstance().container}><span>{sub.name}</span></Link>, LEFT_COLUMN],
            ["Current Subscribers", sub => sub.currentSubscribersCount || 0, CENTER_COLUMN],
            ["All Time Subscribers", sub => sub.allTimeSubscribersCount || 0, CENTER_COLUMN],
            ["Has available plan", sub => MakeSubscriptionOfferStatusLabel(sub), CENTER_COLUMN],
        ];

        return [
            <div className={this.styleSheet.headerButtons}>
                <Button onClick={() => this.createSubscription()}>New subscription tier</Button>
            </div>,
            <CollapsibleTable
                entries={subscriptionCoverages}
                columns={coverageColumns}
                renderCollapsible={(entry) => <SubscriptionTableCollapsedElement subscriptionCoverage={entry}/>}
                // rowClass={this.getEntryRowClass()}
            />,
        ]
    }

    // TODO @Mihai not used right now, tricky to get right
    // TODO use this when we'll want to have any click on the row to open the entry
    // getEntryRowClass() {
    //     const goToEntry = (entry) => this.goToEntry(entry);
    //
    //     @registerStyle(TableHoverStyle)
    //     class ClickableCollapsibleTableRow extends CollapsibleTableRow {
    //         extraNodeAttributes(attr) {
    //             super.extraNodeAttributes(attr);
    //             attr.addClass(this.styleSheet.container);
    //         }
    //
    //         getToggleIcon() {
    //             return <NoClickPropagate>{super.getToggleIcon()}</NoClickPropagate>;
    //         }
    //
    //         onMount() {
    //             this.addClickListener(() => goToEntry(this.options.entry));
    //         }
    //     }
    //
    //     return ClickableCollapsibleTableRow;
    // }

    render() {
        const {selectedEntry} = this;
        if (selectedEntry) {
            return [
                this.makeTitle(selectedEntry.name, null),
                <MerchantSubscriptionInstancePage subscriptionCoverage={selectedEntry} />
            ];
        }

        return [
            this.makeTitle(),
            this.getSubscriptionOffersTable(),
        ]
    }
}
