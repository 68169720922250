import {UI} from "../../../stem-core/src/ui/UIBase";
import {SubscriptionStore} from "../../../client/state/SubscriptionStore";
import {MerchantReportType} from "../../../client/state/merchant/MerchantReportStore";
import {DashboardTablePage, MakeEmailEntry, DashboardTableColumnManager} from "../../common/DashboardTablePage";
import {
    AggregateDataViewManager,
    AnalyticsAnnotation,
    Annotation, ExtraColumnAnnotation,
    GroupByField
} from "../../common/AggregateDataViewManager";
import {MerchantUserStore} from "../../../client/state/merchant/MerchantUserStore";
import {Messages} from "../../../blinkpay/Messages";
import {Theme} from "../../../stem-core/src/ui/style/Theme";
import {Money} from "../../../client/state/misc/Money";
import {BlinkInputField} from "../../common/Input";
import {StemDate} from "../../../stem-core/src/time/Date";
import {InfoBlockGroup} from "../../common/InfoBlock";
import {RadioButtons} from "../../../blinkpay/ui/Button";
import {CENTER_COLUMN, LEFT_COLUMN, MONEY_COLUMN} from "../../common/theme/TableStyle";
import {CollapsiblePanel} from "../../../stem-core/src/ui/collapsible/CollapsiblePanel";
import {SubscriptionOffersMultiselectInput} from "../../common/input/SubscriptionCoverageMultiselectInput";
import {DashboardLabel} from "../../common/DashboardLabel";
import {autoredraw} from "../../../stem-core/src/decorators/AutoRedraw";
import {Level} from "../../../stem-core/src/ui/Constants";
import {
    DateRangeInput,
    DateRangeInterval,
    DefaultMerchantDateRangeIntervals,
    MakeMerchantAllTimeInterval
} from "../../ui/input/DateRangeInput";
import {SubscriptionCoverageStore} from "../../../client/state/SubscriptionCoverageStore";
import {SubscriptionOfferStore} from "../../../blinkpay/State";
import {PANEL_TYPE} from "../../../blink-sdk/Constants";
import {formatPercentFromRatio} from "../../common/Utils";
import {MakeInfoElement} from "../../common/InfoTooltip";
import {MessageElement} from "../../../blinkpay/widget/ui/MessageElement";
import {BillingPlanStore} from "../../../client/state/SubscriptionOfferStore";
import {AnalyticsEventType} from "../../../blink-sdk/utils/AnalyticsClient";
import {UserSearchInput} from "../../ui/input/UserSearchInput.jsx";
import {JourneysFilter} from "../../common/JourneysFilter.jsx";


function getDiscountEntry(subscriptionDiscount) {
    // TODO replace inline style with class-based
    const discountContainerStyle = {
        display: "flex",
        alignItems: "center",
        height: 16,
        fontSize: 12,
        color: Theme.props.TABLE_BOLD_TEXT_COLOR,
    };

    if (!subscriptionDiscount) {
        return null;
    }

    const infoText = () => <MessageElement message={Messages.discountInfo} args={[subscriptionDiscount.code, subscriptionDiscount.getDiscountValue()]} />;

    return (
        <div style={{discountContainerStyle}}>
            <MessageElement message={Messages.discounted} />
            {MakeInfoElement(infoText)}
        </div>
    );
}


function renderBillingPlanTableEntry(billingPlan) {
    return [
        <strong>{billingPlan.getPrice()}</strong>, `/${billingPlan.getAbbreviatedCycleDuration()}`
    ];
}


function renderSubscriptionOfferTableEntry(subscription) {
    return [
        <div>{subscription.coverage.name} ({renderBillingPlanTableEntry(subscription.billingPlan)})
        </div>,
        getDiscountEntry(subscription.getDiscount()),
    ];
}

// TODO @branch rethink this:
//  We want to know if it's active or finished first of all
@autoredraw
export class RecurringPaymentStatusLabel extends DashboardLabel {
    getRecurringPaymentOptions(recurringPayment) {
        if (recurringPayment.isCanceled() || recurringPayment.isFinished()) {
            return {label: "Canceled", level: Level.WARNING};
        }

        if (recurringPayment.isUnpaid()) {
            return {label: "Payment failed", level: Level.ERROR};
        }

        return {label: "Active"};
    }

    getDefaultOptions(options) {
        return this.getRecurringPaymentOptions(options.recurringPayment);
    }

    // TODO @Mihai how to properly implement this with autoredraw and pass-through like functionality?
    redraw() {
        Object.assign(this.options, this.getDefaultOptions(this.options));
        super.redraw();
    }
}


export class MerchantSubscribersPage extends DashboardTablePage {
    columnManager = new DashboardTableColumnManager("subscribers-table-columns", [
        ["Email", subscription => MakeEmailEntry(MerchantUserStore.getFromObject(subscription)), LEFT_COLUMN],
        ["Name", subscription => MerchantUserStore.getFromObject(subscription)?.getName(), LEFT_COLUMN],
        ["Plan", subscription => renderSubscriptionOfferTableEntry(subscription), CENTER_COLUMN],
        ["Offer", subscription => subscription.recurringOffer?.title || "(unnamed)", CENTER_COLUMN],
        ["Created At", subscription => subscription.createdAt, CENTER_COLUMN],
        ["Paid Until", subscription => subscription.getPaidUntil(), CENTER_COLUMN],
        ["Status", subscription => <RecurringPaymentStatusLabel recurringPayment={subscription} />, CENTER_COLUMN],
    ], {tablePage: this});
    groupsManager = new AggregateDataViewManager([
        new AnalyticsAnnotation("Flow starts", "flowStarts", AnalyticsEventType.FLOW_START.key, {
            flowType: PANEL_TYPE.subscribe,
        }),
        new ExtraColumnAnnotation("Conversion rate", entry => {
            if (!entry.flowStarts) {
                return "N/A";
            }
            return formatPercentFromRatio(Math.min(entry.count, entry.flowStarts + 1), entry.flowStarts);
        }),
        new Annotation("Total amount", "total_amount", "sum", "amount", value => new Money(value, this.getCurrency()), MONEY_COLUMN),
        new Annotation("EMR", "estimated_monthly_revenue", "sum", "estimated_monthly_revenue", value => new Money(value, this.getCurrency()), MONEY_COLUMN),
    ], [
        new GroupByField("Subscription Coverage", "coverage_id", value => SubscriptionCoverageStore.get(value)?.name),
        new GroupByField("Subscription Offer", "recurring_offer_id", value => SubscriptionOfferStore.get(value)?.title || "(unnamed)"),
        new GroupByField("Billing Plan", "billing_plan_id", value => renderBillingPlanTableEntry(BillingPlanStore.get(value))),
    ]);

    getDefaultOptions() {
        return {
            title: "Subscribers",
            description: "All current or past subscribers. Users can appear multiple times, " +
                "if they interrupt their service and resubscribe for instance.",
            ...super.getDefaultOptions(),
            store: SubscriptionStore,
            endpoint: "/merchant/get_subscriptions/",
            reportType: MerchantReportType.SUBSCRIPTIONS,
        }
    }

    getFilterSection() {
        // TODO @branch we really need better state patterns
        const merchant = this.getMerchant();

        merchant.createdAt = new StemDate(2010, 0, 1);

        const {subscriptionOffersFilter} = this;
        let coveragePanelFilter = "Filter by subscription type";
        if (subscriptionOffersFilter == null || subscriptionOffersFilter.allSelected()) {
            coveragePanelFilter += " (All Selected)"
        } else {
            coveragePanelFilter += " (Active filter)"
        }

        return [
            <div>
                <UserSearchInput
                    ref="userSearchInput"
                    onChange={() => this.applyFilters()}
                />

                <BlinkInputField label="Status type">
                    <RadioButtons
                        values={["Active", "Canceled", "Failed", "All"]}
                        onSelect={() => this.applyFilters()}
                        ref="subscriptionTypeFilter"/>
                </BlinkInputField>

                <BlinkInputField label="Source">
                    <RadioButtons
                        values={["All", "Made by user", "Made by staff", "Import"]}
                        onSelect={() => this.applyFilters()}
                        ref="sourceFilter"/>
                </BlinkInputField>

                <BlinkInputField label="Requires Delivery">
                    <RadioButtons
                        activeValue="All"
                        values={["Yes", "No", "All"]}
                        onSelect={() => this.applyFilters()}
                        ref="requiresDeliveryFilter"/>
                </BlinkInputField>

                <BlinkInputField label="Created at">
                    <DateRangeInput
                        ref="createdAtDateRangeInput"
                        style={{maxWidth: 350, display: "inline-block"}}
                        options={DefaultMerchantDateRangeIntervals(merchant)}
                        selectedInterval={DateRangeInterval.LAST_30_DAYS}
                        onChange={() => this.applyFilters()}
                    />
                </BlinkInputField>

                <BlinkInputField label="Active until">
                    <DateRangeInput
                        ref="activeUntilDateRangeInput"
                        style={{maxWidth: 350, display: "inline-block"}}
                        options={DefaultMerchantDateRangeIntervals(merchant)}
                        selectedInterval={MakeMerchantAllTimeInterval(merchant)}
                        onChange={() => this.applyFilters()}
                    />
                </BlinkInputField>

            </div>,
            <div>
                <CollapsiblePanel
                    collapsed={true}
                    title="Filter by active journeys"
                >
                    <JourneysFilter ref="journeysFilter" merchant={merchant} onChange={() => this.applyFilters()} />
                </CollapsiblePanel>
            </div>,
            <div style={{marginTop: 16}}>
                <CollapsiblePanel
                    collapsed={true}
                    title={coveragePanelFilter}
                >
                    <SubscriptionOffersMultiselectInput
                        merchant={merchant}
                        onChange={() => this.applyFilters()}
                        includeInactive={true}
                        ref="subscriptionOffersFilter"
                    />
                </CollapsiblePanel>
            </div>
        ]
    }

    getFilters() {
        const subscriptionType = this.subscriptionTypeFilter.getActive();
        const requiresDelivery = this.requiresDeliveryFilter.getActive();
        let status = null;

        if (subscriptionType == "Failed") {
            status = "unpaid";
        }

        if (subscriptionType == "Canceled") {
            status = "canceled";
        }

        let source = null;
        const subscriptionSource = this.sourceFilter.getActive();
        if (subscriptionSource == "Made by user") {
            source = ["user"];
        }
        if (subscriptionSource == "Made by staff") {
            source = ["merchant", "merchant_auto"];
        }
        if (subscriptionSource == "Import") {
            source = ["external"];
        }

        const subscriptionOfferIds = !this.subscriptionOffersFilter.allSelected() ? this.subscriptionOffersFilter.getSelectedSubscriptionOfferIds() : null;

        return {
            userFilter: this.userSearchInput.getValue(),
            status: status,
            source: source,
            createdAt: this.createdAtDateRangeInput.valueOf(),
            activeUntil: this.activeUntilDateRangeInput.valueOf(),
            activeAt: (subscriptionType == "Active") ? StemDate.now() : null,
            requiresDelivery: requiresDelivery != "All" ? (requiresDelivery == "Yes") : null,
            subscriptionOfferIds,
            metadata: this.columnManager.getMetadataFilters(),
            ...this.journeysFilter?.getValue(),
        }
    }

    summaryContent(summaryLastResponse) {
        const {count, estimatedMonthlyRevenue, distribution} = summaryLastResponse;

        return [
            <InfoBlockGroup label="Overview" entries={[
                ["subscriptions", count],
                ["monthly revenue", new Money(estimatedMonthlyRevenue)],
            ]} />,
            <InfoBlockGroup label="Breakdown" entries={[
                ["active", distribution.active],
                ["canceled", distribution.canceled],
                ["failed", distribution.failed],
            ]} />,
        ];
    }
}
