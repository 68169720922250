import {UI, registerStyle, styleRuleInherit} from "../../stem-core/src/ui/UI";
import {WalletListItemStyle} from "../../blinkpay/common/WalletListItem";


export class DashboardRadioStyle extends WalletListItemStyle {
    @styleRuleInherit
    radioInput = {
        border: () => ".175rem solid " + this.themeProps.INPUT_LABEL_COLOR,
    };

    @styleRuleInherit
    selected = {
        "::after": {
            background: this.themeProps.INPUT_LABEL_COLOR,
        },
    }
}


@registerStyle(DashboardRadioStyle)
export class DashboardRadio extends UI.Element {
    extraNodeAttributes(attr) {
        super.extraNodeAttributes(attr);
        attr.addClass(this.styleSheet.radioInput + (this.options.selected ? this.styleSheet.selected : ""));
    }
}
