import {UI} from "../../stem-core/src/ui/UIBase";
import {styleRule, StyleSheet} from "../../stem-core/src/ui/Style";
import {MerchantUserStore} from "../../client/state/merchant/MerchantUserStore";
import {MerchantReportType} from "../../client/state/merchant/MerchantReportStore";
import {DashboardTableColumnManager, DashboardTablePage, MakeEmailEntry} from "../common/DashboardTablePage";
import {Money} from "../../client/state/misc/Money";
import {InfoBlockGroup} from "../common/InfoBlock";
import {BorderedLabel} from "../common/BorderedLabel";
import {formatPercentFromRatio} from "../common/Utils";
import {CENTER_COLUMN, DATE_COLUMN, LEFT_COLUMN, MONEY_COLUMN} from "../common/theme/TableStyle";
import {registerStyle, Theme} from "../../stem-core/src/ui/style/Theme";
import {Button} from "../../stem-core/src/ui/button/Button";
import {MerchantAddUserModal} from "../common/modals/MerchantAddUserModal";
import {UserDetailsModal} from "../common/UserDetails";
import {Annotation, GroupByField, AggregateDataViewManager} from "../common/AggregateDataViewManager";
import {capitalize} from "../../stem-core/src/base/Utils";
import {UserSearchInput} from "../ui/input/UserSearchInput.jsx";

function MakeDeviceInfoLabel(label) {
    const options = {
        borderColor: Theme.props.INPUT_BORDER_COLOR,
        style: {
            margin: "3px",
            borderRadius: 2,
            whiteSpace: "nowrap",
        }
    }
    return <BorderedLabel {...options}>{label || "Unknown"}</BorderedLabel>
}

class MerchantUserPageStyle extends StyleSheet {
    @styleRule
    filterSection = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    }
}

@registerStyle(MerchantUserPageStyle)
export class MerchantUsersPage extends DashboardTablePage {
    getDefaultOptions() {
        return {
            title: "Users",
            description: "All the users that accessed your website through Blink.",
            store: MerchantUserStore,
            endpoint: "/merchant/get_users/",
            reportType: MerchantReportType.USERS,
        }
    }

    getFilterSection() {
        return <div className={this.styleSheet.filterSection}>
            <UserSearchInput
                ref="userSearchInput"
                onChange={() => this.applyFilters()} />

            <Button onClick={() => {
                MerchantAddUserModal.show({
                    onSuccess: (merchantUser) => this.handleUserAdded(merchantUser)
                });
            }}>Add user</Button>
        </div>
    }

    getFilters() {
        return {
            userFilter: this.userSearchInput.getValue(),
        };
    }

    handleUserAdded(merchantUser) {
        UserDetailsModal.show({merchantUser})
        this.paginator.fetchFirstPage();
    }

    summaryContent(summaryLastResponse) {
        const {count, revenue, deviceDistribution} = summaryLastResponse;

        function formatDeviceCount(deviceCount) {
            return formatPercentFromRatio(deviceCount, count);
        }

        return [
            <InfoBlockGroup label="Overview" entries={[
                ["users", count],
                ["lifetime revenue", new Money(revenue, this.getCurrency())],
            ]}/>,
            (count > 0) && <InfoBlockGroup label="Breakdown by device" entries={[
                ["mobile", formatDeviceCount(deviceDistribution.mobile)],
                ["desktop", formatDeviceCount(deviceDistribution.desktop)],
                ["tablet", formatDeviceCount(deviceDistribution.tablet)],
                ["other", formatDeviceCount(deviceDistribution.unknown)],
            ]}/>
        ];
    }

    columnManager = new DashboardTableColumnManager("users-table-columns", [
        ["Email", merchantUser => MakeEmailEntry(merchantUser), LEFT_COLUMN],
        ["Name", merchantUser => merchantUser.getName(), LEFT_COLUMN],
        ["Lifetime Revenue", merchantUser => merchantUser.getLifetimeRevenue().toMainUnitString(), MONEY_COLUMN],
        ["Last Accessed", merchantUser => merchantUser.lastAccessDate, DATE_COLUMN],
        ["Last Device", merchantUser => {
            const lastDevice = merchantUser.getLastDevice();

            return lastDevice && <div>
                <div style={{textAlign: "center"}}>
                    {MakeDeviceInfoLabel(capitalize(lastDevice.type))}
                    {MakeDeviceInfoLabel(lastDevice.os)}
                    {MakeDeviceInfoLabel(lastDevice.browser)}
                </div>
            </div>;
        }, CENTER_COLUMN],
        ["Last Location", merchantUser => {
            const location = merchantUser.getLastLocation();

            return location && <div>
                {location.city && <div>{location.city}</div>}
                <div>{[location.region, location.countryCode].filter(x => !!x).join(', ')}</div>
            </div>
        }]
    ]);
    groupsManager = new AggregateDataViewManager([
        new Annotation("Lifetime revenue", "lifetime_revenue", "sum", "lifetime_revenue", value => new Money(value, this.getCurrency())),
    ], [
        new GroupByField("Last access device type", "last_access_device_type", value => MakeDeviceInfoLabel(capitalize(value))),
    ])
}
